import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import Graficos from './Graficos';
import './css/dashboard.css';


import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import conexao from '../../Services/api2';



const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0x 0px 0px gray",
    borderRadius: "0px",
    height: "100%"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
  }
}));



export default function Dashboard() {
  const classes = useStyles();
  const [showModalTutorial, setModalTutorial] = useState()
  const [openModal, setOpenModal] = useState(false);

  function closeModal(){
    conexao.post(`/ChangeShowMobileAlert/${localStorage.getItem('userId')}`,
      {
        show_painel: 0, 
      }
    )
  }

  
  function GetModal(){
    conexao.get(`/getShowMobileAlert/${localStorage.getItem('userId')}`).then((res) => {

      console.log("ONFORMAÇÔ")
      console.log(res.data.data.show_mobile_alert)
      setOpenModal(res.data.data.show_mobile_alert == 0 ? false : true)
    })
  }

  useEffect(() => {
    GetModal()
  }, [])
  return (
    <div className="Dashboard-container">
     
      <Grid container>
      <Grid item xs={12}>
      <Dialog
        open={openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className='header-tutorial'> 
          <div>
           <span className='header-tutorial-title'>Aviso Mobile</span>
          </div>
          <CloseIcon className='header-close-icon' onClick={() => {
            closeModal()
            setOpenModal(false)
          }}/>
        </div>

        <div className='header-body'>
          <iframe
            className='video-container'
            width="560"
            height="315"
            src="https://www.youtube.com/embed/7VWRxH3jqnM?autoplay=0&controls=1"
            frameBorder="0"
            allow="acceleallowFullScreenrometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
     
        </div>
        <div className='desc-tutorial'>
          <span>No Clinic Plus para uma experiência mais completa e otimizada, recomendamos acessar o sistema através de um computador.</span>
        </div>
      </Dialog>
        <Paper className={clsx(classes.paper, classes.colorPaper)}>
          <Graficos/>
        </Paper>
      </Grid>
    </Grid>
  </div>
  );
}