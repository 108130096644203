import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CircularLoad from '../../Components/Load';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import conexao from '../../Services/api';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {useHistory} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import {StateGlobal} from '../../ContextAPI/context'


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
  width: '100%',
  padding: '1rem'
  },
  divEnvolveForm:{
      display: 'flex',
      flexDirection: 'column',
      margin: '0rem',
      '& > *': {
          marginBottom: theme.spacing(3),
          width: '100%',
      },
  },
  titleModal:{
    fontSize: '1.4em',
    margin: '0',
    marginTop: '.5rem',
    marginBottom: '.5rem',
    color: '#4a4a4a',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '.5rem',
    },
  },

  divTitleModal: {
    display: 'flex',
    marginTop: '5rem',
    alignItems: 'center',
    marginLeft: '.5rem',
    marginBottom: '1rem',
  },
  divBtnSuporte:{
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '.5rem'
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
});

export default function Suporte(props) {
  
  const classes = useStyles();
  const [load, setLoad] = useState(false);
  const [mensagem, setMensagem] = React.useState('');
  const [mensagemResponse, setMensagemResponse] = React.useState('');
  const [assunto, setAssunto] = React.useState('');
  const [validaMensagem, setValidaMensagem] = React.useState(false);
  const [validaAssunto, setValidaAssunto] = React.useState(false);
  const History = useHistory();

  const {rota} = StateGlobal()

 
  

  function voltar(){

    History.push(rota)
    setMensagemResponse('');
    setMensagem('');
    setAssunto('');
}


function onChangeAssunto(event){
  setAssunto(event.target.value);
}

function onChangeMensagem(event){
  setMensagem(event.target.value);
}

async function enviaSuporte(event){
  event.preventDefault();
  var userId = '';
  setLoad(true)
  if(mensagem === '' || assunto === ''){
    setLoad(false)
    if(mensagem === ''){
        setValidaMensagem(true);
    }else{
        setValidaMensagem(false); 
    } if(assunto === ''){
        setValidaAssunto(true);
    }else{
        setValidaAssunto(false);
    }
  }else{
      setValidaMensagem(false);
      setValidaAssunto(false);
      if (localStorage.getItem('userId')) {
          userId = localStorage.getItem('userId');
          await conexao.post('/ticket/create',
          {
              "title": assunto,
              "message": mensagem,
              "status": "ENVIADO",
              "user_id": userId,
          })
          .then((response) => {
              setLoad(false)
              if(!response.data.error){
                  setMensagemResponse('Solicitação de suporte enviada com sucesso. Em breve nossa equipe entrará em contato.');
              }else{
                  setMensagemResponse('Ocorreu um erro ao enviar sua solicitação de suporte! Por favor, tente novamente mais tarde.');
              }
          }).catch((error)=>{
              setLoad(false)
              setMensagemResponse('Ocorreu um erro ao enviar sua solicitação de suporte! Por favor, tente novamente mais tarde.');
          })
      }
  }
}

  return (
    <div className="">
       <div className={classes.divTitleModal}>
        <h1 className={classes.titleModal}><ArrowBackIcon onClick={voltar}/> Suporte técnico</h1>
      </div>
      <ThemeProvider theme={theme}>
        <form onSubmit={enviaSuporte} className={classes.root} noValidate autoComplete="off">
          {!mensagemResponse?
            <div className={classes.divEnvolveForm}>
              <TextField 
                id="outlined-basic" 
                label="Assunto" 
                variant="outlined"  
                required
                value={assunto}
                onChange={onChangeAssunto}
                error={validaAssunto ?true:false}
                helperText={validaAssunto ? 'Campo obrigatório' : ''}
              />
              <TextField
                id="outlined-multiline-static"
                label="Mensagem"
                multiline
                rows={4}
                required
                variant="outlined"
                value={mensagem}
                onChange={onChangeMensagem}
                error={validaMensagem ? true:false}
                helperText={validaMensagem ? 'Campo obrigatório' : ''}
                />
            </div>
          :
            <div>
              <p>{mensagemResponse}</p>
            </div>
          }
          <div className={classes.divBtnSuporte}>
            {!mensagemResponse?
              <Button type="submit" variant="contained" className="color-primary" color="primary">
                {!load?'Enviar':<CircularLoad/>}
              </Button>
                :''}
          </div>
          </form>
      </ThemeProvider>
    </div>
  );
}      