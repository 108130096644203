import React, { useState, useEffect, useLayoutEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'

export default function InputTextoCurto({ title, id, required, edit }) {

  const [textoCurto, setTextoCurto] = useState(edit);
  const { Answer, setAnswer, respEdit, AnswerEdit, setMontagemProntuario, MontagemProntuario, PosProntuario , respView , validaProntuario , setvalidaProntuario , NameProntuario, setNameProntuario, validate } = StateGlobal();
  const [req, setReq] = useState(required);


  const handleChangeInput = event => {
    const { value } = event.target;

    Answer.map((item, index) => {
      if (item.question_id === id) {
        setTextoCurto(value)
        Answer[index].answer = value
      }
    })

    const array = MontagemProntuario

    array[PosProntuario].montagem.map((item, index) => {
      if (item.tempId === id) {
        item.answer = value
      }
    })

    setMontagemProntuario(array)
  };

  useEffect(() => {
    setTextoCurto(edit)
  }, [id])

  useEffect(()=>{
    validacaoOnchange()
  },[textoCurto])

  function validacaoOnchange() {
    if (!required) {
      return  
    }
  
    Answer.map((item) => {
      if (item.question_id === id) {  
        validaProntuario[id].error = !item.answer && validaProntuario[id].started
        validaProntuario[id].value = item.answer ? 'ok' : ''
        validaProntuario[id].started = true
  
        setvalidaProntuario({...validaProntuario})
      }
    })
  }

  return (
    <div>
      <p className="titulo-form">{title}</p>
      <TextField
        id={id}
        name={id}      
        className="mb-4"
        value={textoCurto}
        onChange={handleChangeInput}
        type="text"
        error={validaProntuario[id].error}
        required={req}
        disabled={respView}
        variant="outlined"
      
        inputProps={{ maxLength: 150 }}
      />

      <div style={{ color: 'red', width: '100%', fontSize: '15px' }}>
        {!respView && validaProntuario[id].error === true ? validaProntuario[id].message : ''}
      </div>
       
    </div>
  );
}
