import React, { useState, useEffect } from 'react';
import Header from './header';
import TabelaAgendameto from './TabelaAgendamento'
import './css/Tabela.css';
import ModalDetalhes from "../Modais/ModalDetalhesAgendamento"
import ModalBloqueado from "../Modais/ModalBloqueado"
import conexao from "../../../Services/api"
import { StateGlobal } from '../../../ContextAPI/context';
import { browserName } from 'react-device-detect';



export default function Tabela(props) {

    const [open, setOpen] = useState(false);
    const [openBloqueio, setOpenBloqueio] = useState(false);
    const [id, setId] = useState();
    const [listaAgenda, setListaAgenda] = useState('');
    const { setAgendadoData } = StateGlobal();
    const { statusTabela , setStatusTabela } = StateGlobal();
    const { setListaBloqueado } = StateGlobal();

    console.log('browserName', browserName)

    function modalteste(e) {
  
       var bloqueado
       var idBloqueio
        
       e.target.classList.forEach(item => {
            if(item == 'agendamento-bloqueado'){
                bloqueado = true
                idBloqueio = e.target.dataset.idAgendaBloqueada
            }
        });
        e.target.offsetParent.classList.forEach(item => {
            if(item == 'agendamento-bloqueado'){
                bloqueado = true
                idBloqueio = e.target.offsetParent.dataset.idAgendaBloqueada
            }
        });

        if(!bloqueado){
            if (e.target.offsetParent.dataset.idAgenda) {
                setId(e.target.offsetParent.dataset.idAgenda)
                setOpen(true)
                listaDetalhes(e.target.offsetParent.dataset.idAgenda)
            }
            
            var agenda = document.getElementsByClassName('e-appointment-border'); 
     
            if(agenda[0]){
                agenda[0].addEventListener('click', chamaModalId(e));
        
            }    
            
        }else{
          
            setOpenBloqueio(true)
            listaDetalhes(idBloqueio)
        }
        
       

    }

    function chamaModalId(e){
        
        if (e.target.dataset.idAgenda) {
            setId(e.target.dataset.idAgenda)
            setOpen(true)
            listaDetalhes(e.target.dataset.idAgenda)
        }
    }

    useEffect(() => {
        // window.alert(browserName , "browserName")

         filtroApi()
  
    },[statusTabela]);


    function editaData(data){
    
        let espaco = data.indexOf(' ')

        const h = data.slice(espaco+1)

        const t = data.slice(0, espaco)

        const arrT = t.split('-')
 
        const ano = parseInt(arrT[0])
        const mes = parseInt(arrT[1])
        const dia = parseInt(arrT[2])


        const dataCompleta = `${ano}, ${mes}, ${dia}, ${h}`
        
        return dataCompleta
        
    }

    function filtroApi(){
        const id = localStorage.getItem('userId')
       
        conexao.get(`/schedule/events/${id}`).then((resp) =>{
            
            const data = resp.data.data
            let arr = []
            data.map((item) =>{
                    
                
                if(item.status == "BLOQUEADO"){
               
                    let dataItem = {
                        
                        id: item.id,
                        type: item.status,
                        Subject: 'Horário bloqueado',
                        StartTime: browserName === "Safari" || browserName === "Mobile Safari" || browserName === "WebKit" ? new Date((item.started_at).replace(/ /g,"T")) : new Date((item.started_at)),
                        EndTime: browserName === "Safari" || browserName === "Mobile Safari" || browserName === "WebKit"? new Date((item.finished_at).replace(/ /g,"T")) : new Date((item.finished_at)),
                        

                    }
                   
                    arr.push(dataItem)
                }else{
                    let dataItem = {
        
                        id: item.id,
                        type: item.status,
                        Subject: item.patient.name,
                        StartTime: browserName === "Safari" || browserName === "Mobile Safari" || browserName === "WebKit" ? new Date((item.started_at).replace(/ /g,"T")) : new Date((item.started_at)),
                        EndTime: browserName === "Safari" || browserName === "Mobile Safari" || browserName === "WebKit" ? new Date((item.finished_at).replace(/ /g,"T")) : new Date((item.finished_at)),
                        

                    }
                    arr.push(dataItem)
                    
                }
                
                
                
            })  

           
          

            setListaAgenda(arr)

         

        }).catch((error)=>{
     
        })
       
     
    }

    function listaDetalhes(id){
         
        conexao.get(`/schedule/${id}`).then((resp) =>{
       
         if(resp.data.data[0].status !== 'BLOQUEADO'){
            setAgendadoData(resp.data.data)
        }else{
           
            setListaBloqueado(resp.data.data)
        }        

        }).catch((error)=>{

        })
    } 


    function closemodal() {
        setOpen(false)
        setStatusTabela(true)
      
    }

    function closeModalBloqueio() {
        setOpenBloqueio(false)
             
    }

    const { count, setCount } = StateGlobal();

    return (
        <div>
            <Header />
            <div className="container-tabela">
              
                <TabelaAgendameto modalteste={modalteste} agenda={listaAgenda} />
             
            </div>
            <ModalDetalhes open={open} onChange={closemodal} />
            <ModalBloqueado openBloqueio={openBloqueio} onChange={closeModalBloqueio} />
        </div>
    );

}