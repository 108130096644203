import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import { IconButton } from '@material-ui/core';
import { data} from '../../../../Components/ReduxTeste/Validate';
import { currency } from '../../../../Components/ReduxTeste/Validate';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom:'10px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color:'#8f8f8f'
  },
  AccordionDetails: {
    // display: 'flex',
    // flexDirection: 'column',
    width: '100%',
    textAlign: 'right',
    color:'#8f8f8f',
    padding: '0px'
  },
  alignRight: {
    textAlign: 'right',
    width: '100%'
  },
  amount: {
    position: 'absolute',
    top: 0,
    right: 15
  },
  buttons: {
    margin: '5px'
  },
  hr:{
   border: '1px solid #d3d3d3',
    width: '100%' 
  },
  divbuttons:{
   display:'flex',
   justifyContent:'flex-end' 
  },
  AccordionSummary:{
    display:'flex',
    flexDirection:'column'
  },
  recordDate: {
    margin: '10px 0px 0px 0px'
  },
  recordDescription: {
    margin: '15px 0px',
    color: '#888888'
  },
  badge: {
      display: 'inline-block',
      padding: '0.25em 0.4em',
      // fontSize: '75%',
      fontWeight: '700',
      lineHeight: 1,
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'baseline',
      borderRadius: '0.25rem',
      transition: 'color .15s ease-in-out,backgroundColor .15s ease-in-out,borderColor .15s ease-in-out,box-shadow .15s ease-in-out',
      marginRight: '10px',
      marginTop: '10px'

  },
  badgeSuccess: {
    color: '#155724',
    backgroundColor: '#d4edda',
    borderColor: '#c3e6cb'
  },
  badgeDanger: {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '#f5c6cb'
  },
  badgeWarning: {
    color: '#856404',
    backgroundColor: '#fff3cd',
    borderColor: '#ffeeba'
  },
  badgeInfo: {
    color: '#0c5460',
    backgroundColor: '#d1ecf1',
    borderColor: '#bee5eb'
  }
}));

export default function SimpleAccordion(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classes.AccordionSummary}>
            <h3 className={classes.recordDate}>{data(props.data.due_date)} </h3>
            <div>
              <Typography className={classes.recordDescription}>{props.data.description} </Typography>
              {/* <span className={props.data.type == 'Recebimento' ? [classes.badge, classes.badgeSuccess].join(' ') : [classes.badge, classes.badgeDanger].join(' ')}>{props.data.type}</span> */}
              
              {props.data.category_name ? 
                <span className={[classes.badge, classes.badgeInfo].join(' ')}>{props.data.category_name }</span>
              : ''}

              {props.data.payment_type_name ? 
                <span className={[classes.badge, classes.badgeWarning].join(' ')}>{props.data.payment_type_name}</span>
              : ''}
              
            </div>
            <div className={classes.amount}>
              <h3 className={classes.floatRight}>
                <span className={props.data.type == 'Recebimento' ? [classes.badge, classes.badgeSuccess].join(' ') : [classes.badge, classes.badgeDanger].join(' ')}>{props.data.type == 'Recebimento' ? '+' : '-'}</span>
                R$ {currency(props.data.value)}
              </h3>
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails className={classes.AccordionDetails}>
          <div className={classes.alignRight} >
            <IconButton
              className={classes.buttons}
              onClick={()=>{
                props.edit(props.data.id)
              }}
            > 
              <EditIcon />
            </IconButton>

            <IconButton
              className={classes.buttons}
              onClick={() => {
                props.delete(props.data.id)
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
