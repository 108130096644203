import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'


function FormataTelefone(props) {
    const { inputRef, onChange, ...other } = props;
    const [telefone, setTelefone] = useState('');

  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          setTelefone(values.value)
          console.log("values telefone",values.value.length)
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        format= {telefone.length === 11 ? '(##) # ####-####' : '(##) ####-#### #' }
      />
    );
  }
  
  FormataTelefone.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

export default function InputTelefone({id , title ,required ,edit}) {
    const [telefone, setTelefone] = useState('');

    const { Answer ,setAnswer ,respEdit , setMontagemProntuario ,MontagemProntuario , setNameProntuario, NameProntuario, PosProntuario ,setpos , respView, validaProntuario, setvalidaProntuario} = StateGlobal();

    const handleChangeInput = event => {
        const { value } = event.target;
        Answer.map((item, index) => {
            if (item.question_id === id) {
                setTelefone(value)
                Answer[index].answer = value

            }
          })
          const array  = MontagemProntuario
  
          array[PosProntuario].montagem.map((item , index)=>{
              if(item.tempId === id){

                  item.answer = value
              }
  
          })
  
          setMontagemProntuario(array)
          
  
   
          
      };
  
      useEffect(()=>{
        setTelefone(edit)
        },[id])
      
        
        useEffect(()=>{
          validacaoOnchange()
        },[telefone])
      
      
        function validacaoOnchange() {
          if (!required) {
            return  
          }
        
          Answer.map((item) => {
            if (item.question_id === id) {  
              validaProntuario[id].error = !item.answer && validaProntuario[id].started
              validaProntuario[id].value = item.answer ? 'ok' : ''
              validaProntuario[id].started = true
        
              setvalidaProntuario({...validaProntuario})
            }
          })
        }

    // useEffect(() => {
    //     Answer.map((item, index) => {
    //         if (item.question_id === id) {
    //             setTelefone(item.answer)
    //         }
    //     })
    // }, [id])

    // useEffect(()=>{

    //   Answer.map((item,index)=>{
    //     if(item.question_id === id){  
    //       if(item.answer ===  ''){                 
    //         setTelefone(edit)
    //       }else{
    //         setTelefone(item.answer)

    //       }
    //     }
    //   })      

    // },[edit])
  return (
    <div>
        <p className="titulo-form">{title}</p>
        <TextField 
            className="mb-4"
            value={telefone}
            onChange={handleChangeInput}
            type="tel"
            name="telefone"
            error={validaProntuario[id].error}
            required={required}
            disabled={respView}
            variant="outlined"
            InputProps={{
                inputComponent: FormataTelefone,
            }}
        />

        <div style={{ color: 'red', width: '100%', fontSize: '15px' }}>
          {!respView && validaProntuario[id].error === true ? validaProntuario[id].message : ''}
        </div>
    </div>
  );
}
