import React, {useState, useEffect} from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import conexao from '../../../../Services/api';
import Alert from '@material-ui/lab/Alert';
import CircularLoad from '../../../../Components/Load';
import '../css/Listagem.css';

const useStyles = makeStyles((theme) => ({
    form: {
       width:'100%',
        border: 'none',
        marginBottom: '2rem'
    },
    search: {
        background: '#f1f1f1',
        boxShadow: '0px 1px 10px rgba(25, 32, 56, 0.08)',
        borderRadius: '3px',
        height:'45px',
        fontSize:'15px',
        border: 'none',
        padding: '15px',

        
     },
     iconSearch:{
         color: '#8F8F8F'
     }
  }));


const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    }
  },
}, ptBR);


export default function CardModelosProntos() {
    const classes = useStyles();
    const [modelosProntos, setModelosProntos] = useState('');
    const [typeAlert, setTypeAlert] = useState();
    const [message, setMessage] = useState();
    const [load, setLoad] = useState(false);
    const [nameModelo, setNameModelo] = useState('');
    
  async function listaModeloPronto() {
      setLoad(true)
      await conexao.get(`/form/default/list`,{
        "search": nameModelo,
      })
        .then((response) => {
          setLoad(false)
          setModelosProntos(response.data.data);
          if(response.data.data.length<1){
            setModelosProntos('')
            setTypeAlert('error');
            setMessage('Prontuário não encontrado, verifique o nome do modelo buscado.');
            setTimeout(() => {
              setTypeAlert('');
              setMessage('');
            }, 3000);
          }
        }).catch((error) => {
            setLoad(false)
            setModelosProntos('')
            setTypeAlert('error');
            setMessage('Prontuário não encontrado, verifique o nome do modelo buscado.');
            setTimeout(() => {
              setTypeAlert('');
              setMessage('');
            }, 3000);
        })
  }

  useEffect(() => {
    listaModeloPronto();
  }, [])

  const busca = (event) => {
    setModelosProntos('')
    if (event.key === 'Enter') {
      setLoad(true)
      listaModeloPronto();
    } if (event._reactName === 'onClick') {
      setLoad(true)
      listaModeloPronto();
    }
    return true
  }


  return (
    <div className="">
            <form className={classes.root} noValidate autoComplete="off">
            <FormControl className={clsx(classes.form)}>
            <InputBase
                id="outlined-adornment-weight"              
                endAdornment={<InputAdornment position="end">{<IconButton><SearchIcon className={classes.iconSearch} onClick={(event) => busca(event)} /></IconButton>}</InputAdornment>}
                labelWidth={0}
                disabled={load ? true : false}
                onKeyDown={busca}
                onChange={(e) => setNameModelo(e.target.value)}
                placeholder="Busque por um modelo"
                className={clsx(classes.search)}
            />
            </FormControl>
            </form>
            <div>
              {load?
                <div className="div-load-lista-modelos"><CircularLoad/></div>
              :''}
            {modelosProntos ?
            modelosProntos.map((modeloPronto) => (
              <div className="CardModelos-envolve">
                  <div className="CardModelos-envolve-text-icon">
                      <AssignmentIcon className="CardModelos-icon-pront"/>
                      <p>{modeloPronto.title}</p>
                  </div>
                  <div>
                      <IconButton>
                          <VisibilityIcon/>
                      </IconButton>
                  </div>
              </div>
               )):
               <div>
                  {message ?
                    <div className="Modal-agendamento-alert">
                      <Alert variant="filled" severity={typeAlert}>
                        {message}
                      </Alert>
                    </div>
                  :''}
              </div>
               }
            </div>
    </div>
  );
}