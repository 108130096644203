import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ModalNotificacao from './ModalNotificacoes';
import { StateGlobal} from '../../ContextAPI/context'
import conexao from '../../Services/api'
import { dataehora} from '../../Components/ReduxTeste/Validate'
import {useHistory} from 'react-router-dom'


const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0x 0px 0px gray",
    borderRadius: "0px",
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
  },
  envolveNot:{
    height: '14vh',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      background: "#f9f9f9",
   },
  },
  envolveNotActive:{
    backgroundColor: '#27b8d217',
  },
  colorLateralNot:{
    width: '5px',
    height: '100%',
  },
  circuloNot:{
    backgroundColor: '#27b8d2',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
  },
  envolveNotEData:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: '1rem',
    height: '100%',
    justifyContent: 'flex-end',
  },
  pSN:{
    margin: '1rem',
    color: '#8f8f8f',
    fontSize: '1.1em'
  },
  envolveCorTitle:{
    width: '75%',
    display: 'flex',
    height: '100%',
  },
  envolveTitle: {
    marginLeft: '1rem',
    width: '100%',
    '& h5': {
      fontSize: '1em',
      marginBottom: 0,
    },
    '& p': {
      fontSize: '.9em',
      marginTop: '0.7rem',
      marginBottom: '1rem',
      },
  },
  dataNot:{
    '& p': {
      marginBottom: '1rem',
      fontSize: '.8em',
      color: '#8e8e8e',
      marginTop: '1rem',
    },
  },
  
  titleModal:{
    fontSize: '1.4em',
    margin: '0',
    marginTop: '.5rem',
    marginBottom: '.5rem',
    color: '#4a4a4a',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '.5rem',
    },
  },

  divTitleModal: {
    display: 'flex',
    marginTop: '5rem',
    alignItems: 'center',
    marginLeft: '.5rem',
    marginBottom: '1rem',
  },

  colorAgendada: {
    backgroundColor: '#e13567'
   },
   colorSistema: {
    backgroundColor: '#b1b1b1'
   },
   colorCancelada: {
    backgroundColor: '#3f51b5'
   },
   envolveNotificacoes:{
     overflow: 'auto',
     height: '67vh',
   }
}));

export default function Notificacoes() {
  const classes = useStyles();
  const [titulo, setTitulo] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [openModalNotificacao, setOpenModalNotificacao] = React.useState(false);
  const [optionNotificacao, setOptionNotificacao] = React.useState('');
  const {notification , setNotification ,rota} = StateGlobal()

  const History = useHistory()

  // function exibeMensagem(id) {
  //   setOpenModalNotificacao(true);
  //   setTitulo('Mensagem exemplo');
  //   setDescricao('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget lobortis libero. Vestibulum suscipit massa non felis bibendum, quis consectetur est fermentum. Cras vel feugiat diam. Etiam sem odio, finibus at rutrum quis, vulputate pulvinar risus. Praesent rhoncus maximus lorem, eu placerat purus elementum sit amet. Pellentesque gravida sem quis mollis fermentum. Sed pharetra suscipit fringilla. Nulla consectetur mattis purus, eget condimentum erat feugiat id. Integer tempus et orci non sollicitudin. Maecenas posuere purus vel mi gravida, at egestas erat ultricies. Mauris mattis aliquam elit sit amet rutrum. Cras luctus sed nisi vitae ornare. Proin faucibus in ipsum eu faucibus. Pellentesque justo ligula, posuere vitae leo varius, vehicula tincidunt ipsum.Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque scelerisque est eget condimentum convallis. In et semper eros. Vivamus maximus facilisis est, in porttitor mauris egestas at. Mauris vitae sapien pellentesque, egestas lacus at, dapibus arcu. Morbi molestie pulvinar ex at gravida. Etiam nec eleifend massa. Pellentesque elementum orci eros, eget mattis magna semper sed. In dictum pulvinar velit, sit amet dignissim dui tincidunt aliquet. Ut euismod, ipsum eu venenatis iaculis, lacus neque lacinia turpis, vitae sagittis metus turpis nec metus. Maecenas sed iaculis elit.');
  // }

  function exibeMensagem(title, message, id) {
    conexao.put('/notification/update/' + id, { read: 1 }).then((res) => {




      var userId = '';
      if (localStorage.getItem('userId')) {
        userId = localStorage.getItem('userId');
      conexao.get(`/notification/${userId}`).then((res) => {
        setNotification(res.data.data)        
      }).catch((error)=>{
     
      })
     
    }


    }).catch((error) => {
 
    })    
    setTitulo(title ? title : '');
    setDescricao(message ? message : '');
    setOpenModalNotificacao(true);
  }

  const handleModal = (value) => {
    setOptionNotificacao(value);
    if(value){
      exibeMensagem()
    }else{
      handleClose()
    } 
  };

  const handleClose = () => {
    setOpenModalNotificacao(false);
  }


  function voltar(){

    History.push(rota)
    // <ArrowBackIcon onClick={()=>{voltar()}}/>
 
}

  return (
    <div className="">
      <div>
      <div className={classes.divTitleModal}>
        <h1 className={classes.titleModal}><ArrowBackIcon onClick={()=>{voltar()}}/>Notificações</h1>
      </div>
      <div className={classes.envolveNotificacoes}>
        {notification ?
          notification.map((item) => {
            let color = 'colorCancelada'
            if (item.message) {
              if (item.message.type.id === 4) {
                color = 'colorSistema'
              }
              if (item.message.type.id === 3) {
                color = 'colorCancelada'
              }
              if (item.message.type.id === 2) {
                color = 'colorAgendada'
              }
              if (item.message.type.id === 1) {
                color = 'colorAgendada'
              }
            }
            return (
              <div
                className={item.read === 0 ? clsx(classes.envolveNot, classes.envolveNotActive) : classes.envolveNot}
                onClick={() =>
                  item.message ?
                    exibeMensagem(item.message.title, item.message.message, item.id) : ''}>
                <div className={classes.envolveCorTitle}>
                  <div className={clsx(classes.colorLateralNot, classes[color])}></div>
                  <div className={classes.envolveTitle}>
                    <h5>{item.message ? item.message.type.name : ''} </h5>
                    <p>{item.message ? item.message.title : ''} </p>
                  </div>
                </div>
                <div className={classes.envolveNotEData}>
                  <div ></div>
                  {item.read === 0 ?
                    <div className={classes.circuloNot}></div> : ""}
                  <div className={classes.dataNot}><p>{item ? dataehora(item.send_at) : ''}</p></div>
          
                </div>
              </div>)
          })
          : 
          <div>
            <p className={classes.pSN}>Você ainda não possui notificações!</p>
          </div> 
          }
        {/* <div 
          className={clsx(classes.envolveNot)}
          onClick={()=>exibeMensagem(2)}>
          <div className={classes.envolveCorTitle}>
          <div className={clsx(classes.colorLateralNot,classes.colorSistema)}></div>
            <div className={classes.envolveTitle}>
              <h5>Atualização de sistema</h5>
              <p>Lorem </p>
            </div>
          </div>
          <div className={classes.envolveNotEData}> 
            <div className={classes.circuloNot}></div>
            <div className={classes.dataNot}><p>13/12/2020 às 12:00</p></div>
          </div>
        </div> */}
        {/* <div 
          className={clsx(classes.envolveNot, classes.envolveNotActive)}
          onClick={()=>exibeMensagem(3)}>
          <div className={classes.envolveCorTitle}>
            <div className={clsx(classes.colorLateralNot,classes.colorAgendada)}></div>
            <div className={classes.envolveTitle}>
              <h5>Consulta agendada</h5>
              <p>Lorem  ll</p>
            </div>
          </div>
          <div className={classes.envolveNotEData}> 
            <div ></div>
            <div className={classes.dataNot}><p>13/12/2020 às 12:00</p></div>
          </div>
        </div> */}
      </div>
      </div> 
      <ModalNotificacao
        handleModal={handleModal}
        open={openModalNotificacao} 
        titulo={titulo} 
        descricao={descricao}
      />
  </div>
  );
}      