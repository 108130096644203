import React,{useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  grayCor: {
    color: '#8f8f8f',
  },
  blueCor: {
    color: '#27b8d2',
  },
  greenCor:{
    color: '#4caf50',
  },
  pinkCor:{
    color: '#e7366a',
  }
}));

export default function InfoPacientes(props) {
  const classes = useStyles();
  const [dadosAgendaC, setDadosAgendaC] = useState(props.dados.canceled);
  const [dadosAgendaA, setDadosAgendaA] = useState(props.dados.scheduled);
  const [ dadosAgendaB, setDadosAgendaB] = useState((props.dados.total) - (props.dados.canceled))

  const [load , setLoad] = useState(true)

  useEffect(() => {
    setDadosAgendaC(props.dados.canceled);
    setDadosAgendaA(props.dados.scheduled);
    setDadosAgendaB((props.dados.total) - (props.dados.canceled) - (props.dados.blocked));
    numberStates()
  }, [props])

  async function numberStates() {

    if(props.dados.length !== 0){
      setLoad(false)
    }    
  }

console.log("oxe",props )

  return (
          <div className="Dashboard-Graficos-info-pacientes">
            <div className={clsx(classes.grayCor, "Dashboard-Graficos-info-pacientes-agendados")}>
              <p className="Dashboard-Graficos-numero-pacientes">
                  {load === true ? <CircularProgress/> : 
                  dadosAgendaB}
              </p>
              <p className="Dashboard-Graficos-text-pacientes">Pacientes atendidos</p>
              <DateRangeIcon className="Dashboard-Graficos-icon-pacientes"/>
            </div>
            <div className={clsx(classes.pinkCor, "Dashboard-Graficos-info-pacientes-faltaram")}>
              <p className="Dashboard-Graficos-numero-pacientes">
                {load === true ? <CircularProgress/> : 
                dadosAgendaC}
              </p>
              <p className="Dashboard-Graficos-text-pacientes">Agendamentos cancelados</p>
              <ClearIcon className="Dashboard-Graficos-icon-pacientes"/>
            </div>
          </div>
  );
}
