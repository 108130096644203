import React, { useState, useEffect } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import ListIcon from '@material-ui/icons/List';
import { BsPlusCircleFill } from 'react-icons/bs'

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import './style.css'


import { StateGlobal } from '../../../../../ContextAPI/context'
import { keys } from '@material-ui/core/styles/createBreakpoints';
import VistaAnterior from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_anterior.png';
import VistaInferior from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_inferior.png';
import VistaLateral from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_lateral.png';
import VistaPosterior from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_posterior.png';
import SistemaMuscular from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_sistema_muscular.png';
import SistemaViceral from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_sistema_visceral.png';
import { TrainRounded } from '@material-ui/icons';
import imgScale from '../../../../../Assets/Images/reguador_mobile.png'




const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '97%',
    },
  },
  formControl: {
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',

    background: '#F9F9F9'
  },
  inputEscala: {
    width: '70%',
  },
  divSelect:{
    display:'flex',
    flexDirection:'column',
  },
  selectButon:{
    display:'flex',
    width: '100%',
    alignItems:'center',
    marginTop: '15px',
    '& > div':{
      width: '100%',
      marginRight: '5px'
    }
  },
  imgScale:{
    width: '100%'
  },
  dor:{
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end'
  }

}));



export default function CardTextoCurto({ title, id, required, edit, options, answer }) {
  const classes = useStyles();
  const history = useHistory();
  const [newname, setNewname] = useState();

  const [checked, setcheck] = useState(false)
  const [titulocampo, setTituloCampo] = useState();
  const [tipo, setTipo] = useState('...');
  const [img, setImg] = useState();
  const [escala, setEscala] = useState('...')
  const [listagem, setListagem] = useState([])
  const [error, setError] = useState(false)
  const [req, setReq] = useState(required);



  const { Answer, setAnswer, respEdit, AnswerEdit, setMontagemProntuario, MontagemProntuario, PosProntuario, respView, validaProntuario, setvalidaProntuario, validation, setValidation,
    attValidation, setAttValidation, attEncerraError } = StateGlobal();




  useEffect(() => {
    let arr = []

    tipos.forEach((i, indece) => {
      if (title === i.type) {
        setImg(tipos[indece].img)
        // setTipo(options[0].text)
        setTituloCampo(tipos[indece].tipo)
      }
    })

    if (Answer.length) {
      if (respEdit) {
        const dados = Answer.find(item => item.question_id === id)
  
        dados?.answer.map((item) => {
          if (item.form_step_question_option_id) {
            arr.push({ 
              question_id: item.id,
              tipo: ChangeOption(item.form_step_question_option_id),
              escala: item.text,
              id: item.form_step_question_option_id,
              cor: ChangeColor(item.text) 
            })
  
            UpdateAnswer(arr)
          }
        })
      }
    }

    setListagem(ReturnAnswer())
  }, [id])

  useEffect(()=>{
    validacaoOnchange()
  },[listagem])


  function validacaoOnchange() {
    if (!required) {
      return  
    }
  
    Answer.map((item) => {
      if (item.question_id === id) {  
        validaProntuario[id].error = !item.answer && validaProntuario[id].started
        validaProntuario[id].value = item.answer ? 'ok' : ''
        validaProntuario[id].started = true
  
        setvalidaProntuario({...validaProntuario})
      }
    })
  }


  const tipos = [
    { tipo: 'Mapa EVA - Crânio - Vista anterior', img: VistaAnterior, type: 'cranianovistaanterior' },
    { tipo: 'Mapa EVA - Crânio - Vista inferior', img: VistaInferior, type: 'cranianovistainferior' },
    { tipo: 'Mapa EVA - Crânio - Vista lateral Direita', img: VistaLateral, type: 'cranianovistalateraldireita' },
    { tipo: 'Mapa EVA - Crânio - Vista posterior', img: VistaPosterior, type: 'cranianovistaposterior' },
    { tipo: 'Mapa EVA - Sistema Muscular Esquelético', img: SistemaMuscular, type: 'musculoesqueletico' },
    { tipo: 'Mapa EVA - Sistema visceral', img: SistemaViceral, type: 'visceral' }]


  const escaladedor = [
    { escala: 0, cor: '#7DB3DC' },
    { escala: 1, cor: '#036EA2' },
    { escala: 2, cor: '#485779' },
    { escala: 3, cor: '#018064' },
    { escala: 4, cor: '#34AD3A' },
    { escala: 5, cor: '#E7E92B' },
    { escala: 6, cor: '#F8B302' },
    { escala: 7, cor: '#E47617' },
    { escala: 8, cor: '#E05414' },
    { escala: 9, cor: '#E02617' },
    { escala: 10, cor: '#9D2725' }]

  function handlerChange(e) {
    const value = e.target.value
    // console.log('selecionou tipo', value)
    setTipo(value)

  }
  function handlerChange2(e) {
    const value = e.target.value
    setEscala(value)

  }
  function ChangeColor(escala) {
    var cor = ''
    escaladedor.map((item) => {
      //console.log('ESCALA cor',item.escala.toString() , item.cor , 'ESCALA ATUAL' , escala)            
      if (item.escala.toString() === escala) {
        //console.log('ESCALA cor', item)
        cor = item.cor
      }
    })
    return cor

  }

  function ChangeOption(tipo) {
    var data = ''
    options.map((item) => {
      if (item.text === tipo || item.id === tipo) {
        data = item
      }
    })

    return data.text
  }


  function UpdateAnswer(arr) {
    Answer.map((item, index) => {
      if (item.question_id === id) {
        item.answer = arr
                
        if(req){
          if( !arr[0] ){
            setError(true)
          }
          else{
            setError(false)
          }
        }
      }
    })

    setAnswer(Answer)
  }


  function ReturnAnswer() {
    let returnedAnswer;
    Answer.map((item) => {
      if (item.question_id === id) {
        returnedAnswer = item.answer
      }
    })

    return returnedAnswer
  }
  
  return (
    <div className="CardTextoCurto-container" >
      <div className="container-card-input">
   
        <div className="titleEscale">
          <h3 className="h3-title-card-input">{titulocampo}</h3>
        </div>

        <div >
          <img className={classes.imgScale} src={imgScale} alt="" />
        </div>

        {respView ? '' :
          <div className={classes.divSelect}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple" >Tipo</InputLabel>
              <Select
                native
                label="Tipo"
                onChange={handlerChange}
                value={tipo}
              >
                {<option>...</option>}
                {options ? options.map((item) => {

                  return <option value={item.text}>{item.value} - {item.text}</option>

                }) : ''}
              </Select>
            </FormControl>
            <div className={classes.selectButon}>    
              <FormControl variant="outlined" >
                <InputLabel htmlFor="outlined-age-native-simple" >Intensidade</InputLabel>
                <Select
                  native
                  label="Intensidade"
                  onChange={handlerChange2}
                  value={escala}
                >
                  {<option>...</option>}

                  {escaladedor.map((item) => {

                    return <option value={item.escala}>{item.escala}</option>

                  })}
                </Select>
              </FormControl>
           
              <BsPlusCircleFill
                style={{ color: tipo && escala !== '...' ? '#27b8d2' : '#5d5d5d7a', fontSize: '30px' }} 
                onClick={() => {
                  if (tipo && escala !== '...'){
                    var data = ''

                    options.map((item) => {
                      if (item.text === tipo) {
                        data = item
                      }
      
                    })
      
                    Answer.map((item) => {
      
                      if (item.question_id === id) {
                        item.answer.push({ cor: ChangeColor(escala), tipo: tipo, escala: escala, id: data.id })
                      }
                    })
      
                    const dados = Answer.find(item => item.question_id === id)
      
                    setListagem(dados?.answer.slice())
                    setAnswer(Answer)
                    setEscala('...')
                    setTipo('...')
                  }
                }} 
              />
            </div>
          </div>
          }
        
        <div style={{ display: 'flex', flexDirection: 'column' }} >
          {listagem && listagem.length ? listagem.map((item, index) => {
            return (
              <div style={{ borderRadius: "5px", border: '1px solid gray', flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: '10px', justifyContent: "space-between" }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ borderRadius: '50%', backgroundColor: item.cor, height: '20px', width: '20px', margin: '15px' }}>

                  </div>
                  
                  <div>{item.tipo}</div>
                </div>
                <div className={classes.dor}>
                <div style={{ marginRight: '10px'}}>Dor: {item.escala}</div> 
                <div>
                  {respView ? ' ' :
                    <IconButton onClick={() => {
                      listagem.splice(index, 1)
                      const arr = listagem.slice()

                      setListagem(arr)
                      UpdateAnswer(arr)

                    }}>
                      <DeleteIcon />
                    </IconButton>}
                </div>
                </div>
              </div>
            )
          }) : ''}

          {listagem && !listagem.length && respView ? (
            <div 
              style={{ 
                borderRadius: "5px", 
                border: '1px solid gray', 
                flexDirection: 'row', 
                display: 'flex', 
                alignItems: 'center', 
                marginTop: '10px', 
                justifyContent: "space-between"
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ borderRadius: '50%', height: '20px', margin: '15px' }}></div>
                <div>Campo não preenchido...</div>
              </div>
            </div>
          ) : ''}
        </div>

        <div style={{ color: 'red', width: '100%', marginTop: '10px', fontSize: '15px' }}>
          {!respView && validaProntuario[id].error === true ? validaProntuario[id].message : ''}
        </div>
      </div>
    </div>
  );
}