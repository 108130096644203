import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'

export default function InputSelect({ data ,id , title, required}) {
    const [value, setValue] = React.useState('1');
    const [options, setOptions] = React.useState([]);
    const { Answer ,setAnswer,respView, validaProntuario, setvalidaProntuario } = StateGlobal();

    const handleChangeInput = event => {
        const { value } = event.target;
      
        Answer.map((item, index) => {
            if (item.question_id === id) {
               
                setValue(item.text)
                Answer[index].answer = value
                
        
            }
        })
        console.log(Answer)
    };



    useEffect(() => {
      
        const id = data.tempId
   
        if(data.opcoes){
           
            data.opcoes.forEach((item)=>{
        
       
            Answer.map((answer, index) => {
                                 
                if (answer.question_id == id) {
                    if (respView) {

                        if(answer.answer){
    
                            setValue(answer.answer)   
    
                            
                        }else{
                            setValue(data.opcoes[0].value)   
                            Answer[index].answer = data.opcoes[0].value
                           
                        }
                    }
                }
            })
            
            })
        }
       
        setOptions(data.opcoes)

        if(data.answers){
            data.answers.map((item)=>{
                setValue(item.form_step_question_option_id)
            })
        }
    }, [id])

    useEffect(()=>{
        validacaoOnchange()
    },[value])
    
    
    function validacaoOnchange() {
        if (!required) {
          return  
        }
      
        Answer.map((item) => {
          if (item.question_id === id) {  
            validaProntuario[id].error = !item.answer && validaProntuario[id].started
            validaProntuario[id].value = item.answer ? 'ok' : ''
            validaProntuario[id].started = true
      
            setvalidaProntuario({...validaProntuario})
          }
        })
    }



   



    // useEffect(() => {
    //     Answer.map((item, index) => {
    //         if (item.question_id === id) {
    //             setNumerico(item.answer)
    //         }
    //     })
    // }, [id])

    return (
        <div>
            <p className="titulo-form">{title}</p>
            <TextField
                id="select"
                select
                value={value}
                required={true}
                disabled={respView}
                onChange={handleChangeInput}
                error={validaProntuario[id].error}
                SelectProps={{
                    native: true,
                }}
                variant="outlined"
            >
                {data.answers && !data.answers.length ? (
                    <option value="" selected>...</option>    
                ) : (
                    <>
                        <option value="" selected>...</option>
                        {options.map((item , index) => {
                            return (
                                <option key={index} value={item.value}>
                                    {item.text}
                                </option>
                            )

                        })}
                    </>
                )}
            </TextField>

            <div style={{ color: 'red', width: '100%', fontSize: '15px' }}>
                {!respView && validaProntuario[id].error === true ? validaProntuario[id].message : ''}
            </div>
        </div>
    );
}
