import React, {useState, useEffect} from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Alert from '../../../Components/Alert';
import TextField from '@material-ui/core/TextField';
import Avatar from './avatar';
import {telefones, cpf, rg } from '../../../Components/ReduxTeste/Validate';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import conexao from '../../../Services/api';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { StateGlobal } from '../../../ContextAPI/context';
import AlertSave from '@material-ui/lab/Alert';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import {campovazio, email, CPF, RG, phone, cepValid} from '../../../Components/ReduxTeste/ValidateForm';
import CircularProgress from '@material-ui/core/CircularProgress';


import './style.css'

const useStyles = makeStyles((theme) => ({
    paper: {
      boxShadow: "0px 0px 0px 0px gray",
      borderRadius: "0px",
      height: "100vh !important",
      overflow: "auto"
    },
    colorPaper: {
      backgroundColor: "#f9f9f9",
      boxShadow: "0px 0px 0px 0px gray",
    },
    colorPaperWhite:{
      backgroundColor: "#fff",
      boxShadow: "0px 0px 0px 0px gray",
      padding: '2rem'
    },
    envolveCadastrar:{
        marginTop: '0rem',
        height: '87vh',
        padding: '2rem',
        paddingTop:"0"
    },
    titulo:{
        fontSize: '1.3em',
        color: '#219FB6 !important',
        marginBottom: '1rem !important',
        fontWeight: '600'
    },
    containerFields:{
        display:'flex',
        flexDirection:'column',
        width: '100%',
         
    },
    fields:{
        marginBottom: '30px'
    },
    container: {
        display:'flex',
        justifyContent:'space-between'  
    },
    genero:{
      display:'flex',
      flexDirection:'row'
    },
    generoDiv:{
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    generoContainer:{
      display:'flex',
      flexDirection:'column',
  
      
      
    },
    labelGenero:{
      marginRight: '10px',
      fontSize: '1rem',
      color:'#8F8F8F',
    },
    doc:{

      display:'flex',
      justifyContent:'space-between',
      marginBottom:'1.5rem',
      flexDirection:'column',
   
    },
    cpf:{
      width:'100%',
      marginBottom:'1.5rem'
    },
    textbox:{
      display:'flex',
      flexDirection:'column',
      marginBottom:'1.5rem'
      
    },
    small:{
      color:'#8F8F8F',
      fontStyle:'italic'
    },
    mb4:{
      marginBottom: '1.5rem'
    },
    tel:{
      width: '100%',
      marginBottom:'1.5rem'
    },
    sms:{
      color: '#0DB662',
    },
    end:{
      width:'100%',
      marginBottom:'1.5rem'
    },
    num:{
      width:'100%'
    },
    comp:{
      width:'100%',
      marginBottom:'1.5rem'
    },
    bairro:{
      width:'100%'
    },
    buttons:{
      marginTop: '3rem',
      display:'flex',
      justifyContent: 'flex-end',
      paddingBottom:'5rem',
      flexDirection:'column-reverse',
      '& > button': {
        marginBottom:'1.5rem'
      },
    },
    alerts:{
      marginTop: '30px',      

    },
    sexError:{
      color:'#f44336',
    },
    smallError:{
      color:'#f44336',
      marginLeft: '14px',
      marginRight: '14px',
      fontSize: '0.75rem',
      marginTop: '3px'
    }      
      
  }));

  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#27b8d2',
        contrastText: '#FFF',
      },
      secondary: {
        light: '#FFFFFF',
        main: '#FFFFFF',
        dark: '#FFFFFF',
        contrastText: '#8F8F8F',
      },
      

    },
    
  }, ptBR);

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#E7366A',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#8F8F8F',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[400],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });
  

  function FormataCelular(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        format='(##) # ####-####'
      />
    );
  }
  
  FormataCelular.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  function FormataTelefone(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        format='(##) ####-####'
      />
    );
  }
  FormataTelefone.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

export default function Cadastrar(){
  const classes = useStyles();
  const [tel, setTel] = useState("")
  const [cell, setCell] = useState("")
  const [cpfDoc, setCpfDoc] = useState("")
  const [docRg, setDocRg] = useState("")
  const [cep, setCep] = useState("")
  const [uf, setUf] = useState()
  const {isEditPatient, setIsEditPatient , setViewPatient, ViewPatient, Location, pacienteName} = StateGlobal()
  const [editarStatus, setEditarStatus] = useState()
  const [idPaciente, setIdPaciente] = useState()
  const [isAlert, setIsAlert] = useState(false)
  const [isError, setIsError] = useState(true)
  const [erros, setErros] = useState()
  const [errosValide, setErrosValide] = useState({})
  const [isLoading, setIsloading] = useState(true)
  const [img, setImg] = useState()
  const [zipCode, setZipCode] = useState()
  const [errorPatient, setErrorPatient] = useState()
  const [dados, setDados] = useState({
    "name": "",
    "email": "",
    "cpf": "",
    "rg": "",
    "phone": "",
    "cellphone":"",
    "is_whatsapp": true,
    "birthday": "",
    "mothers_name": "",
    "fathers_name": "",
    "how_met": "",
    "notification": true,
    "street": "",
    "neighborhood": "",
    "number": '',
    "city": "",
    "state": "",
    "complement": "",
    "zip_code": "",
    "doctor_id": "",
    "avatar":""
})
const [validatesx , setvalidatesx] = useState([
  {name:'name' , funcao : campovazio , error:false},
  {name:'birthday' , funcao : campovazio , error:false},
  {name:'email' , funcao : email , error:false},
  {name:'cpf' , funcao : CPF , error:false},
  {name:'rg' , funcao : RG , error:false},
  {name:'mothers_name' , funcao : campovazio , error:false},
  {name:'fathers_name' , funcao : campovazio , error:false},
  {name:'phone' , funcao : campovazio , error:false},
]);

const [validateTest, setValidateTest] =  useState({
  name:{name:'name', funcao: campovazio, error:false},
  email:{name:'email', funcao: email, error:false},
  birthday:{name:'birthday', funcao:campovazio, error:false},
  cpf:{name:'cpf', funcao:CPF, error:false},
  rg:{name:'rg', funcao:RG, error: false},
  mothers_name:{name:'mothers_name', funcao:campovazio, error:false},
  fathers_name:{name:'fathers_name', funcao:campovazio, error:false},
  phone:{name:'phone', funcao:phone, error:false},
  cellphone:{name:'cellphone', funcao:phone, error:false},
  zip_code:{name:'zip_code',funcao: cepValid, error:false},
  street:{name:'street', funcao: campovazio, error:false},
  number:{name:'number', funcao: campovazio, error:false},
  city:{name:'city', funcao: campovazio, error:false},
  neighborhood:{name:'neighborhood',funcao: campovazio, error:false},
  state:{name:'state',funcao: campovazio, error:false},
  complement:{name:'complement',funcao: campovazio, error:false},
  sex:{name:'sex', funcao: campovazio, error:false},
})

  const history = useHistory()

    useEffect(() => {
      const doctor_id = localStorage.getItem('doctorId')

      setDados({...dados, 'doctor_id': parseInt(doctor_id)})



      getEstados()
      
      console.log('Location:', Location)
      if(isEditPatient == true){
        if(Location === 'agendamento'){
          chamada()
      
          setEditarStatus(true)
        }else{
          
          getFilterPacientes(pacienteName)
        }
        
      }else{
        setIsloading(false)
      }
      
      

    },[])


    async function chamada(){ 
    
      const agendamentoId = await localStorage.getItem('agendamentoId')
     
      await conexao.get(`/form/patient/schedule/${agendamentoId}`)
      .then((resp) => {
        
        getFilterPacientes(resp.data.data.name)
      
      }).catch((err)=>{

      })
    }

    async function getFilterPacientes(name){
      const idDoutor = await localStorage.getItem('doctorId')
      const idPatient = await localStorage.getItem('patientID')
      const data = {
        "name": name,
        "cpf": ""
      }
      await conexao.post(`/patient/filter/${idDoutor}`, data)
      .then((resp) => {

        const arr = resp.data.data.data
    
        arr.forEach(item => {
            
            if(item.id == idPatient){
              
              getPatient(item.cpf, item.name, idDoutor)
              setIdPaciente(idPatient)
            }
        });
        setTimeout(() => {
          setIsloading(false)
        }, 1000);
      }).catch((error)=>{
  
        console.log(error)
      })
    }

    async function getAllPacientes(){
      const idDoutor = await localStorage.getItem('doctorId')
      const idPatient = await localStorage.getItem('patientID')
     
      await conexao.post(`/patient/get/${idDoutor}`)
      .then((resp) => {
       
        const arr = resp.data.data.data
    
        arr.forEach(item => {
            
            if(item.id == idPatient){
              
              getPatient(item.cpf, item.name, idDoutor)
              setIdPaciente(idPatient)
            }
        });
        setTimeout(() => {
          setIsloading(false)
        }, 1000);
      }).catch((error)=>{
  
        console.log(error)
      })
    }

    function onchangeValidateTeste(e){
      const{name , value } = e.target
      const obj = validateTest[name]
  
              const er =  validateTest[name].funcao(value)
              let att = {...obj, error: er}
              
     
      setValidateTest({...validateTest, [name]: att  })
    
    }

    async function getPatient(patientCpf,  patientName, doctorId){

      await conexao.post('/patient/filter/'+doctorId, 
      {
        "name": patientName,
        "cpf": patientCpf    
      }).then((resp) =>{
        

        if(resp.data.data.data[0].birthday){
          var birt = resp.data.data.data[0].birthday.split(" ")
        }else{
          var birt = ''
        }

        let paciente = resp.data.data.data

        let info = {
          "name": paciente ? paciente[0].name : '',
          "cellphone": paciente ? paciente[0].cellphone : '',
          "email": paciente ? paciente[0].email : '',
          "cpf": paciente ? paciente[0].cpf : '',
          "rg": paciente ? paciente[0].rg : '',
          "phone": paciente ? paciente[0].phone : '',
          "is_whatsapp": paciente ? paciente[0].is_whatsapp : '',
          "birthday": paciente ? birt[0] : '',
          "mothers_name": paciente ? paciente[0].mothers_name : '',
          "fathers_name": paciente ? paciente[0].fathers_name : '' ,
          "how_met": paciente ? paciente[0].how_met : '',
          "notification": paciente ? paciente[0].notification : '',
          "street": paciente[0].address ? paciente[0].address.street : '',
          "neighborhood": paciente[0].address ? paciente[0].address.neighborhood : '',
          "number": paciente[0].address ? paciente[0].address.number : '',
          "city": paciente[0].address ? paciente[0].address.city : '',
          "state": paciente[0].address ? paciente[0].address.state : '',
          "complement": paciente[0].address ? paciente[0].address.complement : '',
          "zip_code": paciente[0].address ? paciente[0].address.zip_code : '',
          "sex": paciente[0].sex,
          "doctor_id":Number(doctorId)
        }
        setDados(info)
           
        setCell(paciente[0].cellphone ? paciente[0].cellphone : '')
        setTel(paciente[0].phone)   
        setDocRg(paciente[0].phone ? rg(paciente[0].rg) : '')
        setCpfDoc(paciente[0].cpf ? cpf(paciente[0].cpf) : '')

        setCep(paciente[0].address.zip_code) 
        setImg(paciente[0].avatar)

        alterAlert()
        setIsError(false)


      }).catch((error)=>{
        alterAlert()
        setIsError(true)

      })
      
    }

 
    function onChangeInput(e){
          console.log(e.target.value)
      const { name, value } = e.target
      setDados({...dados, [name]: value})

      if(e.target.name == 'is_whatsapp' || e.target.name == 'notification'){
        const { name, checked } = e.target
        setDados({...dados, [name]: checked})
      }
      if(e.target.name == 'cellphone'){
     
        const celular = e.target.value

        setCell(celular)

        if(cell){
       
          let noMask = celular.replace(/\D/g, "")
           
          const { name, value } = e.target
          setDados({...dados, [name]: noMask})

        } 
      }
      if(e.target.name == 'phone'){
        
        const telefone = e.target.value

        setTel(telefone)

        if(tel){
          
          let noMask = telefone.replace(/\D/g, "")
             
          const { name, value } = e.target
      
          setDados({...dados, [name]: noMask})

        }
      }
      if(e.target.name == 'cpf'){
        const doc = cpf(e.target.value)
        
        setCpfDoc(doc)

        if(cpfDoc){
          const { name, value } = e.target

          let noMask = value.replace(/\D/g, "")  
         
          setDados({...dados, [name]: noMask})

         
        }
      }
      
      if(e.target.name == 'rg'){
        const doc = rg(e.target.value)
        setDocRg(doc)
        if(docRg){
          const { name, value } = e.target
          
          let noMask = value.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"")
          

          setDados({...dados, [name]: noMask})
        }

      }
      if(e.target.name == 'zip_code'){
        
        let value = e.target.value
        
        const valor = maskCep(value)

        setCep(valor) 

        if(cep){
          const noMask = value.replace(/\D/g, "").split('-')[0];

          setDados(prevState => ({...prevState, [name]: noMask}))
          
          // if(noMask.length >= 8 ){
          //   getCep(noMask)
          // }
        }
        
      }
      
    }

    function unMaskZipCode(zipcode) {
      let value = zipcode.replace(/\D/g, "").split('-')[0];
      return value
    }


    function alterAlert(){
      setIsAlert(true)
      setTimeout(() => {
        setIsAlert(false)
      }, 3000);
     
    }

    function maskCep(value){
      if(value !== null && value !== undefined && value !== '' ){
        value = value.replace(/\D/g, "");

        value = value.replace(/(\d{2})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1-$2")
          .replace(/(-\d{3})\d+?$/, '$1')

        return value
      }
    }
    
    async function getEstados(){
      await conexao.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((resp) => {
        setUf(resp.data)
      })
    }

    function validate(values, nameInput){
      const errors = {}
     
      if(nameInput === 'name'){      
        if(values === ""){
          errors[nameInput] = {error: true, helperText: 'Campo nome requerido'}
        }
      }
      if(nameInput === 'email'){
        if(values === ""){
          errors[nameInput] = {error: true, helperText: 'Campo nome requerido'}
        
        }
        if(!values.includes('@')){
          errors[nameInput] = {error: true, helperText: 'Insira um email válido.'}
        }
      }

      return (errors)
    }

    async function salvar(){
      
      await conexao.post('/patient/create', dados)
      .then((resp) => {

        alterAlert()
        setIsError(false)
        localStorage.setItem('patientID', resp.data.data.id)
        history.push('/listagem-pacientes')        
       
      }).catch((error)=>{
        console.log('error:', error)
        let obj = error.response.data.data

        let data = validateTest

        if(error.response.data.error === true){
          
          setErrorPatient(error.response.data.message)

        }else{
          Object.keys(obj)?.forEach(function(name){
            
            const obj = validateTest[name]

                const er =  'Preencha o campo corretamente'
                let att = {...obj, error: er}
                
                data = {...data,[name]:att}
                
         });
        }
        

         setValidateTest(data)

        alterAlert()
        setIsError(true)
        
      })

    }

    async function salvarAdd(){
      await conexao.post('/patient/create', dados)
      .then((resp) => {

        alterAlert()
        setIsError(false)
        const dado ={
          "avatar":"",
          "name": "",
          "email": "",
          "cpf": "",
          "rg": "",
          "phone": "",
          "cellphone":"",
          "is_whatsapp": true,
          "birthday": "",
          "mothers_name": "",
          "fathers_name": "",
          "how_met": "",
          "notification": true,
          "street": "",
          "neighborhood": "",
          "number": '',
          "city": "",
          "state": "",
          "complement": "",
          "zip_code": "",
          "doctor_id": parseInt(localStorage.getItem('doctorId'))
        }
        setDados(dado)
        setCpfDoc('')
        setDocRg('')
        setCpfDoc('')
        setCell('')
        setTel('')
        setCep('')
        setImg('')
       
      }).catch((error)=>{

        setErros(error.response.data.data)
        alterAlert()
        setIsError(true)
        
      })
    }
    
    async function salvarEdit(){

      await conexao.post('/patient/create', dados)
      .then((resp) => {

        localStorage.setItem('patientID', resp.data.data.id)
        alterAlert()
        setIsError(false)
        setIsEditPatient(true)
        setEditarStatus(true)
        setIdPaciente(resp.data.data.id)
        setViewPatient(false)
       
      }).catch((error)=>{
       
        setErros(error.response.data.data)
        alterAlert()
        setIsError(true)
      })
    }

    async function editar(){
      await conexao.put(`/patient/update/${idPaciente}`, dados)
      .then((resp) => {
      
        alterAlert()
        setIsError(false)
        history.push('/listagem-pacientes')  
       
      }).catch((error)=>{

        // setErros(error.response.data.data)
        // alterAlert()
        // setIsError(true)
        console.log(error.response)

        let obj = error.response.data.data

        let data = validateTest

        if(!error.response.data.data){
          
          setErrorPatient(error.response.data.message)

        }else{
          Object.keys(obj).forEach(function(name){
            
            const obj = validateTest[name]

                const er =  'Preencha o campo corretamente'
                let att = {...obj, error: er}
                
                data = {...data,[name]:att}
                
         });
        }
        

         setValidateTest(data)

        alterAlert()
        setIsError(true)
      })
    }
    
    const getImage = img =>{
      
      const obj = {...dados, 'avatar':img}
      setDados(obj)
    }

    const searchPostalCode = async postalCode => {
      let postalCodeUnmasked = postalCode.replace(/\D/g, "").split('-')[0];

      if (postalCodeUnmasked.length === 8) {
        await axios
          .get(`https://viacep.com.br/ws/${postalCodeUnmasked}/json/`)
          .then(res => {
            setDados({
              ...dados, 
              'street': res.data.logradouro,
              'state': res.data.uf,
              'city': res.data.localidade,
              'neighborhood': res.data.bairro,
              'complement': res.data.complemento,
              'zip_code': postalCodeUnmasked
            })
          })
      }
    }
   
   
    return(
    <div className={classes.envolveCadastrar}>
        
            {/* <Alert/> */}
        <ThemeProvider theme={theme}>
          <Avatar handleImg={getImage} imagem={img} loading={isLoading}/>
            {/* <h3 className={classes.titulo}>Pedro Silva</h3> */}
            <div className={classes.container}> 
            
                <div className= {classes.containerFields}>
                <h3 className={classes.titulo}>GERAL</h3>
                {isAlert ? 
                <div className={classes.alerts}>   
                { isError ? 
                <AlertSave variant="filled" severity="error">
                  {errorPatient ? errorPatient : 
                    'Confira os dados!'
                  }
                </AlertSave>
                :
                <AlertSave variant="filled" severity="success">
                  Salvo com sucesso!
                </AlertSave>     
                } 
                
                </div>   
                
                : "" 
                } 
                { isLoading ?
                  <div className='loadingPaciente'><CircularProgress /></div> 
                  :
                <>

                    <TextField
                        className={classes.fields}
                        label='Nome'
                        type="text"
                        name='name'
                        required={true}
                        variant="outlined"
                        value={dados.name}
                        inputProps = {{maxLength:150}}
                        onChange={(e) => {onChangeInput(e); onchangeValidateTeste(e)} }
                        onBlur = {onchangeValidateTeste}
                        // error = {erros ? erros.name ? true : false :''} 
                        // error = {validatesx[0].error ? validatesx[0].error ? true : false :''}
                        error = {validateTest.name ? validateTest.name.error ? true : false :''}
                        helperText={validateTest.name.error ? validateTest.name.error ? validateTest.name.error : '':''}
                    />
                    <TextField
                        className="mb-4"
                        type="date"
                        label='Data de Nascimento'
                        required={true}
                        value={dados.birthday}
                        name='birthday'
                        variant="outlined"
                        // error = {erros ? erros.birthday ? true : false :''} 
                        error = {validateTest.birthday ? validateTest.birthday.error ? true : false :''}
                        helperText={validateTest.birthday.error ? validateTest.birthday.error ? validateTest.birthday.error : '':''}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps = {{maxLength:150}}
                        onChange={(e)=>{onChangeInput(e); onchangeValidateTeste(e)}}
                        onBlur = {onchangeValidateTeste}
                    />
                 
                    <div className={classes.generoDiv}>
                    <FormControl component="fieldset" className={classes.generoContainer} error>
                      <label className={classes.labelGenero}><span className={erros ? erros.sex ? classes.sexError : '' :''}>Sexo*:</span></label>
                      
                      <RadioGroup aria-label="gender" name="gender1" className={classes.genero} name="sex" onChange={onChangeInput} >
                        <FormControlLabel value="H" control={<Radio color="primary" />} label="Masculino" checked={dados.sex === "H" ? true : false} />
                        <FormControlLabel value="M" control={<Radio color="primary" />} label="Feminino" checked={dados.sex === "M" ? true : false}/>
                        {/* <FormControlLabel value="O" control={<Radio color="primary" />} label="Outro" checked={dados.sex === "O" ? true : false}/> */}
                      </RadioGroup>
                     
                    </FormControl>
                    <small className={classes.smallError}> {validateTest.sex ? validateTest.sex.error ? validateTest.sex.erro : '':''}</small>
                    </div>

                    <TextField
                        className={classes.doc}
                        label='Email'
                        type="email"
                    
                        variant="outlined"
                        name="email"
                        value={dados.email}
                        onChange={(e)=>{onChangeInput(e); onchangeValidateTeste(e)}}
                        onBlur = {onchangeValidateTeste}
                        // error = {validateTest.email ? validateTest.email.error ? true : false :''}
                        // helperText={validateTest.email.error ? validateTest.email.error ? validateTest.email.error : '':''}
                    />

                    <div className={classes.doc}>
                      <TextField
                           className={classes.cpf}
                           label='CPF'
                           type="text"
                           required={true}
                           variant="outlined"
                           inputProps = {{maxLength:14}}
                           name='cpf'
                           value={cpfDoc}
                           onChange={(e)=>{onChangeInput(e); onchangeValidateTeste(e)}}
                           onBlur = {onchangeValidateTeste}
                           error = {validateTest.cpf ? validateTest.cpf.error ? true : false :''}
                           helperText={validateTest.cpf.error ? validateTest.cpf.error ? validateTest.cpf.error : '':''}
                      />
                      <TextField
                        
                        label='RG'
                        type="text"
                        // required={true}
                        variant="outlined"
                        name='rg'
                        value={docRg}
                        inputProps = {{maxLength:13}}
                        onChange={(e)=>{onChangeInput(e); onchangeValidateTeste(e)}}
                        onBlur = {onchangeValidateTeste}
                        // error = {validateTest.rg ? validateTest.rg.error ? true : false :''}
                        //   helperText={validateTest.rg.error ? validateTest.rg.error ? validateTest.rg.error : '':''}
                        
                      />
                    </div>

                    <TextField
                        className={classes.mb4}
                        label='Nome da mãe'
                        type="text"
                        // required={true}
                        variant="outlined"
                        name="mothers_name"
                        value={dados.mothers_name}
                        onChange={(e)=>{onChangeInput(e); onchangeValidateTeste(e)}}
                        onBlur = {onchangeValidateTeste}
                        // error = {validateTest.mothers_name ? validateTest.mothers_name.error ? true : false :''}
                        // helperText={validateTest.mothers_name.error ? validateTest.mothers_name.error ? validateTest.mothers_name.error : '':''}
                    />
                    <TextField
                        className={classes.mb4}
                        label='Nome do pai'
                        type="text"
                        // required={true}
                        variant="outlined"
                        name="fathers_name"
                        value={dados.fathers_name}
                        onChange={(e)=>{onChangeInput(e); onchangeValidateTeste(e)}}
                        onBlur = {onchangeValidateTeste}
                        // error = {validateTest.fathers_name ? validateTest.fathers_name.error ? true : false :''}
                        // helperText={validateTest.fathers_name.error ? validateTest.fathers_name.error ? validateTest.fathers_name.error : '':''}
                    />
                   
                    {/* <div className={classes.textbox}>
                    <TextField 
                            className="mb-4"
                            label="Observações"
                            type="text"
                            required={true}
                            variant="outlined"
                            multiline
                            rows={5}
                            inputProps={{ maxLength: 800 }}
                            name="notification"
                            onChange={onChangeInput}
                    />
                    <span className={classes.small}>*Esta informação será vísivel somente para vocês</span>
                    </div> */}
                    <TextField
                        className={classes.mb4}
                        label='Como conheceu?'
                        type="text"
                        variant="outlined"
                        inputProps = {{maxLength:150}}
                        name="how_met"
                        value={dados.how_met}
                        onChange={onChangeInput}
                        // error = {validatesx[7].error ? validatesx[7].error ? true : false :''}
                        // helperText={validatesx[7].error ? validatesx[7].error ? validatesx[7].error : '':''}
                    />

                    <h3 className={classes.titulo}>TELEFONES</h3>

                    <div className={classes.doc}>
                                      
                     <TextField
                        className={classes.mb4, classes.tel}
                        label='Celular'
                        type="text"
                        variant="outlined"
                        InputProps={{
                          inputComponent: FormataCelular,
                        }}
                        name="cellphone"
                        required = {true}
                        onChange={(e)=>{onChangeInput(e); onchangeValidateTeste(e)}}
                        onBlur = {onchangeValidateTeste}
                        value={cell}
                        error = {validateTest.cellphone ? validateTest.cellphone.error ? true : false :''}
                        helperText={validateTest.cellphone.error ? validateTest.cellphone.error ? validateTest.cellphone.error : '':''}
                    />
                     <TextField
                      className={classes.mb4, classes.tel}
                      label='Fixo'
                      type="text"
                      // required = {true}
                      variant="outlined"
                      InputProps={{
                        inputComponent: FormataTelefone,
                      }}
                      name="phone"
                      onChange={(e)=>{onChangeInput(e); onchangeValidateTeste(e)}}
                      onBlur = {onchangeValidateTeste}
                      value={tel}
                      // error = {validateTest.phone ? validateTest.phone.error ? true : false :''}
                      // helperText={validateTest.phone.error ? validateTest.phone.error ? validateTest.phone.error : '':''}
                    />
                    </div>
                  
                  <h3 className={classes.titulo}>LEMBRETE DE AGENDAMENTO</h3>
                  
                  <FormControlLabel
                    control={<IOSSwitch  onChange={onChangeInput} name="is_whatsapp" checked={dados.is_whatsapp ? true : false}/>}
                    label="Paciente aceita receber lembrete de agendamento por SMS"
                  />
                  {dados.is_whatsapp ? 
                  <span className={classes.sms}> Seus pacientes podem responder o SMS</span>
                  : ''}    
                  <h3 className={classes.titulo}>ENDEREÇO</h3>

                  <TextField
                    className={classes.mb4}
                    label='CEP'
                    type="tel"
                    variant="outlined"
                    name="zip_code"
                    value={maskCep(dados.zip_code)}
                    onChange={e =>  {
                      setDados({
                        ...dados,
                        zip_code: unMaskZipCode(e.target.value)}
                      )
                      searchPostalCode(e.target.value)
                    }}
                    onBlur = {onchangeValidateTeste}
                    // error = {validateTest.zip_code ? validateTest.zip_code.error ? true : false :''}
                    // helperText={validateTest.zip_code.error ? validateTest.zip_code.error ? validateTest.zip_code.error : '':''}
                  />
                  <div  className={classes.doc}>
                  <TextField
                        className={classes.mb4, classes.end}
                        label='Endereço'
                        type="text"
                        variant="outlined"
                        inputProps = {{maxLength:150}}
                        name = "street"
                        value={dados.street}
                        onChange={(e)=>{onChangeInput(e); onchangeValidateTeste(e)}}
                        onBlur = {onchangeValidateTeste}
                        // error = {validateTest.street ? validateTest.street.error ? true : false :''}
                        // helperText={validateTest.street.error ? validateTest.street.error ? validateTest.street.error : '':''}
                        
                  />
                   <TextField
                        className={classes.mb4, classes.num}
                        label='Número'
                        type="text"
                        variant="outlined"
                        inputProps = {{maxLength:150}}
                        name="number"
                        value={dados.number}
                        onChange={(e)=>{onChangeInput(e); onchangeValidateTeste(e)}}
                        onBlur = {onchangeValidateTeste}
                        // error = {validateTest.number ? validateTest.number.error ? true : false :''}
                        // helperText={validateTest.number.error ? validateTest.number.error ? validateTest.number.error : '':''}
                        
                  />
                  </div>
                  <div  className={classes.doc}>
                  <TextField
                        className={classes.mb4, classes.comp}
                        label='Complemento'
                        type="text"
                        variant="outlined"
                        value={dados.complement}
                        inputProps = {{maxLength:150}}
                        name="complement"
                        onChange={(e)=>{onChangeInput(e); onchangeValidateTeste(e)}}
                        onBlur = {onchangeValidateTeste}
                        // error = {validateTest.complement ? validateTest.complement.error ? true : false :''}
                        // helperText={validateTest.complement.error ? validateTest.complement.error ? validateTest.complement.error : '':''}
                        // value={dados.complement}
                        
                  />
                   <TextField
                        className={classes.mb4, classes.bairro}
                        label='Bairro'
                        type="text"
                        variant="outlined"
                        inputProps = {{maxLength:150}}
                        name="neighborhood"
                        value={dados.neighborhood}
                        onChange={(e)=>{onChangeInput(e); onchangeValidateTeste(e)}}
                        onBlur = {onchangeValidateTeste}
                        // error = {validateTest.neighborhood ? validateTest.neighborhood.error ? true : false :''}
                        // helperText={validateTest.neighborhood.error ? validateTest.neighborhood.error ? validateTest.neighborhood.error : '':''}
                        
                  />
                  </div>
                  <div  className={classes.doc}>
                  <TextField
                        className={classes.mb4, classes.comp}
                        label='Cidade'
                        type="text"
                        variant="outlined"
                        inputProps = {{maxLength:150}}
                        name="city"
                        value={dados.city}
                        onChange={(e)=>{onChangeInput(e); onchangeValidateTeste(e)}}
                        onBlur = {onchangeValidateTeste}
                        // error = {validateTest.city ? validateTest.city.error ? true : false :''}
                        // helperText={validateTest.city.error ? validateTest.city.error ? validateTest.city.error : '':''}
                       
                  />
                   
                  <FormControl  variant="outlined" className={classes.mb4, classes.bairro}>
                    <InputLabel id="demo-simple-select-outlined-label">Estado</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      label='Estado'
                      name="state"
                      onChange={(e)=>{onChangeInput(e); onchangeValidateTeste(e)}}
                      onBlur = {onchangeValidateTeste}
                      value = {dados.state}
                      error = {validateTest.state ? validateTest.state.error ? true : false :''}
                     
                    >
                    {uf ? 
                        uf.map((estado, index) => {
                          return(
                          <MenuItem key={index} value={estado.sigla}>{estado.sigla}</MenuItem>
                          
                          )
                        })
                        :
                          <MenuItem> Carregando </MenuItem>                     
                   
                    }
                      
                      
                    </Select>
                    <small className={classes.smallError}> {validateTest.state ? validateTest.state.error ? validateTest.state.error : '':''}</small>
                  </FormControl>
                  </div>

                  <FormControlLabel
                    control={<IOSSwitch  onChange={onChangeInput} name="notification" value={dados.notification} checked={dados.notification ? true : false} />}
                    label="Eu aceito receber as notificações da Clinic Plus"
                    
                  />
               
               {isAlert ? 
                <div className={classes.alerts}>   
                { isError ? 
                <AlertSave variant="filled" severity="error">
                  {errorPatient ? errorPatient : 
                    'Confira os dados!'
                  }
                </AlertSave>
                :
                <AlertSave variant="filled" severity="success">
                  Salvo com sucesso!
                </AlertSave>     
                } 
                
                </div>   
                
                : "" 
                } 
                </>
                } 

                </div>
                {/* <div>
                    <Avatar/>
                </div> */}
            </div>
            
              
          {!isLoading ? 
           !isEditPatient ?  
            <div className={classes.buttons}>
            
            <Button onClick={salvarAdd}>
              SALVAR E ADICIONAR OUTRO
            </Button>
            <Button onClick={salvarEdit}>
              SALVAR E CONTINUAR EDITANDO
            </Button>
            <Button variant="contained" color='primary' className="color-primary" onClick={salvar}>
              SALVAR
            </Button>
            </div>
            :
            <div>
            {ViewPatient === false?
            <div className={classes.buttons}> 
              <Button onClick={()=>{history.push('/listagem-pacientes')}}>
                CANCELAR
              </Button>
              <Button variant="contained" className="color-primary" color='primary' onClick={editar}>
                SALVAR
              </Button>
              
            </div>
            :''}
            </div>
            :''}
            
        </ThemeProvider>
         
        
    </div>
    )

} 