import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CircularLoad from '../../../../Components/Load';
import DialogContentText from '@material-ui/core/DialogContentText';


export default function Modal(props) {

    const [load, setLoad] = useState(props.load)
    const [open, setOpen] = useState(props.open)
    const [typeAlert, setTypeAlert] = useState(props.typeAlert);
    const [message, setMessage] = useState(props.message);





    useEffect(() => {

        setOpen(props.open)
    setLoad(props.load)
    setTypeAlert(props.typeAlert);
    setMessage(props.message);

    }, [props])


    function handleExclusao(value) {
        return value

    }

    function salvar(){
          props.encerra()
    }

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.titulo}</DialogTitle>
                <DialogContent>
                {message ?
                <div className="Modal-agendamento-alert">
                  <Alert variant="filled" severity={typeAlert}>
                    {message}
                  </Alert>
                </div>
                : ''}
                    <DialogContentText id="alert-dialog-description">
                        {props.desc}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        props.close()
                        setOpen(false)

                    }} color="default">
                        Cancelar
                    </Button>
                    <Button 
                        onClick={() => {
                            props.titulo === 'Clinic Plus - Atendimentos'
                                ? salvar()
                                : props.delete()
                        }} 
                        variant="contained" 
                        color="primary" 
                        className={!load ? "color-primary" : 'color-disable'}
                        autoFocus
                        disabled={load}
                    >
                        {!load ? 'Concluir' : <CircularLoad />}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}