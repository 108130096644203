import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import CircularLoad from '../../Components/Load';
import conexao from '../../Services/api';
import Logo from '../../Assets/Images/LogoCP.svg';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import './css/cadastroProfissional.css';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { campovazio } from '../../Components/ReduxTeste/ValidateForm'
import { telefones, validateEmail } from '../../Components/ReduxTeste/Validate'
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginBottom: '1rem',
      width: '100%',
    },
    width: '80%',
    minHeight: '100%'
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  checkedAceito: {
    marginTop: '.5rem'
  }
}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
}, ptBR);


const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#27b8d2',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#27b8d2',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 5,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#27b8d2',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#27b8d2',
    zIndex: 1,
    fontSize: 22,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};


const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};



function getSteps() {
  return ['Dados pessoais', 'Dados profissionais', 'Fim do cadastro'];
}

export default function CadastroProfissional() {
  const classes = useStyles();
  const History = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [profissao, setProfissao] = useState([])
  const [especialidade, setEspecialidade] = useState([]);
  const [idProfissao, setIdProfissao] = useState();
  const [idEspecialidade, setIdEspecialidade] = useState();
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState();
  const [profissaoSelecionada, setProfissaoSelecionada] = useState();
  const [especialidadeSelecionadaIndex, setEspecialidadeSelecionadaIndex] = useState();
  const [profissaoSelecionadaIndex, setProfissaoSelecionadaIndex] = useState();
  const [load, setLoad] = useState(false);
  const [openalert, setOpenalert] = useState({ open: false })


  function Alertshow(msg, type) {
    setOpenalert({ open: true, msg: msg, type: type })
    setTimeout(() => {
      setOpenalert({ open: false })
    }, 2000);
  }


  const initialvalidate = {
    nome: { name: 'nome', funcao: campovazio, error: false, value: '' },
    email: { name: 'email', funcao: validateEmail, error: false, value: '' },
    telefone: { name: 'telefone', funcao: campovazio, error: false, value: '' },
    profissao: { name: 'profissao', funcao: campovazio, error: false, value: '' },
    especialidade: { name: 'especialidade', funcao: campovazio, error: false, value: '' },
    senha: { name: 'senha', funcao: campovazio, error: false, value: '' },
  }

  const [dados, setDados] = useState({
    "name": "",
    "email": "",
    "cpf": "",
    "rg": "",
    "phone": "",
    "cellphone": "",
    "is_whatsapp": true,
    "birthday": "",
    "street": "",
    "neighborhood": "",
    "number": '',
    "city": "",
    "state": "",
    "complement": "",
    "zip_code": "",
    "avatar": "",
    "speciality_id": 1
  })

  const [validatex, setValidatex] = useState(initialvalidate)

  function onchangeValidateTeste(e) {
    const { name, value } = e.target
    const obj = validatex[name]
    const er = validatex[name].funcao(value)
    let att = { ...obj, error: er, value: value }
    setValidatex({ ...validatex, [name]: att })
    
  }

  const handleNext = () => {

    if (activeStep === 0) {
      if (validatex.nome.value === '') {
        validatex.nome.error = true
        Alertshow('dados inválidos!', 'error')
      }
      if (validatex.email.value === '') {
        validatex.email.error = true
        Alertshow('dados inválidos!', 'error')
      }
      if (validatex.telefone.value === '') {
        validatex.telefone.error = true
        Alertshow('dados inválidos!', 'error')
      }
      setValidatex({ ...validatex })

      if (validatex.nome.error === false && validatex.email.error === false && validatex.telefone.error === false) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }

    if (activeStep === 1) {
   
      if (validatex.profissao.value === '') {
        validatex.profissao.error = true
        Alertshow('dados inválidos!', 'error')
      }
      if (validatex.especialidade.value === '') {
        validatex.especialidade.error = true
        Alertshow('dados inválidos!', 'error')
      }
      setValidatex({ ...validatex })
      if (validatex.profissao.error === false && validatex.especialidade.error === false) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }


    if (activeStep === 2) {
      if (validatex.senha.value === '' || validatex.senha.value.length < 6) {
        validatex.senha.error = true
        Alertshow('dados inválidos!', 'error')
      }
      if (state.checkedA === false) {
        Alertshow('Aceite os termos de uso para prosseguir', 'warning')

      }
      setValidatex({ ...validatex })

      if (validatex.senha.error === false && state.checkedA === true) {
        localStorage.removeItem('token')
        localStorage.removeItem('userId')
        localStorage.removeItem('doctorId')
        
        let noMask = validatex.telefone.value.replace(/\D/g, '')

        setLoad(true)
        const obj = {
          name: validatex.nome.value,
          phone: noMask,
          email: validatex.email.value,
          speciality_id: validatex.especialidade.value,
          password: validatex.senha.value,
        }
        conexao.post('/doctor/create', obj).then((res) => {
          console.log('RESPONSE DOCTOR', res)
          setLoad(false)
          localStorage.setItem('token', res.data.data.login.token)
          localStorage.setItem('userId', res.data.data.login.id)
          localStorage.setItem('doctorId', res.data.data.login.doctor_id)

          const obj =
            [{ route: '/agenda', autorization: 'false' },
            { route: '/pacientes', autorization: 'false' },
            { route: '/financeiro', autorization: 'false' },
            { route: '/novo-modelo', autorization: 'false' },
            { route: '/atendente', autorization: 'false' },
            { route: '/planos', autorization: 'false' },
            { route: '/perfil', autorization: 'false' },
            { route: '/painel', autorization: 'false' },
            { route: '/relatorios', autorization: 'false' }]


          var count = 0
          if (res.data.data.permission) {
            res.data.data.permission.map((item) => {
              obj.map((objitem) => {
                if (objitem.route === item) {
                  objitem.autorization = 'true'
                  count = count + 1
                }
              })
            })
          }



          obj.map((item) => {
            localStorage.setItem(item.route, item.autorization)
          })


          if (!res.data.data.permission) {
            obj.map((item) => {
              localStorage.setItem(item.route, 'true')
            })
          }




          //





          Alertshow('Cadastro realizado com sucesso!', 'success')

          setTimeout(() => {

              window.location.href  =  '/home'

          }, 1500);
        }).catch((error) => {
          setLoad(false)
          Alertshow('Esse e-mail já está sendo utilizado!', 'error')
        })
      }
    }
  }



  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  async function getProfissaoCadastro() {
    await conexao.get(`/speciality/category`)
      .then((resp) => {
        setProfissao(resp.data.data)
        resp.data.data.map((i, index) => {
          if (i.name === profissaoSelecionada) {
            setProfissaoSelecionadaIndex(index);

          }
        })
    })

    
    conexao.get(`/speciality/${idProfissao}`)
      .then((resp) => {
        setEspecialidade(resp.data.data)
        resp.data.data.map((i, index) => {
          if (i.name == especialidadeSelecionada) {
            setEspecialidadeSelecionadaIndex(index);

          }
        })
      }).catch((error) => {
      })

  }


  async function getEspecializacaoCadastro(idProfissao) {
    await conexao.get(`/speciality/${idProfissao}`)
      .then((resp) => {
        setEspecialidade(resp.data.data)
      }).catch((error) => {

      })

  }


  function handleInputChange(event, value) {
    for (let i = 0; i < profissao.length; i++) {
      if (profissao[i].name == value) {
        setIdProfissao(profissao[i].id);
        setProfissaoSelecionadaIndex(i)
        onchangeValidateTeste({ target: { name: 'profissao', value: profissao[i].id } })
        getEspecializacaoCadastro(profissao[i].id);
      }
    }
  }


  function handleInputChangeEspecialidade(event, value) {
    for (let i = 0; i < especialidade.length; i++) {
      if (especialidade[i].name == value) {
        setIdEspecialidade(especialidade[i].id);
        setEspecialidadeSelecionadaIndex(i)
        onchangeValidateTeste({ target: { name: 'especialidade', value: especialidade[i].id } })
        setDados({
          ...dados,
          ['speciality_id']: especialidade[i].id
        })
      }
    }
  }


  useEffect(() => {
    getProfissaoCadastro();
  }, [profissaoSelecionada])




  return (
    <div className="container-cadastro">
      <ThemeProvider theme={theme}>
       
        <div className="envolve-form-cadastro">
          <form className={classes.root} autoComplete="off">
          <div className='envolve-logo'>  
            <img className="img-logo-cadastro" src={Logo} alt="Logo Clinic" />
          </div>
            <h1 className="h1-cadastro">Cadastro Profissional</h1>
            {openalert.open ?
              <div className="Modal-agendamento-alert">
                <Alert variant="filled" severity={openalert.type}>
                  {openalert.msg}
                </Alert>
              </div>
              : ''}
            <div >
              <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {activeStep === 0 ?
              <div className="envolve-textField">
                <TextField
                  onChange={(e) => { onchangeValidateTeste(e) }}
                  error={validatex.nome.error}
                  value={validatex.nome.value}
                  helperText={validatex.nome.error ? 'Este Campo precisa ser preenchido' : ''}
                  type="text" name="nome" label="Nome completo" variant="outlined" color="primary" required />

                <TextField
                  onChange={(e) => { onchangeValidateTeste(e) }}
                  error={validatex.email.error}
                  value={validatex.email.value}
                  helperText={validatex.email.error ? 'Email inválido' : ''}
                  type="email" name="email" label="E-mail" variant="outlined" color="primary" required />

                <TextField
                  onChange={(e) => { onchangeValidateTeste(e) }}
                  error={validatex.telefone.error}
                  value={telefones(validatex.telefone.value)}
                  helperText={validatex.telefone.error ? 'Este Campo precisa ser preenchido' : ''}
                  type="tel" name="telefone" label="Telefone" variant="outlined" color="primary" required />
              </div>
              :
              activeStep === 1 ?
                <div className="envolve-textField">
                  {profissaoSelecionadaIndex !== '' ?
                    <div >
                      <Autocomplete
                        id="profissao"
                        options={profissao}
                        getOptionLabel={(option) => option.name}
                        defaultValue={profissao[profissaoSelecionadaIndex]}
                        onInputChange={handleInputChange}
                        renderInput={(params) => <TextField {...params} error={validatex.profissao.error ? true : false} helperText={validatex.profissao.error ? 'Campo obrigatório' : ''} label="Profissão" variant="outlined" />}

                      />
                    </div>
                    : ''}
                  {especialidadeSelecionadaIndex !== '' ?
                    <Autocomplete
                      id="especialidade"
                      options={especialidade}
                      getOptionLabel={(option) => option.name}
                      defaultValue={especialidade[especialidadeSelecionadaIndex]}
                      onInputChange={handleInputChangeEspecialidade}
                      renderInput={(params) => <TextField {...params} error={validatex.especialidade.error ? true : false}
                        helperText={validatex.especialidade.error ? 'Campo obrigatório' : ''} label="Especialidade" variant="outlined" />}
                    />
                    : ''}
                </div>
                :
                activeStep === 2 ?



                  <div className="envolve-textField">

                    <FormControl variant="outlined">
                      <InputLabel error={validatex.senha.error ? true : false} htmlFor="senha">Senha</InputLabel>
                      <OutlinedInput
                        id="senha"
                        name="senha"
                        onChange={(e) => { onchangeValidateTeste(e) }}
                        error={validatex.senha.error ? true : false}
                        type={values.showPassword ? 'text' : 'password'}
                        value={validatex.senha.value}
                        //onChange={handleChange('password')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        labelWidth={50}
                      />
                    </FormControl>
                    <FormHelperText style={{ color: 'red' }} id="component-error-text">{validatex.senha.error ? 'Este Campo precisa ser preenchido' : ''}</FormHelperText>

                    <FormControlLabel
                      className={classes.checkedAceito}
                      control={<Checkbox checked={state.checkedA} color="primary" onChange={handleChangeCheck} name="checkedA" />}
                      label={
                        <React.Fragment>
                          <p className="termosPlan">Eu aceito os <a href="#" target="_blank">Termos de uso</a> e <a href="#" target="_blank"> Política de Privacidade</a></p>
                        </React.Fragment>
                      }
                    />
                  </div>
                  : ''}
            <div className="div-btn-cadastro">
              <div>
                <div>
                  <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                    Voltar
                  </Button>
                  {!load ?
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={clsx(classes.button, "color-primary")}
                    >
                      {activeStep === 2 ? 'Cadastrar' : 'Próximo'}
                      {activeStep !== 2 ? <ArrowForwardIcon className="margin-left-icon-cadastro" /> : ''}
                    </Button>
                    :
                    <Button
                      variant="contained"
                      color="primary"
                      className={clsx(classes.button, "color-primary")}
                    >
                      <CircularLoad />
                    </Button>
                  }
                </div>
              </div>
            </div>
          </form>
        </div>
      </ThemeProvider>
    </div>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
];