import React, {useState, useEffect} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'

export default function InputCheckbox({ data ,id , title, answers, required}) {
    const [state, setState] = React.useState({});
    const [options, setOptions] = React.useState([]);
    const [value, setValue] = React.useState('1');
    const [error, setError] = useState(false)
    const [arrValidate, setArrValidate] = useState([])
    const { Answer , setAnswer, respView, respEdit, validation, setValidation,
        attValidation, setAttValidation, attEncerraError, validaProntuario, setvalidaProntuario } = StateGlobal();

    const handleChange = (event, itemValue) => {
        console.log(itemValue)
        setState({...state, [event.target.name]: event.target.checked  })

        

        // console.log(arrValidate)
        // console.log('new Arr:', newArr)
        // options.map((item, index) => {
        //     if (item.id === id) {
        //       item.checked = !item.checked
        //     }
        // })

        

        let data = []
        options.map((item, index)=>{
            if(event.target.name == item.value){

                data = options
                data[index].checked = event.target.checked 
                                     
            }
        })
        

        Answer.map((item, index) => {
            if (item.question_id === id) {
               options.map((opcao)=>{
                if(opcao.checked){
                    if(opcao.value === item.id_check){

                        Answer[index].answer = opcao.value
                        
                    }
                }else{
                    if(opcao.value === item.id_check){

                        Answer[index].answer = ''
                        
                    }
                }
                  
               })        

              
            }
       
        })

        setOptions(data)
    };


    
    useEffect(() => {
        
         
            let valor = []

                    
            data.opcoes.map((item)=>{
                
                let dat 
                dat = {...item, checked: false}
        
                if(data.answers){
                    if(data.answers[0]){
                        data.answers.map((ans)=>{
                          
                            if(ans.form_step_question_option_id == item.value){
                                
                                dat = {...item, checked: true}
                            
                            }
                            Answer.map((answer, index)=>{
                                
                                if(answer.id_check === ans.form_step_question_option_id){
                                 
                                    Answer[index].answer = ans.form_step_question_option_id
                                }
                            })
                        })
                        
                    }
                }
                valor.push(dat) 
            

            })

           
            setOptions(valor)
            
         
            
    }, [id])

    useEffect(()=>{
     
        let valor = []
        
            data.opcoes.map((item)=>{

                let dat 

                dat = {...item, checked: false}
       
                          
                Answer.forEach((ans)=>{
                  
                    if(ans.question_type == 'check'){
                    
                       if(ans.answer === item.value){
                            dat = {...item, checked: true}
                       }
                    }
                })

                valor.push(dat)

            })
            
            
           setOptions(valor)          
            
        
    },[])

    

    useEffect(()=>{
        validacaoOnchange()
    },[arrValidate])
    
    console.log('arrValidadte', arrValidate)

    function validacaoOnchange() {
        if (!required) {
          return  
        }
      
        Answer.map((item) => {
          if (item.question_id === id) {  
            validaProntuario[id].error = !arrValidate.length && validaProntuario[id].started
            validaProntuario[id].value = arrValidate ? 'ok' : ''
            validaProntuario[id].started = true
      
            setvalidaProntuario({...validaProntuario})
          }
        })
    }
    
    return (
        <div className="div-checkbox-margin">
            <p className="titulo-form">{title}</p>
            <FormControl component="fieldset">
                <FormGroup aria-label="position">
                    {options.map((item, index)=>{
                        
                        return(
                         <FormControlLabel
                            value={item.value}
                            name={item.value}
                            onChange={(e)=>{
                                handleChange(e, item.value)
                                setArrValidate(options.filter(e => e.checked !== false ))
                            }}
                            required={required}
                            control ={<Checkbox color="primary" 
                                checked={options[index].checked}
                            />}
                            label={item.text}
                            labelPlacement={item.text}
                            disabled={respEdit}
                        />
                        )
                    })}
                                       
                </FormGroup>
            </FormControl>

            <div style={{ color: 'red', width: '100%', fontSize: '15px' }}>
                {!respView && validaProntuario[id].error === true && !respView ? validaProntuario[id].message : ''}
            </div>
        </div>
  );
}
