import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {useHistory} from 'react-router-dom' 


const useStyles = makeStyles((theme) => ({
    divTitleModal: {
        display: 'flex',
        marginTop: '5rem',
        alignItems: 'center',
        marginLeft: '.5rem',
        marginBottom: '1rem',
      },
      titleModal:{
        fontSize: '1.4em',
        margin: '0',
        marginTop: '.5rem',
        marginBottom: '.5rem',
        color: '#4a4a4a',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
          marginRight: '.5rem',
        },
      },
  }));

  export default function Voltar(props){
    const classes = useStyles();
    let history = useHistory()

    function voltar(){
        history.goBack()
    }

    return(
        <div className={classes.divTitleModal}>
            <h1 className={classes.titleModal}><ArrowBackIcon onClick={()=>{voltar()}}/>{props.Titulo}</h1>
        </div>
    )

  }