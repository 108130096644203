import React, { useState, useEffect } from 'react';

import './css/header.css';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import LocalPrintshopSharpIcon from '@material-ui/icons/LocalPrintshopSharp';
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ModalAddAgendamento from '../../Modais/ModalAgendamento';
import conexao from '../../../../Services/api'
import Alert from '@material-ui/lab/Alert';
import CircularLoad from '../../../../Components/Load';
//import ModalGerarGuia from '../../Modais/ModalGerarguia';
import ModalResultadoPesquisa from '../../Modais/ModalResultado';
import ModalConfiguracaoAgenda from '../../Modais/ModalConfiguracaoAgenda';


const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    border: 'none'
  },
  search: {
    background: '#FFFFFF',
    boxShadow: '0px 6px 10px rgba(25, 32, 56, 0.08)',
    borderRadius: '3px',
    height: '60px',
    fontSize: '15px',
    border: 'none',
    padding: '15px',
    width: '100%'

  },
  iconSearch: {
    color: '#8F8F8F'
  },
  ass:{
    width: '100%'
  }
}));

export default function HeaderTabela() {

  const classes = useStyles();
  const [modalAddAgendamento, setModalAddAgendamento] = useState(false);

  const [modalResultado, setModalResultado] = useState(false);
  const [modalConfiguracao, setModalConfiguracao] = useState(false);
  const [resultadoPesqAgen, setResultadoPesqAgen] = useState('');
  const [resultadoPesqRea, setResultadoPesqRea] = useState('');
  const [resultadoPesqCan, setResultadoPesqCan] = useState('');
  const [nameDoctor, setNameDoctor] = useState('');
  const [namePaciente, setNamePaciente] = useState('');
  const History = useHistory();
  const [proc, setProc] = useState();
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [load, setLoad] = useState(false);
  const [MessageError, setMessageError] = useState('')



  useEffect(() => {

    // function listaProcedimentos2() {
    var userId = localStorage.getItem('userId');
    conexao.get(`/schedule/type/${userId}`).then((res) => {
      setProc(res.data)
    }).catch((error) => {
  
    })

    // }

  }, [])

  const openModalAddAgendamento = () => {
    setModalAddAgendamento(true)
  }

  async function listaDadosMedico() {
    var userId = '';
    if (localStorage.getItem('userId')) {
      userId = localStorage.getItem('userId');
      await conexao.get(`doctor`)
        .then((response) => {
          var medicos = response.data.data;
          for (var i = 0; i < medicos.length; i++) {

            if (medicos[i].user_id == userId) {
              setNameDoctor(medicos[i].user.name)
            }
          }
        })
    } else {
      History.push('/');
    }
  }

  useEffect(() => {
    listaDadosMedico();
  }, [])



  const openModalConfiguracao = () => {
    setModalConfiguracao(true)
  }

  const busca = (event) => {
 
    if (event.key === 'Enter') {
      setLoad(true)
      buscarConsAge();
      buscarConsRea();
      buscarConsCan();
    } if (event._reactName === 'onClick') {
      setLoad(true)
      buscarConsAge();
      buscarConsRea();
      buscarConsCan();
    }
    return true
  }

  async function buscarConsAge() {
    var userId = '';

    if (localStorage.getItem('userId')) {
      userId = localStorage.getItem('userId');
      await conexao.post('/schedule/patient',
        {
          "user_id": userId,
          "patient": namePaciente,
          "status": "AGENDADO"
        })
        .then((response) => {
          setResultadoPesqAgen(response.data)
          setMessage('')
          setTypeAlert('success')
          if (resultadoPesqAgen.data !== '' || resultadoPesqRea.data !== '' || resultadoPesqCan.data !== '') {
            setModalResultado(true)
            setLoad(false)
          }
        }).catch((error) => {
          setLoad(false)
          if (resultadoPesqAgen.data !== '' && resultadoPesqRea.data !== '' && resultadoPesqCan.data !== '') {
            setMessage('Paciente não encontrado, verifique o nome e tente novamente.')
            setTypeAlert('error')
            setTimeout(() => {
              setMessage('')
              setTypeAlert('')
            }, 1500);
          }
        })
    } else {
      History.push('/');
    }
  }
  async function buscarConsRea() {

    var userId = '';
    if (localStorage.getItem('userId')) {
      userId = localStorage.getItem('userId');
      await conexao.post('/schedule/patient',
        {
          "user_id": userId,
          "patient": namePaciente,
          "status": "REALIZADO"
        })
        .then((response) => {
          setResultadoPesqRea(response.data)
          setMessage('')
          setTypeAlert('success')
          if (resultadoPesqAgen.data !== '' || resultadoPesqRea.data !== '' || resultadoPesqCan.data !== '') {
            setModalResultado(true)
            setLoad(false)
          }

        }).catch((error) => {
          setLoad(false)
          if (resultadoPesqAgen.data !== '' && resultadoPesqRea.data !== '' && resultadoPesqCan.data !== '') {
            setMessage('Paciente não encontrado, verifique o nome e tente novamente.')
            setTypeAlert('error')
            setTimeout(() => {
              setMessage('')
              setTypeAlert('')
            }, 1500);
          }
        })
    } else {
      History.push('/');
    }
  }
  async function buscarConsCan() {

    var userId = '';
    if (localStorage.getItem('userId')) {
      userId = localStorage.getItem('userId');
      await conexao.post('/schedule/patient',
        {
          "user_id": userId,
          "patient": namePaciente,
          "status": "CANCELADO"
        })
        .then((response) => {
          setResultadoPesqCan(response.data)
          setMessage('')
          setTypeAlert('success')
          if (resultadoPesqAgen.data !== '' || resultadoPesqRea.data !== '' || resultadoPesqCan.data !== '') {
            setModalResultado(true)
            setLoad(false)
          }

        }).catch((error) => {
          setLoad(false)
          if (resultadoPesqAgen.data !== '' && resultadoPesqRea.data !== '' && resultadoPesqCan.data !== '') {
            setMessage('Paciente não encontrado, verifique o nome e tente novamente.')
            setTypeAlert('error')
            setTimeout(() => {
              setMessage('')
              setTypeAlert('')
            }, 1500);
          }
        })
    } else {
      History.push('/');
    }
  }

  const alteraEstadoModal = (modalAddAgendamento) => {
    setModalAddAgendamento(modalAddAgendamento);

  };

  const alteraEstadoModalResultado = (modalResultado) => {
    setModalResultado(modalResultado);
  };

  const alteraEstadoModalConfiguracao = (modalConfiguracao) => {
    setModalConfiguracao(modalConfiguracao);
  };

  async function getPdf() {
    setLoad(true)
    let userId =  localStorage.getItem('userId');
    // let Doctor_ID = localStorage.getItem('doctorId')
     try {
        const data = await conexao.get(`/schedule/save/${userId}`)  
        console.log("data aqui",data.data)
        setTimeout(() => {
          window.location.href = `${process.env.REACT_APP_STORAGE_URL}/storage/schedule/${userId}.pdf`
          setLoad(false)
        }, 2000);
        
        
       }catch (error) {
        setMessageError("Sem agendamentos para o dia de hoje!")
          setTimeout(() => {
            setMessageError("")
          }, 5000);
            setLoad(false)
       }
    }
    console.log("ModalAddAgendamento",modalAddAgendamento)

  return (
    <div className="container">
      {message ?
        <div className="Modal-agendamento-alert">
          <Alert variant="filled" severity={typeAlert}>
            {message}
          </Alert>
        </div>
        : ''}
      <div className="inputs-headers">
        <div className={clsx(classes.ass)}>
          <h1 className="header-name">{nameDoctor}</h1>

          <form className={classes.root} noValidate autoComplete="off">

            <FormControl className={clsx(classes.form)}>
              <InputBase
                id="outlined-adornment-weight"
                endAdornment={<InputAdornment position="end">{!load ? <IconButton><SearchIcon className={classes.iconSearch} onClick={(event) => busca(event)} /></IconButton> : <div className="load-detalhe-agendamento"><CircularLoad /></div>}</InputAdornment>}
                labelWidth={0}
                disabled={load ? true : false}
                onKeyDown={busca}
                onChange={(e) => setNamePaciente(e.target.value)}
                placeholder="Busque por um paciente"
                className={clsx(classes.search)}
              />

            </FormControl>

          </form>
        </div>


        <div className="button-group-table">
          <div className="div-group-btn-alert">
            <div className="div-group-btn">

                <div>
                  <button onClick={openModalAddAgendamento} className="buttonHeader"><ControlPointIcon /></button>
                </div>
                <div>
                  <button 
                  onClick={() => {getPdf()}} 
                  className="buttonHeader">{load === true ? 
                  <div style={{width: "100%",display: "flex",justifyContent: 'center'}}> 
                    <div className="load-detalhe-agendamento2">
                      <CircularLoad/>
                    </div> 
                  </div> 
                    :
                    <>
                      <LocalPrintshopSharpIcon />
                      <span> Imprimir agenda </span>
                    </>}
                  </button>
                </div>
                <div>
                <button onClick={openModalConfiguracao} className="buttonHeader mr-0"><SettingsIcon /></button>
                </div>
                
            </div>
          
            <div className="div-group-alert">

                <p>{MessageError}</p>

            </div>

          </div>
        </div>
      </div>
      <ModalAddAgendamento edit={false} open={modalAddAgendamento} onChange={alteraEstadoModal} />

      <ModalResultadoPesquisa
        namePac={namePaciente}
        resultadosPesquisasCan={resultadoPesqCan}
        resultadosPesquisasAgen={resultadoPesqAgen}
        resultadosPesquisasRea={resultadoPesqRea}
        open={modalResultado}
        onChange={alteraEstadoModalResultado} />
      <ModalConfiguracaoAgenda open={modalConfiguracao} onChange={alteraEstadoModalConfiguracao} />
    </div>
  );

}