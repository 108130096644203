import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { IconButton } from '@material-ui/core';
import { cpf } from '../../../Components/ReduxTeste/Validate';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom:'10px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color:'#8f8f8f'
  },
  AccordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    color:'#8f8f8f'
  },

  buttons: {
    margin: '5px'

  },
  hr:{
   border: '1px solid #d3d3d3',
    width: '100%' 
  },
  divbuttons:{
   display:'flex',
   justifyContent:'flex-end' 
   },
   AccordionSummary:{
     display:'flex',
     flexDirection:'column'

   }
}));

export default function SimpleAccordion(props) {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
         
        >
          <div  className={classes.AccordionSummary}>
          <Typography className={classes.heading}>Nome: {props.arr.name}</Typography>
          <Typography className={classes.heading}>CPF: {props.arr.cpf ? cpf(props.arr.cpf) : 'Não cadastrado'} </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails}>
         
          <hr className={classes.hr} />
          <div className={classes.divbuttons} >
            <IconButton className={classes.buttons} onClick={()=>{
              props.prontuario(props.arr.id, props.arr.name)
            }}>
              <DescriptionIcon />
            </IconButton>

            <IconButton className={classes.buttons} onClick={()=>{
              props.editar(props.arr.id, props.arr.name)
            }}>
              <EditIcon />
            </IconButton>

            <IconButton onClick={()=>{
              props.deletar(props.arr.id, props.arr.name)
            }}>
             <DeleteIcon />
            </IconButton>

          
         
          </div>
        </AccordionDetails>
      </Accordion>
     

    </div>
  );
}
