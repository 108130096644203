import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import CircularLoad from '../../../../Components/Load';
import conexao from '../../../../Services/api';
import Checkbox from '@material-ui/core/Checkbox';
import '../css/ListagemP.css';
import { Button } from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { StateGlobal } from '../../../../ContextAPI/context'



const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    border: 'none',
    marginBottom: '2rem'
  },
  search: {
    background: '#f1f1f1',
    borderRadius: '3px',
    height: '45px',
    fontSize: '15px',
    border: 'none',
    padding: '15px',
    minWidth: '100%',

  },
  iconSearch: {
    color: '#8F8F8F'
  },
  divScroll: {
    height: '44vh',
 
  }
}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    }
  },
}, ptBR);


export default function CardModelosProntos() {
  const classes = useStyles();
  const History = useHistory();
  const [modelosCriados, setModelosCriados] = useState('');
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [load, setLoad] = useState(false);
  const [nameModelo, setNameModelo] = useState('');


  const { pos, setPos, MontagemProntuario, setMontagemProntuario, setAnswer, respView, Answer, respEdit, validaProntuario, setvalidaProntuario, NameProntuario, setNameProntuario, setIdProntuario, setFormPatientID } = StateGlobal();


  async function listaModeloCriado() {
    setLoad(true)
    var userId = '';
    if (localStorage.getItem('userId')) {
      userId = localStorage.getItem('userId');
      const data = { search: nameModelo }
      await conexao.post('/form/list/' + localStorage.getItem('userId'), data).then((response) => {

        setLoad(false)
        setModelosCriados(response.data.data)
        if (response.data.data.length < 1) {
          setModelosCriados('')
          setTypeAlert('error');
          setMessage('Prontuário não encontrado, verifique o nome do modelo buscado.');
          setTimeout(() => {
            setTypeAlert('');
            setMessage('');
          }, 3000);
        }
      }).catch((error) => {
        setLoad(false)
        setModelosCriados('')
        setTypeAlert('error');
        setMessage('Você ainda não possui um modelo próprio. Clique em novo modelo para criar seu próprio modelo de prontuário!');
        setTimeout(() => {
          setTypeAlert('');
          setMessage('');
        }, 5000);
      })
    } else {
      History.push('/');
    }
  }


  useEffect(() => {
      listaModeloCriado()
  },[]);

  useEffect(() => {
    if(nameModelo===''){
      setModelosCriados([])
      listaModeloCriado()
    }

  },[nameModelo]);

  const busca = (event) => {
    if (event.key === 'Enter') {
      setLoad(true)
      listaModeloCriado();
      setModelosCriados([])
    } if (event._reactName === 'onClick') {
      setLoad(true)
      setModelosCriados([])
      listaModeloCriado();
    }
    return true
  }
  
  function enviar(e) {  // INICIAR ATENDIMENTO GERA ARRAY SEM RESPOSTA
    conexao.get('/form/' + e.id).then((res) => {
 
      setIdProntuario(e.id)
      setFormPatientID(res.data.data.id)
      
      const id = Math.floor(Math.random() * 256)

      const arredit = []

      localStorage.getItem('userId')
      localStorage.getItem('patientID')

      const iniciaAtendimento = {
        form_id: res.data.data.id, 
        user_id: localStorage.getItem('userId'), 
        patient_id: localStorage.getItem('patientID')
      }

      res.data.data.steps.map((item, index) => {

        const idsessao = Math.floor(Math.random() * 256)



        const components = []
        item.questions.map((item) => {


          // const types = ['textocurto', 'textolongo', 'data', 'numerico', 'telefone', 'email','label' ]//teste
          const types = ['textocurto', 'textolongo', 'data', 'numerico', 'telefone', 'email', 'label', 'image']//teste
          //componentes basicos de texto
          types.map((itemtypes) => {
            if (item.type === itemtypes) {
              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao })
            }
          })

          //tipo de componente especial diferente com selects
          const typesepeciais = ['escaladedor', 'musculoesqueletico', 'visceral', 'cranianovistainferior', 'cranianovistaanterior', 'cranianovistaposterior', 'cranianovistalateraldireita']//teste
          typesepeciais.map((itemtypes) => {
            //console.log('especiasselects', item)
            if (item.type === itemtypes) {//pego os options do selelect aqui thiagao manda pra gente
              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, options: item.options })
            }
          })


          //ODONTOLOGICO
          const typesodontologico = ['odontologico']
          typesodontologico.map((itemtypes) => {
            if (item.type === itemtypes) {//pego os options do selelect aqui thiagao manda pra gente
              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, options: item.options })
            }
          })

          //DESENVOLVIMENTO MOTOR
          const typesdesenvolvimentomotor = ['desenvolvimentomotor']
          typesdesenvolvimentomotor.map((itemtypes) => {
            if (item.type === itemtypes) {
              //console.log('desenvolvimentomotor', item)
              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, options: item.options })
            }
          })



          const typesosteopatia = ['osteopatiapediatrica']
          typesosteopatia.map((itemtypes) => {
            if (item.type === itemtypes) {
              //  console.log('osteopatiapediatrica', item)
              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, options: item.options })
            }
          })

          const typescurvadecrescimento = ['curvadecrescimento']
          typescurvadecrescimento.map((itemtypes) => {
            if (item.type === itemtypes) {
              //  console.log('osteopatiapediatrica', item)
              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, options: item.options })
            }
          })



          //componente selecao
          const typesselecao = ['selecao']
          typesselecao.map((itemtypes)=>{
            if (item.type === 'selecao') {
              const arrop = []
              if (item.options) {
                item.options.map((item) => {
                  arrop.push({ text: item.text, value: item.id })
                })
              }
              components.push({ type: "selecao", component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, opcoes: arrop })
  
            }
          })
          
          typesselecao.map((itemtypes)=>{
            if (item.type === 'check') {
              const arrop = []
              if (item.options) {
                item.options.map((item) => {
                  arrop.push({ text: item.text, value: item.id })
                })
              }
              components.push({ type: "check", component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, opcoes: arrop })
  
            }
          })
         

        })

        //gera sessoes
        arredit.push({ componentsessao: item.title, id: idsessao, montagem: components, titulosessao: item.description })

      })
      conexao.post('/form/patient/create', iniciaAtendimento).then((res) => {

        localStorage.setItem('idSend', res.data.data.id)
      }).catch((error) => {

      })

      criaAnswer(arredit)
      createValidate(arredit)


      setMontagemProntuario(arredit)
      setNameProntuario(res.data.data.title);


     setPos(2)


    }).catch((error) => {
    })

  }

  

  function createValidate(e) {//CRIAR ARRAY PARAR VALIDACAO DOS COMPONENTES DINAMICOS
    if (respView) {
      return
    }
    
    var valida = {}

    e.map((item) => {
      item.montagem.map((obj, index) => {
        valida = { 
          ...valida,
          ...{ [obj.tempId]: {
            question_id: obj.tempId,
            value: '',
            required: obj.required === '1' ? true : false,
            sessao: obj.idsessao,
            error: false,
            message: '* Reposta Obrigatória',
            type: obj.type,
            started: false
          }}
        }
      })
    })


    setvalidaProntuario(valida)
  }


  function criaAnswer(arredit) {//MUITO IMPORTANTE PEGA VALORES DO BACK E CONVERTE PARA PRENCHER OS COMPONENETES DINAMICOS E ARMAZENAR SUAS UPDADTE NO ARRAY ANSWER DO CONTEXT API

    //MEUS MODELOS CRIAÇÃO DE RESPOSTAS
    const answers = []
    arredit.map((item) => {


      item.montagem.map((create) => {

        if (create.type === 'check') {
           
          create.opcoes.map((opcao)=>{
            answers.push({
              question_id: create.tempId,
              question_type: create.type,
              id_check: opcao.value,
              answer: create.answer !== undefined ? create.answer : ''
            })
          })
          
        }


        if (create.type === 'osteopatiapediatrica') {

          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : [],
            form_step_question_option_id: ''

          })

        }

        if (create.type === 'curvadecrescimento') {

          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : [],
            form_step_question_option_id: ''

          })

        }


        if (create.type === 'desenvolvimentomotor') {

          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : [],
            form_step_question_option_id: ''

          })

        }


        if (create.type === 'odontologico') {

          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : [],
            form_step_question_option_id: ''

          })

        }

        if (create.type === 'cranianovistainferior' || create.type === 'musculoesqueletico' || create.type === 'visceral' || create.type === 'cranianovistaanterior' || create.type === 'cranianovistaposterior' || create.type === 'cranianovistalateraldireita') {
          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : [],
            form_step_question_option_id: ''

          })
        }
        if (create.type === 'textocurto' || create.type === 'textolongo' || create.type === 'data' || create.type === 'numerico' || create.type === 'telefone' || create.type === 'email' || create.type === 'label'  || create.type === 'selecao' || create.type === 'image') {
          
          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : ''
          })

        }


      })
    })

    const arr = answers.slice()
   
    setAnswer(arr)
  }


  return (
    <div className="">

        <FormControl className={clsx(classes.form)}>
          <InputBase
            id="outlined-adornment-weight"
            endAdornment={<InputAdornment position="end">{<IconButton><SearchIcon className={classes.iconSearch} onClick={(event) => busca(event)} /></IconButton>}</InputAdornment>}
            labelWidth={0}
            disabled={load ? true : false}
            onKeyDown={busca}
            onChange={(e) => setNameModelo(e.target.value)}
            placeholder="Busque por um modelo"
            className={clsx(classes.search)}
          />
        </FormControl>

      <div className={classes.divScroll}>
        {load ?
          <div className="div-load-lista-modelos"><CircularLoad /></div>
          : ''}
        {modelosCriados ?
          modelosCriados.map((modeloCriado) => (
            <div className="CardModelos-envolve">

              <div className="CardModelos-envolve-text-icon">

                {/* <AssignmentIcon className="CardModelos-icon-pront"/> */}
                <p>{modeloCriado.title}</p>
              </div>
              <div>
                <Button className={clsx(classes.btnInicarAtendimento,"color-primary")} variant="contained" color="primary" onClick={() => { enviar(modeloCriado) }} >
                  <PlayArrowIcon /> Iniciar
            </Button>
              </div>
            </div>
          )) :
          <div>
            {message ?
              <div className="Modal-agendamento-alert">
                <Alert variant="filled" severity={typeAlert}>
                  {message}
                </Alert>
              </div>
              : ''}
          </div>
        }
      </div>
    </div>
  );
}