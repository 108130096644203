import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'

export default function InputNumerico({id ,title , required, edit}) {
    const [numerico, setNumerico] = useState('');


    const { Answer ,setAnswer ,respEdit , setMontagemProntuario ,MontagemProntuario , setNameProntuario, NameProntuario, PosProntuario ,setpos , respView} = StateGlobal();

    const handleChangeInput = event => {
        const { value } = event.target;
        Answer.map((item, index) => {
            if (item.question_id === id) {
                setNumerico(value.replace(/([^\d])+/gim, ''))
                Answer[index].answer = value.replace(/([^\d])+/gim, '')

            }
        })
        const array  = MontagemProntuario
  
        array[PosProntuario].montagem.map((item , index)=>{
            if(item.tempId === id){

                item.answer = value
            }

        })

        setMontagemProntuario(array)
        


        
    };

    useEffect(()=>{
      setNumerico(edit)
      },[id])

    // useEffect(() => {
    //     Answer.map((item, index) => {
    //         if (item.question_id === id) {
    //             setNumerico(item.answer)
    //         }
    //     })
    // }, [id])



    // useEffect(()=>{

    //     Answer.map((item,index)=>{
    //       if(item.question_id === id){  
    //         if(item.answer ===  ''){                 
    //        setNumerico(edit)
    //         }else{
    //           setNumerico(item.answer)

    //         }
    //       }
    //     })      

    //   },[edit])
    
  return (
        <div>
            <p className="titulo-form">{title}</p>
            <TextField 
                className="mb-4"
                value={numerico}
                onChange={handleChangeInput}
                type="tel"
                disabled={respView}
                required={required}
                variant="outlined"
            />
        </div>
  );
}
