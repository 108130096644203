import axios from 'axios';
 
const api = axios.create({

    baseURL:process.env.REACT_APP_NEW_API_URL,
    headers: {
        'Authorization': localStorage.getItem('token')  , //TESTE PARA O ERRO 500 DA API
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
});

// const api = axios.create({

//     baseURL: 'http://127.0.0.1:8000/api',

//     headers: {
//         'Authorization': localStorage.getItem('token'),
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }

// });

export default api ;
