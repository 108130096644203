import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { StateGlobal } from '../../../ContextAPI/context'
import DadosPessoais from '../Components/DadosPessoais'
import InputTextoCurto from './InputsBasicos/InputTextoCurto';
import InputTextoLongo from './InputsBasicos/InputTextoLongo'
import InputTelefone from './InputsBasicos/InputTelefone'
import InputNumerico from './InputsBasicos/InputNumerico'
import InputEmail from './InputsBasicos/InputEmail'
import InputSelect from './InputsBasicos/InputSelect'
import InputData from './InputsBasicos/InputData'
import InputCheckbox from './InputsBasicos/InputCheckbox'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InputLabel from './InputsBasicos/InputLabel'
import InputImagem from './InputsEspeciais/InputImagem';
import InputEscalaDor from './InputsEspeciais/InputEscalaDor';
import InputEscala from './InputsEspeciais/InputEscala';
import InputTabela from './InputsEspeciais/InputTabela';
import InputOdontologico from './InputsEspeciais/InputOdontologico'
import InputDesenvolvimentoMotor from './InputsEspeciais/InputDesenvolvimentoMotor'
import InputOsteopatia from './InputsEspeciais/InputOsteopatia'
import InputCurvaDeCrescimento from './InputsEspeciais/InputCurvaDeCrescimento'
import Alert from '@material-ui/lab/Alert';
import conexao from '../../../Services/api'
import {TextField} from '@material-ui/core';
import CircularLoad from '../../../Components/Load';
import clsx from 'clsx';
import annexIcon from '../../../Assets/Images/annexIcon.svg';




const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  colorPaperWhite: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 0px 0px gray",
    padding: '2rem'
  },
  envolveResumo: {
    marginTop: '1rem',
    height: '87vh',
    padding: '1rem',
  },
  envolveDadosPaciente: {
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  divEnvolveFotoDados: {
    display: 'flex'
  },
  divEnvolveTextos: {
    '& p': {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: '1rem',
      color: '#8a8a8a'
    }
  },
  avatar: {
    width: '60px',
    height: '60px',
    fontSize: '2rem'
  },
  tituloName: {
    fontSize: '1.3em',
    color: '#27b8d2 !important',
    marginBottom: '.3rem !important',
    fontWeight: '600'
  },
  tituloEtapa: {
    fontSize: '1.3em',
    color: '#757575 !important',
    marginBottom: '.6rem !important',
    fontWeight: '600',
    marginTop: '2rem'
  },
  filtroData: {
    display: 'flex',
  },
  filtroResulo: {
    backgroundColor: 'white',
    marginTop: '2rem',
    padding: '2rem',
    height: '48vh',
    overflow: 'auto'
  },
  pSelecionarData: {
    marginRight: '1rem',
    color: '#757575',
    fontWeight: '500'
  },
  textField: {
    marginLeft: '2rem',
    marginRight: '2rem'
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  table: {
    boxShadow: '0px 0px 0px 0px grey',
    marginTop: '2rem',
  },
  subTituloEtapa: {
    fontSize: '1em',
    color: '#757575 !important',
    marginBottom: '0rem !important',
    fontWeight: '500',
    marginTop: '0rem',

  },
  divEnvolveTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTop: '1px solid #f3f3f3',
    backgroundColor: 'white',
    padding: '1rem',
    paddingTop: 0
  },

  divButtonSalvar: {
    marginBottom: '3rem',
    '& button': {
      marginRight: '1rem'
    },
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '2rem',
    marginRight:'0rem',
    with: '100%'
  },
  containerProntuario: {
    minHeight: '65vh',
    background: '#F8F8F8',
    borderRadius: '4px',
    paddingBottom: '5rem'
  },
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: '0.5rem',
    paddingTop: '0.5rem',
    width:'100%',
  },
  baixar:{
    display:'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems:'center',
  }, 
  observationCard: {
    padding: '1.5rem',
    marginTop: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0px 6px 10px 0px #0000000F'

  },
  observationData: {
    fontWeight: 500,
    fontSize: '1.3em',
    color: '#27B8D2',
    margin: '0 0 20px 0'
  },
  observationText: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#000000',
    textAlign: 'justify'
  },
  observationAnnex: {
    fontWeight: 500,
    fontSize: '1.3em',
    color: '#27B8D2',
    marginBottom: '0'
  },
  observationFile: {
    fontWeight: 500,
    fontSize: '1.3em',
    color: '#E7366A',
    textDecoration: 'underline',
    cursor: 'pointer',
    wordBreak: 'break-word',

    '& * + span': {
      marginLeft: '10px'
    },
  },
  divEnvolveForm:{
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: '1.5rem',
    boxShadow: '0px 6px 10px 0px #0000000f',
    marginTop: '50px',

    '& p': {
      margin: '0 0 10px 0',
      fontWeight: 500,
      fontSize: '22px',
      color: '#27B8D2'
    },

    '& > *': {
      marginBottom: theme.spacing(3),
      width: '100%',
    },

    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F8F8F8',
      boxShadow: '0px 6px 10px 0px #0000000f',
    }
  },
  annexDiv: {
    '& input#file-input + label': {
      padding: '6px 16px',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: '#27B8D2',
      color: '#FFFFFF',
      fontSize: '0.875rem',
      fontWeight: '500',
      lineHeight: '1.75',
      display: 'block',
      textAlign: 'center',
      marginTop: '15px',
      marginBottom: '15px',
      boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',

      '&:hover': {
        cursor: 'pointer'
      }
    },

    '& p':{
      padding: '9px 30px 9px 30px',
      margin: '0',
      marginRight: '13px',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: '#F8F8F8',
      color: '#CCCCCC',
      fontSize: '19px',
      boxShadow: '0px 6px 10px 0px #0000000f',
      width: '100%',
      wordBreak: 'break-word',
    },
  },
  btnVizualizar: {
    width: '100%'
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#27b8d2',
      contrastText: '#fff',
    }
  },
}, ptBR);

export default function ResumoPaciente() {
  const classes = useStyles();

  const [observationTextFieldValue, setObservationTextFieldValue] = useState('')
  const [observations, setObservations] = useState([])
  const [validaMensagem, setValidaMensagem] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [ load , setLoad] = useState(false)
  const [error, setError] = useState()
  // const [ linkBlob , ]


  const { 
    MontagemProntuario,  
    PosProntuario, 
    setPosProntuario, 
    Answer, 
    setAnswer, 
    respEdit,  
    validaProntuario,  
    NameProntuario,  
    idProntuario, 
    formPatientID, 
    observationStep ,
    pos,
    respView 
  } = StateGlobal();
   
  const answers = []

  // useEffect(() => {
  //   MontagemProntuario.map((item) => {
  //     if (item.montagem) {        
  //       item.montagem.map((create) => {
  //         if(respEdit){            
  //           answers.push({ question_id: create.tempId, question_type: create.type, answer:create.answer })
  //         }else{
  //           answers.push({ question_id: create.tempId, question_type: create.type, answer: [] })
  //         }
  //       })
  //     }
  //   })

  //   setAnswer(answers)
  // }, [])

  useEffect(() => {
    getUserObservations()
  }, [])

  useEffect(()=>{
    let arrayValidaProntuario = Object.values(validaProntuario)

    let hasValidationError = arrayValidaProntuario.some((item) => item.error === true)

    setError(hasValidationError)
  },[validaProntuario])

  async function getUserObservations() {
    console.log("REQUISIÇÂO DE PRONTUARIO" + localStorage.getItem('idSend'))
    const res = await conexao.get(`/historic/getall/${localStorage.getItem('idSend')}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
    })
    
    setObservations(res.data.data)
  }

  async function handleObservation() {
    if ( observationTextFieldValue === '' ) {
      setValidaMensagem(true)
      return
    } 
    setValidaMensagem(false)
    
    setIsLoading(true)
    await conexao.post(`historic/create`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }, 
      form_id: localStorage.getItem('idSend'),
      observation: observationTextFieldValue
    })
    .then(async res => {
      if(uploadedFileName) {
        uploadedFileName.forEach(async (file, idx) => {
          
          console.log("fileee maldito",file)

          let formData = new FormData();
          formData.append(`file`, file);

          const fileUrl = await conexao.post('/upload', formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
  
          await conexao.post(`file/create`, {
            historic_id: res.data.data.id,
            url: fileUrl.data,
            file_name: file.name 
          })

          getUserObservations()
        })
      }
      
      getUserObservations()
      setObservationTextFieldValue('')
      setUploadedFileName('')
    })
    setIsLoading(false)
  }

  function handleAnnex(event) {
    let files = Object.values(event.target.files)

    let fileNameField = document.getElementById('file-name');
    fileNameField.textContent = files.map((file) => file.name);

    setUploadedFileName(files)
  }

  async function donwloadFile(fileUrl, fileName) {

console.log(fileUrl, fileName)

    conexao({
      url: `/download/path`,
      method: 'POST',
      data: {
        caminho: fileUrl,
        name: fileName
      },
      responseType: 'blob'
    })
    .then((response) => {
      console.log("response",response)

      let url = window.URL.createObjectURL(new Blob([response.data]));
      
      const toDataURL = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            callback(reader.result);
          }
          reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      }
      
    toDataURL(url, function(dataUrl) {
      console.log('RESULT:', dataUrl)
      console.log("file", fileName.replaceAll(" ", ""))
      conexao.post(`upload/hot`, {name: fileName.replaceAll(" ", ""), type: response.data.type, blob: dataUrl }).then((response)=>{
        console.log("response",response)
        setTimeout(() => {
          window.location.href = `${process.env.REACT_APP_STORAGE_URL}/storage/${response.data.data}`
           
          // const url = window.URL.createObjectURL(new Blob([response.data]));
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', `${fileName}`);
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);



        }, 2000)
        // console.log("resposta do blob",`${process.env.REACT_APP_STORAGE_URL}/storage/${response.data.data}`)
      })})
        // console.log("url 11",url)
        // let formData = new FormData();
        // formData.append(`file`, url);
  // console.log("formData?",formData)
        // const data = conexao.post(`/upload/hot`, formData, {
        //   headers: {
        //      "Content-Type": "multipart/form-data",
        //      "Accept": "application/json",
        //      "type": "formData"
        //   },
        // })

        // console.log("data master",data)
      
      // console.log("url blob",url)
      // const link = document.createElement('a');
      // link.href = url;
      // console.log("url 22",url)
      // link.setAttribute('download', `${fileName}`);
      // document.body.appendChild(link);

      // link.click();
      // document.body.removeChild(link);
    })
  }

  const idForm = localStorage.getItem("idSend")
  async function print(){

    setLoad(true)

    try{  
      const response = await conexao.get(`/form/patient/save/${idForm}`)
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_STORAGE_URL}/storage/prontuario/${idForm}.pdf`
        
      }, 2000);
      setLoad(false)
    }catch(error){
      console.log(error)
      setLoad(false)
    }
  }

  function alterarData(data) {
    const initialDate = data.split(' ')
    const time = initialDate[1].split('.')[0].split(':')
    const date = initialDate[0].split('-').reverse()
    const formatedDate = `${date[0]}/${date[1]}/${date[2]}`
    const formatedTime = `${time[0]}:${time[1]}`

    return `${formatedDate} ${formatedTime}`
  }

  return (
    <div className={classes.envolveResumo}>
      <ThemeProvider theme={theme}>
        <div>
          <DadosPessoais />
        </div>
        <>
          {error && !respView  ? 
            <Alert variant="filled" severity="error">
            Há campos inválidos no prontuario
            </Alert> 
          : ""}
        </>
        <div className={classes.containerProntuario}>
        <div className={classes.divEnvolveTitle}>
            <div>
              <h3 className={classes.tituloEtapa}>{NameProntuario? NameProntuario:''}</h3>
          
            </div>
            <div className={classes.baixar}>
              <h5 
                className={classes.subTituloEtapa}
              >
                Seção: {
                  observationStep ?  'Observações' 
                  : MontagemProntuario[0] ? MontagemProntuario[PosProntuario].titulosessao 
                  : ''
                }
              </h5>
              <Button color="primary" onClick={()=>{print()}} className={classes.btnBaixar}>
              {load === true ?  <div className="div-load-lista-modelos"> <CircularLoad/> </div> : <> <GetAppIcon /> Baixar PDF </> }
              </Button>
            </div>
          </div>
          {observationStep ? (
            <>
              {isLoading ? (
                <div className="div-load-lista-modelos" style={{ height: '65vh' }}> <CircularLoad /> </div>
              ) : (
                <>
                  <div>
                    {observations.map((item) => (
                      <div key={item.id} className={classes.observationCard}>
                        <p className={classes.observationData}>{alterarData(item.created_at)}</p>
                        <p className={classes.observationText}>
                          {item.observation}
                        </p>
                        {item.files.length !== 0 && <p className={classes.observationAnnex}>Anexos:</p>}
                        <span
                          className={classes.observationFile}
                        >
                          {item.files.map((file) => (
                           
                               <span 
                              onClick={() => donwloadFile(file.url, file.file_name)}
                              style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '0', marginRight: '11px' }}
                            >
                              {file.file_name && 
                                <img style={{ width: '16px', marginRight: '3px' }} src={annexIcon} alt="annex icon" />
                              }
                              {file.file_name}
                            </span>
                            
                           
                          ))}
                        </span>
                      </div>
                    ))}
                  </div>

                  <div className={classes.divEnvolveForm}>
                    <p style={{ fontSize: '1.3em'}}>Observação:</p>

                    <TextField
                      id="outlined-multiline-static"
                      label="Digite aqui"
                      multiline
                      rows={5}
                      required
                      variant="outlined"
                      value={observationTextFieldValue}
                      onChange={(e) => setObservationTextFieldValue(e.target.value)}
                      error={validaMensagem ? true:false}
                      helperText={validaMensagem ? 'Campo obrigatório' : ''}
                      autoFocus={false}
                    />

                    <p style={{ fontSize: '1.3em'}}>Anexar documentos</p>

                    <div className={classes.annexDiv}>
                      <div>
                        <p id="file-name">Selecionar arquivo</p>

                        <input type="file" name='files[]' id="file-input" multiple onChange={handleAnnex} />
                        <label for="file-input">ANEXAR</label>
                      </div>
                    
                      <Button 
                        variant="contained" 
                        className={clsx('color-primary', classes.btnVizualizar)}  
                        color="primary" 
                        onClick={handleObservation}
                      >
                        SALVAR
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <form onSubmit={(event)=>{ event.preventDefault()}} className={classes.root} autoComplete="off">
              <div className="local-inputs">
              {/* <InputImagem/>
              <InputEscala/>
              <InputTabela/> */}
              <div>
              { 
                MontagemProntuario ? MontagemProntuario[PosProntuario].montagem.map((item, index) => {
                  if (item.type === 'textocurto') {
                    return (
                      <div className={classes.root}>
                        {/* importante para nao bugar os imputs de cadastro de respota item.answer === undefined*/}
                        <InputTextoCurto title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} />
                      </div>
                    )
                  }
                  if (item.type === 'textolongo') {
                    return (
                      <div className={classes.root}>

                        <InputTextoLongo title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} />
                      </div>
                    )
                  }
                  if (item.type === 'telefone') {
                    return (
                      <div className={classes.root}>
                        <InputTelefone title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} />
                      </div>
                    )
                  }
                  if (item.type === 'data') {
                    return (
                      <div className={classes.root}>
                        <InputData title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} />
                      </div>
                    )
                  }
                  if (item.type === 'selecao') {
                    return (
                      <div className={classes.root}>
                        <InputSelect title={item.title} id={item.tempId} data={item} required={item.required === "0" ? false : true} />
                      </div>
                    )
                  }
                  if (item.type === 'email') {
                    return (
                      <div className={classes.root}>
                        <InputEmail title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} />
                      </div>
                    )
                  }
                  if (item.type === 'numerico') {
                    return (
                      <div className={classes.root}>
                        <InputNumerico title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} />
                      </div>
                    )
                  }
                  if (item.type === 'check') {
                    return (
                      <div className={classes.root}>
                        <InputCheckbox title={item.title} id={item.tempId} data={item} required={item.required === "0" ? false : true} />
                      </div>
                    )
                  }
                  if (item.type === 'escala') {
                    return (
                      <div className={classes.root}>
                        <InputEscala title={item.title} id={item.tempId} required={item.required === "0" ? false : true} />
                      </div>
                    )
                  }
                  if (item.type === 'image') {
                
                    return (
                      <div className={classes.root}>
                        <InputImagem title={item.title} id={item.tempId} required={item.required === "0" ? false : true} answer={item.answer} imageID={`imgProntuario-${index}`}/>
                      </div>
                    )
                  }
                  if (item.type === 'tabela') {
                    return (
                      <div className={classes.root}>
                        <InputTabela title={item.title} id={item.tempId} required={item.required === "0" ? false : true} />
                      </div>
                    )
                  }
                  if (item.type === 'escaladedor' || item.type === 'cranianovistainferior' || item.type === 'musculoesqueletico' || item.type === 'visceral' || item.type === 'cranianovistaanterior' || item.type === 'cranianovistaposterior' || item.type === 'cranianovistalateraldireita') {
                
                    return (

                      // ***********************************************USAR EDIT FAZER ONCHAGE EXTERNO**********************************************************
                      <div className={classes.root}>
                        <InputEscalaDor title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} options={item.options} />
                      </div>
                    )
                  }
                  if (item.type === 'label') {
                    return (
                      <div className={classes.root}>
                        <InputLabel title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} />
                      </div>
                    )
                  }

                  if (item.type === 'odontologico') {
                    return (
                      <div className={classes.root}>
                        <InputOdontologico title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} options={item.options} />
                      </div>
                    )
                  }

                  if (item.type === 'desenvolvimentomotor') {
                    return (
                      <div className={classes.root}>
                        <InputDesenvolvimentoMotor title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} options={item.options} />
                      </div>
                    )
                  }

                  if (item.type === 'osteopatiapediatrica') {
              
                    return (
                      <div className={classes.root}>
                        <InputOsteopatia title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} options={item.options} />
                      </div>
                    )
                  }

                  if (item.type === 'curvadecrescimento') {
                  
                    return (
                      <div className={classes.root}>
                        <InputCurvaDeCrescimento title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} options={item.options} />
                      </div>
                    )
                  }

                })
                
                : ''
              }

            </div>
            </div>
            <div className={classes.divButtonSalvar}>
                {PosProntuario>=1?
                <Button onClick={() => {

                  if (PosProntuario >= 1) {
                    setPosProntuario(PosProntuario - 1)
                  }

                }} type="submit">
                  <ArrowBackIcon /> Anterior
              </Button>
              :''}
                {MontagemProntuario.length>1 && MontagemProntuario.length != PosProntuario+1?
                <Button onClick={() => {
                  if (PosProntuario < MontagemProntuario.length - 1) {
                    setPosProntuario(PosProntuario + 1)
                  }

                }} type="submit" variant="contained" className="color-primary" color="primary">
                  Próximo <ArrowForwardIcon />
                </Button>
                :''}
              </div>
            </form>
          )}
        </div>
      </ThemeProvider>
    </div>
  );
}