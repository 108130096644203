import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StateGlobal } from '../../../../ContextAPI/context'
import clsx from 'clsx';
import { FiAlertCircle } from 'react-icons/fi'
import Badge from '@material-ui/core/Badge';


const useStyles = makeStyles((theme) => ({
  divSecao: {
    margin: 0,
    borderRadius: '4px',
    cursor: 'pointer',
  },
  active: {
    backgroundColor: 'white'
  },
  titleSecao: {
    color: '#27b8d2',
    fontSize: '1.1em',
    padding: '.7rem'
  },
  divsessao: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'


  }
}));


export default function SecoesLateralEsquerda({ data }) {
  const classes = useStyles();

  const { validaProntuario, respView  } = StateGlobal();

  return (
    <div className={clsx(classes.divSecao, classes.active)}>


      <div className={classes.divsessao}>
        <div>
          <h2 className={classes.titleSecao}>{data.titulosessao}</h2>
        </div>


        {!respView && Object.values(validaProntuario).find(item => item.sessao === data.id && item.error === true)? 

        <Badge
          color="secondary"
           badgeContent={
             Object.values(validaProntuario).filter(item => item.sessao === data.id && item.error === true).length > 0 
            ? Object.values(validaProntuario).filter(item => item.sessao === data.id && item.error === true).length 
            : ''
          }
        >
          {
            Object.values(validaProntuario).find(item => item.sessao === data.id && item.error === true) 
            ?
            <FiAlertCircle style={{ fontSize: '30px', color: 'red' }} /> 
           : ""
          }
        </Badge>
        :''}


      </div>
    </div>
  );
}