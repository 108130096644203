import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Select from '@material-ui/core/Select';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import ReactWhatsapp from 'react-whatsapp';
import conexao from '../../../Services/api'
import '../css/Agenda.css'
import ModalGerarGuia from './ModalGerarguia'
import Alert from '@material-ui/lab/Alert';
import ModalLancarPagamento from './ModalLancarPagamento'
import CircularLoad from '../../../Components/Load'
import ModalAgendamento from './ModalAgendamento'
import { StateGlobal } from '../../../ContextAPI/context'
import { celular, currency } from './Validate'
import ModalExcluir from './ModalConfirmExcluir'
import { useHistory }  from "react-router-dom"
import { browserName, CustomView } from "react-device-detect"


const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '4rem',
    height: '4rem',
    marginRight: '0rem',
  },
  namePaciente: {
    margin: 0,
    color: '#27b8d2',
    fontSize: '1.5em',
    marginTop: '.5rem',
    textTransform:'capitalize'
  },
  telefonePaciente: {
    margin: '0rem',
    marginTop: '.25rem',
    color: '#8F8F8F',
    marginBottom: '1em',
  },
  divDadosPaciente: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1rem',
    borderBottom: '1px solid #e4e4e4',
    paddingBottom: '1rem',
  },
  divDadosNome:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnReactWhats: {
    backgroundColor: '#f9f9f9',
    border: 0
  },
  btnWhats: {
    backgroundColor: '#f9f9f9',
    color: '#4bc557',
    border: '1px solid #4bc557c7',
    fontSize: '1em',
    width: '100%',
  },
  btnWhatsApp: {
    backgroundColor: '#f9f9f9',
    border: 'solid 1px #6dc376',
    borderRadius: '5px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#6dc376',
    fontWeight: 500,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    fontSize: '14px',
    cursor:'pointer',

    "&:hover": {
      backgroundColor: '#f3f3f3 !important;'
    }

  },
  btnsFooter: {
    color: '#8F8F8F',
  },
  btnFooter: {
    color: '#8F8F8F',
    cursor: 'pointer'
  },
  iconBtn: {
    marginRight: '.25rem'
  },
  dadosConsultaH2: {
    color: '#27b8d2',
    fontSize: '.9em',
    textTransform: 'uppercase'
  },
  dadosConsulta: {

    alignItems: 'center',
    justifyContent: 'space-between'
  },
  selectAgendamento: {
    width: '100%',
    backgroundColor: 'white',
    marginBottom: '1rem'
  },
  tabelaAgendamento: {
    boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%)',
    color: '#8F8F8F',
    backgroundColor: '#f9f9f9',
  },
  ptabelaAgendamento: {
    color: '#8F8F8F',
    marginBottom: '1rem'
  },
  divTable: {
    marginBottom: '1rem'
  },
  modalColor: {
    backgroundColor: '#f9f9f9',
  },
  paddingButton:{
    justifyContent: 'space-evenly'
  },
  iniciarAtendimento:{
    width: '90%',
    margin: '1rem',
  },
  containerTabelaAgendamento:{
    minHeight: '10rem'
  },  
  dialogPadding:{
    padding: '9px 9px'
  }
}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
}, ptBR);

function createData(procedimento, quant, valor) {
  return { procedimento, quant, valor };
}

const rows = [
  createData('Retorno', 1, 'R$ 0,00'),
  createData('', 'Total', 'R$ 0,00'),
];

export default function ModalDetalhesAgendamento(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [agendamento, setAgendamento] = useState('AGENDADO');
  const [openGerarguia, setOpenGerarguia] = useState(false);
  const [openLancarPagamento, setLancarPagamento] = useState(false);
  const [openEditarAgedamento, setEditarAgendamento] = useState(false);
  const [openExcluir, setOpenExcluir] = useState(false);
  const { statusTabela, setStatusTabela, setLocation } = StateGlobal();
  const [tipoAgendamento, setTipoAgendamento] = useState('agendada')
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [load, setLoad] = useState(false);
  const [id, setId] = useState('');
  const [nomePac, setNomePac] = useState('');
  const [avatar, setAvatar] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [ loginType ] = useState(localStorage.getItem('loginType'))

  const { agendadoData, setAgendadoData ,setPos} = StateGlobal()


  const history = useHistory() 

  function AlterarEstadoGerarguia(state) {
    setOpenGerarguia(state)
  }

  function AlterarLancarPagamento(state) {
    setLancarPagamento(state)
  }

  function AlterarEditarAgendamento(state) {
    setEditarAgendamento(state)
  }

  useEffect(() => {
    if(agendamento === 'AGENDADO'){
      setIsDisabled(false)
    }else{
      setIsDisabled(true)
    }
  }, [agendamento])

  useEffect(() => {
    if (agendadoData) {
      if (agendadoData[0]) {
        setAgendamento(agendadoData[0].status);
        setId(agendadoData[0].id)
        setNomePac(agendadoData[0].patient.name);
        setAvatar(agendadoData[0].patient.avatar)
      } else {
        setAgendamento(agendadoData.status);
        setId(agendadoData.id);
        setNomePac(agendadoData.patient.name);
        setAvatar(agendadoData.patient.avatar)
      }
    }
    // setOpen(props.open);
    setStatusTabela(true)

  }, [agendadoData])

  useEffect(()=>{
    setTimeout(() => {
      setOpen(props.open);
    }, 1000);
   
   },[props])

  const closeModal = () => {
    setOpen(false);
    props.onChange(false)
    setStatusTabela(!statusTabela)

  }

  const handleChange = (event) => {
    setAgendamento(event.target.value);
    alterarStatusAgendamento(event.target.value);
  };

  const closeModalExcluir = () => {
    setOpenExcluir(false);
    props.onChange(false)

  }

  const openModalConfirm = () => {
    setOpen(false);
    props.onChange(false)
    setOpenExcluir(true);


  }
  async function alterarStatusAgendamento(value) {
    setLoad(true)
    if (agendadoData) {
      if (agendadoData[0]) {
        setId(agendadoData[0].id)
       
      } else {
        setId(agendadoData.id)
        
      }
    }
    await conexao.put(`schedule/status/${id}`,
      {
        "status": value
      })
      .then((response) => {
        setMessage('O status do agendamento foi alterado com sucesso!')
        setTypeAlert('success')
        setLoad(false)
        setTimeout(() => {
          setMessage('')
          setTypeAlert('')
        }, 1500);
      }).catch((error) => {
        setMessage('Erro ao alterar o status do agendamento. Por favor, tente novamente!')
        setTypeAlert('error')
        setLoad(false)
        setTimeout(() => {
          setMessage('')
          setTypeAlert('')
        }, 1500);
      })
  }

  function irAtendimento(){

    if(agendadoData[0]){
       localStorage.setItem('agendamentoId', agendadoData[0].id)
       const agendamentoId = agendadoData[0].id
       conexao.get(`/form/patient/schedule/${agendamentoId}`)
       .then((resp) => {
         localStorage.setItem('patientID' ,resp.data.data.patient_id)
       })
     } else {
       localStorage.setItem('agendamentoId', agendadoData.id)
       const agendamentoId = agendadoData.id
       conexao.get(`/form/patient/schedule/${agendamentoId}`)
       .then((resp) => {
         localStorage.setItem('patientID' ,resp.data.data.patient_id)
       })
      // history.push(`/atendimento/${agendadoData[0].patient.id}`);
    }

     setPos(0)
     setLocation('agendamento')
     localStorage.setItem('Location', 'agenda')
     history.push('/atendimento')
  }

  return (


    <div className="Modal-agendamento">
      <ThemeProvider theme={theme}>
        <Dialog maxWidth='xl' minWidth='xl' open={open} aria-labelledby="Modal-title-agendamento">



          <div className={classes.modalColor}>

            <DialogTitle id="Modal-title-agendamento">
              <div className="title-modal-close">
                Detalhes do agendamento
            <CloseIcon onClick={closeModal} />
              </div>
            </DialogTitle>
            {agendadoData && agendadoData[0] ?
              <DialogContent className={classes.dialogPadding}>
                <div className={classes.divDadosPaciente}>
                  <Avatar className={classes.avatar} alt={agendadoData ? agendadoData[0].patient.name : ''} 
                  src={avatar ? avatar : "/static/images/avatar/1.jpg"} />
                  <div className={classes.divDadosNome}>
                    <h1 className={classes.namePaciente}>{agendadoData ? agendadoData[0].patient.name : ''}</h1>
                    <p className={classes.telefonePaciente}>{agendadoData ? celular(agendadoData[0].patient.cellphone) : ''}</p>
                    <button className={classes.btnWhatsApp} onClick={()=> {
                      window.location.href = `https://api.whatsapp.com/send?phone=55${agendadoData ? agendadoData[0].patient.cellphone.replace(/\D/g,''): ''},&text=Olá ${agendadoData ? agendadoData[0].patient.name : ''}, você tem uma consulta ${agendadoData ? agendadoData[0].date : ''} com o Dr. ${agendadoData ? agendadoData[0].user.name : ''}. Responda SIM para confirmar e NÃO para cancelar`                      
                    }}>
                      <WhatsAppIcon className={classes.iconBtn} /> Enviar lembrete de consulta 
                    </button>
                    {/* <button className={classes.btnWhatsApp} onClick={()=> {
                      window.open(`https://api.whatsapp.com/send?phone=55${agendadoData ? agendadoData[0].patient.cellphone.replace(/\D/g,''): ''},&text=Olá ${agendadoData ? agendadoData[0].patient.name : ''}, você tem uma consulta ${agendadoData ? agendadoData[0].date : ''} com o Dr. ${agendadoData ? agendadoData[0].user.name : ''}. Responda SIM para confirmar e NÃO para cancelar`)                      
                    }}>
                      <WhatsAppIcon className={classes.iconBtn} /> Enviar lembrete de consulta location OPENNNN
                    </button> */}
                    {/* <ReactWhatsapp className={classes.btnReactWhats} number={`+55${agendadoData ? agendadoData[0].patient.cellphone : ''}`} message={`Olá ${agendadoData ? agendadoData[0].patient.name : ''}, você tem uma consulta ${agendadoData ? agendadoData[0].date : ''} com o Dr. ${agendadoData ? agendadoData[0].user.name : ''}. Responda SIM para confirmar e NÃO para cancelar`}>
                      <Button className={classes.btnWhats} type="button">
                        <WhatsAppIcon className={classes.iconBtn} /> Enviar lembrete de consulta
                    </Button>
                    </ReactWhatsapp> */}
                    {/* teste */}
                  </div>
                </div>
                {message ?
                  <div className="Modal-agendamento-alert">
                    <Alert variant="filled" severity={typeAlert}>
                      {message}
                    </Alert>
                  </div>
                  : ''}
                <div className={classes.divTable}>
                  <div className={classes.dadosConsulta}>
                    <div>
                      <h2 className={classes.dadosConsultaH2}>{agendadoData ? agendadoData[0].date : ''}</h2>
                      {agendadoData && agendadoData[0].description ?
                        <p className={classes.ptabelaAgendamento}>Observação: {agendadoData[0].description}</p>
                        : ''}
                      <p className={classes.ptabelaAgendamento}>Convênio: <strong>{agendadoData ? agendadoData[0].type : ''}</strong></p>
                    </div>
                    <div className="load-detalhe-agendamento">
                      <FormControl variant="outlined" className={classes.selectAgendamento}>
                        <Select
                          id="demo-simple-select-outlined"
                          value={agendamento}
                          onChange={handleChange}
                          disabled={load ? true : false}
                        >
                          <MenuItem value="AGENDADO">Agendado</MenuItem>
                          <MenuItem value="CANCELADO">Cancelado</MenuItem>
                          <MenuItem value="REALIZADO">Realizado</MenuItem>
                        </Select>
                      </FormControl>
                      {load ?
                        <CircularLoad />
                        : ''}
                    </div>
                  </div>
                  <TableContainer className={classes.tabelaAgendamento +' '+ classes.containerTabelaAgendamento} component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                      <TableHead className={classes.tabelaAgendamento}>
                        <TableRow className={classes.tabelaAgendamento}>
                          <TableCell className={classes.tabelaAgendamento}>Procedimento</TableCell>
                          <TableCell className={classes.tabelaAgendamento} align="center">Quantidade</TableCell>
                          <TableCell className={classes.tabelaAgendamento} align="center">Valor</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {agendadoData ?

                          agendadoData[0].items.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell className={classes.tabelaAgendamento} component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell className={classes.tabelaAgendamento} align="center">{row.quantity}</TableCell>
                              <TableCell className={classes.tabelaAgendamento} align="center">R$ {currency(row.value * row.quantity)}</TableCell>
                            </TableRow>
                          ))
                          : ''}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </DialogContent>
              :
              <DialogContent>
                <div className={classes.divDadosPaciente}>
                  <Avatar className={classes.avatar} alt={agendadoData ? agendadoData.patient.name : ''} src="/static/images/avatar/1.jpg" />
                  <div>
                    <h1 className={classes.namePaciente}>{agendadoData ? agendadoData.patient.name : ''}</h1>
                    <p className={classes.telefonePaciente}>{agendadoData ? celular(agendadoData.patient.cellphone) : ''}</p>
                    <button className={classes.btnWhatsApp} onClick={()=> {
                      window.location.href = `https://api.whatsapp.com/send?phone=55${agendadoData ? agendadoData[0].patient.cellphone.replace(/\D/g,''): ''},&text=Olá ${agendadoData ? agendadoData[0].patient.name : ''}, você tem uma consulta ${agendadoData ? agendadoData[0].date : ''} com o Dr. ${agendadoData ? agendadoData[0].user.name : ''}. Responda SIM para confirmar e NÃO para cancelar`                      
                    }}>
                      <WhatsAppIcon className={classes.iconBtn} /> Enviar lembrete de consulta
                    </button>
                    {/* <button className={classes.btnWhatsApp} onClick={()=> {
                      window.open(`https://api.whatsapp.com/send?phone=55${agendadoData ? agendadoData[0].patient.cellphone.replace(/\D/g,''): ''},&text=Olá ${agendadoData ? agendadoData[0].patient.name : ''}, você tem uma consulta ${agendadoData ? agendadoData[0].date : ''} com o Dr. ${agendadoData ? agendadoData[0].user.name : ''}. Responda SIM para confirmar e NÃO para cancelar`)                      
                    }}>
                      <WhatsAppIcon className={classes.iconBtn} /> Enviar lembrete de consulta location OPENNNN
                    </button> */}
                    {/* <ReactWhatsapp className={classes.btnReactWhats} number={`+55${agendadoData ? agendadoData.patient.cellphone : ''}`} message={`Olá ${agendadoData ? agendadoData.patient.name : ''}, você tem uma consulta ${agendadoData ? agendadoData.date : ''} com o Dr. ${agendadoData ? agendadoData.user.name : ''}. Responda SIM para confirmar e NÃO para cancelar`}>
                      <Button className={classes.btnWhats} type="button">
                        <WhatsAppIcon className={classes.iconBtn} /> Enviar lembrete de consulta
                    </Button>
                    </ReactWhatsapp> */}
                  </div>
                </div>
                {message ?
                  <div className="Modal-agendamento-alert">
                    <Alert variant="filled" severity={typeAlert}>
                      {message}
                    </Alert>
                  </div>
                  : ''}
                <div className={classes.divTable}>
                  <div className={classes.dadosConsulta}>
                    <div>
                      <h2 className={classes.dadosConsultaH2}>{agendadoData ? agendadoData.date : ''}</h2>
                      {agendadoData && agendadoData.description ?
                        <p className={classes.ptabelaAgendamento}>Observação: {agendadoData.description}</p>
                        : ''}
                    </div>
                    <div className="load-detalhe-agendamento">
                      <FormControl variant="outlined" className={classes.selectAgendamento}>
                        <Select
                          id="demo-simple-select-outlined"
                          value={agendamento}
                          onChange={handleChange}
                          disabled={load ? true : false}
                        >
                          <MenuItem value="AGENDADO">Agendado</MenuItem>
                          <MenuItem value="CANCELADO">Cancelado</MenuItem>
                          <MenuItem value="REALIZADO">Realizado</MenuItem>
                        </Select>
                      </FormControl>
                      {load ?
                        <CircularLoad />
                        : ''}
                    </div>
                  </div>
                  <p className={classes.ptabelaAgendamento}>Convênio: <strong>{agendadoData ? agendadoData.type : ''}</strong></p>
                  <TableContainer className={classes.tabelaAgendamento} component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                      <TableHead className={classes.tabelaAgendamento}>
                        <TableRow className={classes.tabelaAgendamento}>
                          <TableCell className={classes.tabelaAgendamento}>procedimento</TableCell>
                          <TableCell className={classes.tabelaAgendamento} align="center">Quantidade</TableCell>
                          <TableCell className={classes.tabelaAgendamento} align="center">Valor</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {agendadoData ?

                          agendadoData.items.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell className={classes.tabelaAgendamento} component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell className={classes.tabelaAgendamento} align="center">{row.quantity}</TableCell>
                              <TableCell className={classes.tabelaAgendamento} align="center">R$ {currency(row.value * row.quantity)}</TableCell>
                            </TableRow>
                          ))
                          : ''}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </DialogContent>
            }
            <DialogActions className={classes.paddingButton}>
              <DeleteIcon className={classes.btnFooter} onClick={openModalConfirm} />
              <Button className={classes.btnsFooter} onClick={() => {
                AlterarEstadoGerarguia(true)
              }} type="button">
                <LibraryAddIcon className={classes.iconBtn} /> 
          </Button>
              <Button className={classes.btnsFooter} onClick={() => AlterarLancarPagamento(true)} type="button">
                <AttachMoneyIcon className={classes.iconBtn} /> 
          </Button>
              <Button className={classes.btnsFooter} onClick={() => {


                if (Array.isArray(agendadoData)) {//converte obejto em array
                  setEditarAgendamento(true)
                  closeModal()
         
                } else {
                  const arr = [agendadoData]
                  setAgendadoData(arr)
                  setEditarAgendamento(true)
                  closeModal()
              

                }


              }} type="button">
                <EditIcon className={classes.iconBtn} /> 
          </Button>
            </DialogActions>
            { loginType === 'DOCTOR' && (
                <Button 
                  type="submit" 
                  variant="contained"
                  color="primary" 
                  className={classes.iniciarAtendimento + " color-primary"}  
                  onClick={irAtendimento} 
                  disabled={isDisabled}
                >
                  Iniciar o atendimento
                </Button>
            )}
          </div>
        </Dialog>

      </ThemeProvider>

      { openGerarguia ? <ModalGerarGuia open={openGerarguia} nomePac={nomePac} id={id} onChange={AlterarEstadoGerarguia} /> : ''}
      { openLancarPagamento ? <ModalLancarPagamento open={openLancarPagamento} onChange={AlterarLancarPagamento} /> : ''}
      { openEditarAgedamento ? <ModalAgendamento edit={true} open={openEditarAgedamento} onChange={AlterarEditarAgendamento} /> : ''}

      { openExcluir ? <ModalExcluir openExcluir={openExcluir} onChange={closeModalExcluir} tipoAgendamento={tipoAgendamento} /> : ''}
    </div>
  );
}