import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import Alert from  '../../Components/Alert';
import Tabela from './Tabela'
import './css/Agenda.css';
import { StateGlobal } from '../../ContextAPI/context';
import moment from 'moment'


const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0x 0px 0px gray",
    borderRadius: "0px",
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
  }
}));

export default function SpacingGrid() {
  const classes = useStyles();

  const { attAgenda, setAttAgenda } = StateGlobal()

  const date = new Date()
  const newDate = moment(date).format('DD/MM/YYYY, h:mm:ss a')



  useEffect(()=>{
    // window.alert(newDate)

    if(attAgenda === true){

      setAttAgenda(false)
    }
  },[])

  useEffect(() => {
    localStorage.removeItem('patientID')
    localStorage.removeItem('agendamentoId')
  }, [])

  return (
    <div className="Dashboard-container">
      <Grid container>

      <Grid item className="table-div-scroll" xs={12}>
        <Paper className={clsx(classes.paper, classes.colorPaper)}>
        <div className="container-agenda-mobile">
            <Tabela/>
        </div>
        </Paper>
      </Grid>

    </Grid>
  </div>
  );
}