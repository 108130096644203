import React, { useState, useEffect } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import ListIcon from '@material-ui/icons/List';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { StateGlobal } from '../../../../../ContextAPI/context'
import Button from '@material-ui/core/Button';

import './css/style.css'


const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '97%',
    },
  },
}));



export default function CardTextoCurto(props) {

 
  const classes = useStyles();
  const history = useHistory();
  const [checked, setcheck] = useState(true);
  const [items, setItems] = useState(props.opcoes ? props.opcoes : []);
  const [descricao, setDescricao] = useState('');
  const [index, setIndex] = useState('');
  const [status, setStatus] = useState(false)

  const {
    montagemstate,
    setMontagem,
    sessaostate,
    setIdcomponentAtual,
    idEditProntuario,
    idComponentAtual,
    setAtt,
    att
  } = StateGlobal();


  function onchange(e) {
    const { name, value } = e.target;


    montagemstate.map((item, index) => {
      if (props.tempId === item.tempId) {
        montagemstate[index].title = value


      }
    })

  }


  useEffect(() => {


    setItems(items)
    setDescricao(descricao)

    montagemstate.map((item, index) => {
      if (props.tempId === item.tempId) {
        montagemstate[index].options = items
      }
    })

  }, [status]);

  function onchangeOptions(e) {
    setDescricao(e.target.value)
  }




  function handlerClick(e, index) {

    setIndex(index)
    const lista = e.target.parentNode.children


    for (let i = 0; lista.length > i; i++) {

      const id = lista[i].id.slice(7)

      if (id == index) {
        e.target.classList.add("option-selected")
      } else {
        lista[i].classList.remove("option-selected")
      }
    }
  }

  function addOption() {
    const arr = items
    arr.push({ text: descricao, value: '' })

    setItems(arr)

    setDescricao('')

  }

  function deleteOption() {

    const arr = items
    arr.splice(index, 1)
    setItems(arr)
    setDescricao('')
    document.getElementById(`option-${index}`).classList.remove("option-selected")
    setStatus(!status)
    

  }

  function editOption() {
    if (descricao) {
      const arr = items
      const option = arr[index]
      setDescricao(option)
      arr[index] = descricao
      setItems(arr)
      setDescricao('')
      setStatus(!status)
    }


  }

  return (
    <div className="CardTextoCurto-container" >
      {props.tempId}
      <ListIcon className="icon-list-card-input" />
      <div className="container-card-input">

        <h3 className="h3-title-card-input">SELEÇÃO</h3>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField id="tituloDoCampo" name={props.tempId} onChange={onchange} label="Título do campo" variant="outlined" />
        </form>
        <h3 className="h3-title-card-input">OPÇÕES</h3>
        <div className="options">
          <div className="options-inputs">
            <TextField id="tituloDoCampo" name="tituloOpcoes" value={descricao} onChange={onchangeOptions} label="Descrição" variant="outlined" className="option-title" />
            <div className="options-buttons">
              <Button variant="contained" className="options-buttons-incluir" onClick={addOption}>
                INCLUIR
                </Button>
              <Button variant="contained" className="options-buttons-editar" onClick={editOption}>
                EDITAR
                </Button>
              <Button variant="contained" className="options-buttons-excluir" onClick={deleteOption}>
                EXCLUIR
                </Button>
            </div>
          </div>

          <div className="options-items">
            <ul>

              {items  ?
                items.map((dado, index) => {
                  const id = `option-${index}`
                  return <li id={id} onClick={(e) => { handlerClick(e, index) }}>{dado.text}</li>

                })


                : ''}

            </ul>
          </div>

        </div>
        <div className="Card-option">
          <IconButton name={props.tempId} onClick={() => {
            montagemstate.map((item, index) => {
              if (props.tempId === item.tempId) {
                const array = montagemstate
                array.splice(index, 1)

                setMontagem(array)
                setAtt(!att)

              }
            })

          }}>
            <DeleteIcon />
          </IconButton>
          <div className="divisor"></div>
          <FormControlLabel
            className="label-color"
            name='switch'
            onChange={() => {
              setcheck(!checked)
              
              montagemstate.map((item, index) => {
                if (props.tempId === item.tempId) {

                  item.required = checked
                }
              })

            }}
            value={checked}
            control={<Switch color="primary" />}
            label="Obrigatório"
            labelPlacement="start"


          />


        </div>
      </div>
    </div>
  );
}