import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import './css/Planos.css';
import { createMuiTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0x 0px 0px gray",
    borderRadius: "0px",
    height: "100%"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
  }
}));

const theme = createMuiTheme({
    palette: {
      secondary: {
        light: '#E7366A',
        main: '#E7366A',
        dark: '#D93364',
        contrastText: '#fff',
      },
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  });

export default function Planos() {
  const classes = useStyles();
  const history = useHistory();
  const [periodo,setPeriodo] = useState('mensal');
  const [planId, setPlanId] = useState(2);

  function selecionaPeriodo(value){
    setPeriodo(value)
  }

  return (
    <div className="container-envolve-planos">
        <ThemeProvider theme={theme}>
            <div className="titulo-pag-planos">
                <h1>Escolha seu plano</h1>
                <p>Temos o plano ideal para você fazer a gestão eficiente do seu consultório e de seus pacientes</p>
            </div>
            <div className="envolve-periodo-card">
              <div className="envolve-periodo-card-plan">
                <div 
                  onClick={() => {
                    selecionaPeriodo('mensal')
                    setPlanId(2)
                  }}                   
                  className={
                    periodo === 'anual' 
                    ? "envolve-periodo-card-plan-desactive" 
                    : "envolve-periodo-card-plan-active"
                  }
                >
                  <p>Mensal</p>
                </div>
                <div 
                  onClick={()=> {
                    selecionaPeriodo('anual')
                    setPlanId(3)
                  }} 
                  className={
                    periodo === 'anual' 
                    ? "envolve-periodo-card-plan-active" 
                    : "envolve-periodo-card-plan-desactive"
                  }
                >
                  <p>Anual</p>
                 </div>
              </div>
            </div>
            <div className="envolve-todos-cards">
                <div className="card-planos">
                    <div className="envolve-titulo-card-planos">
                        <h2>Plano Gold</h2>
                        <p>
                            <span className="preco-moeda">R$</span>
                            <span className="preco-plano">{ periodo === 'anual' ? 850 : '79,90' }</span>
                            <span className="preco-periodo">{ periodo === 'anual' ? '/ano' : '/mês' }</span>
                        </p>
                        <Button onClick={()=>history.push(`/contratar-plano?id=${planId}`)} className="color-primary btn-selecionar-plano" variant="contained" color="secondary">
                            Selecionar
                        </Button>
                    </div>
                    <div className="div-ul-list-descricao">
                        <ul className="ul-list-descricao">
                          <li>Sistema disponível na versão web e mobile (android e IOS)</li>
                          <li>Agenda inteligente on-line</li>
                          <li>Gestão Financeira</li>
                          <li>Prontuários Especializados</li>
                          <li>Relatórios Especiais</li>
                          <li>Confirmação das consultas on-line</li>
                          <li>Palestras com especialistas exclusivo para usuários Clinic Plus</li>
                        </ul>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    </div>
  );
}