import React, {useState, useEffect} from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Logo from '../../Assets/Images/LogoCP.svg';
import {  CircularProgress } from "@material-ui/core"
import FormLogin from './Form';
import ModalEsqueciSenha from './ResetResenha';
import './css/login.css';
import { useHistory } from 'react-router';
import CircularLoad from '../../Components/Load';


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
});

export default function Login() {
  const [openEsqueci, setOpenEsqueci] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      window.location.href = '/home'
    }
  }, [localStorage.getItem('token')])

  const closeModalEsqueci = () => {
    setOpenEsqueci(false);
  }

  const history = useHistory()

  return (
    <ThemeProvider theme={theme}>
     <div className="login-background-image">
         <div className="login-card-form-login">
           <img src={Logo} className="Login-logo"/>
               <FormLogin/>
               <div>
                  <Button 
                     href="#text-buttons" 
                     onClick={()=>setOpenEsqueci(true)}
                     className="lost-password" 
                     color="default">
                     Esqueci minha senha
                   </Button>
               </div>
         </div>
         <ModalEsqueciSenha open={openEsqueci} handleModal={closeModalEsqueci}/>
     </div>
   </ThemeProvider>
);
}