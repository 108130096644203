import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import moment from 'moment'
import conexao from "../../../Services/api"
import defaultIMG from "../../../Assets/Images/default-user-image.png"


const date = new Date()
const newDate = moment(date).format('DD/MM/YYYY')

function GeraPdf( DataGuia , props , descGuia ){
  console.log("props", props)
  console.log("DataGuia2",DataGuia)
    const url = DataGuia.avatar === null ? defaultIMG : DataGuia.avatar

    let newUrl = '' 

    const toDataURL = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            callback(reader.result);
          }
          reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      }
      
    toDataURL(url, function(dataUrl) {
      console.log('RESULT:', dataUrl)
      newUrl = dataUrl

    console.log("DataGuia", DataGuia)
    console.log("teste img",dataUrl)

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

const details = [
    
        {text: `Dr.${DataGuia.user.name} `, fontSize: 20},
        { columns: [     
            {         
            text: `${DataGuia.street}, ${DataGuia.number} \n `+
                    `${DataGuia.neighborhood} - CEP ${DataGuia.zip_code}\n`+
                    `${DataGuia.city}/${DataGuia.state} - Brasil`,
            },
            {
                image: `${newUrl}`,
                width: 180,        
            }]
        },

        {text: `${props.nomePac} `, fontSize: 16, alignment: 'center',margin: [0, 65,0,0]},
        {text: `Registro de Dr.${DataGuia.user.name} em ${newDate}`, fontSize: 12, alignment: 'center',margin: [0, 8,0,0]},

        {text: `${descGuia}`, fontSize: 12,margin: [0, 40,0,0],alignment: 'justify'},

        { columns: [     
          {         
          },
          {        
          },
          {alignment: 'center',margin: [0, 150,0,0],
                  table: {
                    widths: ['*'],
                    body: [[" "], [`Dr.${DataGuia.user.name}\n ${DataGuia.speciality.name}\n`]]
            },
            layout: {
                hLineWidth: function(i, node) {
                    return (i === 0 || i === node.table.body.length) ? 0 : 2;
                },
                vLineWidth: function(i, node) {
                    return 0;
                },
                
            },
  
          }]
      },
        
    ];

      
    function Rodape(currentPage, pageCount){
        return [
          
            {text: `Documento gerado por Clinic Plus - Sistema para gestão de clínicas e consultórios.`, fontSize: 12,margin: [30,0,0,0], alignment: 'center'},
            {text: currentPage + ' / ' + pageCount,alignment: 'right',fontSize: 9,margin: [0, 10, 40, 0]},
                   
        ]
    }

        const docDefinitios = {
            pageSize: 'A4',
            pageMargins: [50, 50, 50, 40],
            content: [details],
            footer: Rodape,
        }
        
       const pdf = pdfMake.createPdf(docDefinitios)

       pdf.getBlob((blob) => {
         console.log('blob',blob)
         const url = window.URL.createObjectURL(new Blob([blob]));
        //  console.log("url blob",url)
        const toDataURL = (url, callback) => {
          var xhr = new XMLHttpRequest();
          xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
              callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
          };
          xhr.open('GET', url);
          xhr.responseType = 'blob';
          xhr.send();
        }
      
       toDataURL(url, function(dataUrl) {
        console.log('RESULT:', dataUrl)
        
        conexao.post(`upload/hot`, {name: `${localStorage.getItem('userId')}.pdf`, type: blob.type, blob: dataUrl }).then((response)=>{

          setTimeout(() => {
            window.location.href = `${process.env.REACT_APP_STORAGE_URL}/storage/${response.data.data}`
          }, 2000)

          // console.log("resposta do blob",`${process.env.REACT_APP_STORAGE_URL}/storage/${response.data.data}`)
        })
      })
     })
       
    
})
}

export default GeraPdf;