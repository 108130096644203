import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import imgPerfil from '../../../../Assets/Images/default-user-image.png';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import { StateGlobal } from '../../../../ContextAPI/context';
import conexao from '../../../../Services/api';
import CircularProgress from '@material-ui/core/CircularProgress';


import './style.css';

const useStyles = makeStyles((theme) => ({

  buttonAvatar: {
    marginTop: '10px'
  }

}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#27b8d2',
      contrastText: '#FFF',
    }
  },

}, ptBR);


export default function Avatar(props) {
  const classes = useStyles();

  const [imgAvatar ,setImgAvatar] = useState(imgPerfil)
  const [isLoading, setIsLoading] = useState()  

  const { ViewPatient, isEditPatient } = StateGlobal()

   useEffect(()=>{
    setIsLoading(props.loading)
      
    
      if(props.imagem ){
        
        setImgAvatar(props.imagem)
        setIsLoading(false)
        
      }
   
  },[props.imagem])
  useEffect(()=>{
    setIsLoading(props.loading)
   
  },[props])

  function onClickImg(e){
        
    if(e.target.files[0].size > 10000000){
      alert("Documento maior que 10MB, por favor verifique o tamanho do arquivo.");
      return;
    };

    var file = e.target.files[0];
    
    if(file){
    setIsLoading(true)  
    var reader = new FileReader();

    reader.onloadend = function() {
                
      setAvatar(reader.result)

    }

    reader.readAsDataURL(file);
    }
  }
  
 
  async function setAvatar(img){
      
    await conexao.post(`/image/upload`,{
        "path" : "patient",
        "photo" : img
      })
    .then((resp) => {
    
        setImgAvatar(resp.data.data)
        props.handleImg(resp.data.data)
        setIsLoading(false)

    }).catch((error)=>{
      setIsLoading(false)
    })
  }
  

  return (
    <div className="avatar-container">
      <div className="avatar-cadastrar">
        {!isLoading ?
        <img src={imgAvatar} alt="logo" />
        :
        <CircularProgress />
        }
      </div>
     
     
      <div className="text-avatar">
    
        <h1>Imagem de perfil</h1>
        <p>Sua imagem deve ter no máximo 250x250px e 10MB.</p>
        {/* <Button className={classes.buttonAvatar} variant="contained" color='primary' >EDITAR FOTO</Button> */}
            <label className="upload" for='avatar'>EDITAR FOTO</label>
            <input id='avatar' type='file' onChange={onClickImg}></input>
      </div>
     
     
    </div>
  )
}