import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom:'10px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color:'#8f8f8f'
  },
  AccordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    color:'#8f8f8f'
  },

  buttons: {
    margin: '5px'

  },
  hr:{
   border: '1px solid #d3d3d3',
    width: '100%' 
  },
  divbuttons:{
   display:'flex',
   justifyContent:'flex-end' 
   }
}));

export default function SimpleAccordion(props) {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Atendente: {props.data.name}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails}>
          <Typography >
            Nome:  {props.data.name}
            <br />
           Email:  {props.data.email}
          </Typography>
          <hr className={classes.hr} />
          <div className={classes.divbuttons} >
            <IconButton className={classes.buttons} onClick={()=>{
 
              props.editar(props.data.id, props.data)

            }}>
            <EditIcon />
            </IconButton>

            <IconButton className={classes.buttons} onClick={() => {
                 props.excluir(props.data.user_id)
                }}>
            <DeleteIcon />
            </IconButton>

          
         
          </div>
        </AccordionDetails>
      </Accordion>
     

    </div>
  );
}
