import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'

export default function InputLabel({id ,title , required, edit}) {


    const [label, setLabel] = useState('');
    const { Answer, setAnswer, respEdit, AnswerEdit, setMontagemProntuario, MontagemProntuario, PosProntuario , respView , validaProntuario , setvalidaProntuario , NameProntuario, setNameProntuario, validation, setValidation,
    attValidation, setAttValidation, attEncerraError } = StateGlobal();
    const [req, setReq] = useState(required);
    const [error, setError] = useState(false)


  const handleChangeInput = event => {
    const { value } = event.target;

    Answer.map((item, index) => {
      if (item.question_id === id) {
        setLabel(value)
        Answer[index].answer = value

        if(req){
          if( !Answer[index].answer ){
            setError(true)
          }
          else{
            setError(false)
          }
        }

      }
    })
    const array = MontagemProntuario

    array[PosProntuario].montagem.map((item, index) => {
      if (item.tempId === id) {

        item.answer = value
      }

    })

    setMontagemProntuario(array)

  };
  

  useEffect(() => {
    setLabel(edit)
  }, [id])

  useEffect(()=>{
    validacaoOnchange()
  },[label])


  function validacaoOnchange() {
    if (!required) {
      return  
    }
  
    Answer.map((item) => {
      if (item.question_id === id) {  
        validaProntuario[id].error = !item.answer && validaProntuario[id].started
        validaProntuario[id].value = item.answer ? 'ok' : ''
        validaProntuario[id].started = true
  
        setvalidaProntuario({...validaProntuario})
      }
    })
  }

  useEffect(() => {
      Answer.map((item, index) => {
          if (item.question_id === id) {
            
      
              Answer[index].answer = title
          }
      })
  }, [id])
    
  return (
        <div>

          
            <p className="titulo-form">{title}</p>
            <TextField
                id={id}
                name={id}      
                className="mb-4"
                value={label}
                onChange={handleChangeInput}
                onBlur = {handleChangeInput}
                type="text"
                error={validaProntuario[id].error}
                required={req}
                disabled={respView}
                variant="outlined"
                disabled = {respEdit}
            
                inputProps={{ maxLength: 150 }}
            />

            <div style={{ color: 'red', width: '100%', fontSize: '15px' }}>
              {!respView && validaProntuario[id].error === true ? validaProntuario[id].message : ''}
            </div>
        </div>
  );
}
