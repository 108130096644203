import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'

export default function InputEmail({id ,title ,required , edit}) {
    const [email, setEmail] = useState('');
    const { Answer ,setAnswer ,respEdit , setMontagemProntuario ,MontagemProntuario , setNameProntuario, NameProntuario, PosProntuario ,setpos , respView, validaProntuario, setvalidaProntuario} = StateGlobal();

    const handleChange = event => {
        const { value } = event.target;
        Answer.map((item, index) => {
            if (item.question_id === id) {
                setEmail(value)
                Answer[index].answer = value
            }
        })
        const array  = MontagemProntuario
  
        array[PosProntuario].montagem.map((item , index)=>{
            if(item.tempId === id){
                item.answer = value
            }

        })

        setMontagemProntuario(array)
        
        
    };

    useEffect(()=>{
      setEmail(edit)
    },[id])

    useEffect(()=>{
        validacaoOnchange()
    },[email])


    function validacaoOnchange() {
        if (!required) {
          return  
        }
      
        Answer.map((item) => {
          if (item.question_id === id) {  
            validaProntuario[id].error = !item.answer && validaProntuario[id].started
            validaProntuario[id].value = item.answer ? 'ok' : ''
            validaProntuario[id].started = true
      
            setvalidaProntuario({...validaProntuario})
          }
        })
      }


    // useEffect(() => {
    //     Answer.map((item, index) => {
    //         if (item.question_id === id) {
    //             setEmail(item.answer)
    //         }
    //     })
    // }, [id])

    // useEffect(()=>{

    //     Answer.map((item,index)=>{
    //       if(item.question_id === id){  
    //         if(item.answer ===  ''){                 
    //        setEmail(edit)
    //         }else{
    //           setEmail(item.answer)

    //         }
    //       }
    //     })      

    //   },[edit])

  return (
        <div>
            <p className="titulo-form">{title}</p>
            <TextField
                className="mb-4"
                value={email}
                onChange={handleChange}
                disabled={respView}
                type="email"
                required={required}
                error={validaProntuario[id].error}
                variant="outlined"
            />

            <div style={{ color: 'red', width: '100%', fontSize: '15px' }}>
                {!respView && validaProntuario[id].error === true ? validaProntuario[id].message : ''}
            </div>
        </div>
  );
}
