import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'

export default function InputData({id , title , required ,edit}) {

    const [value, setValue] = React.useState('');

    const { Answer ,setAnswer ,respEdit , setMontagemProntuario , setNameProntuario, NameProntuario, MontagemProntuario , PosProntuario ,setpos , respView, validaProntuario, setvalidaProntuario} = StateGlobal();

    const handleChange = event => {
        const { value } = event.target;
        Answer.map((item, index) => {
            if (item.question_id === id) {
                setValue(value)
                Answer[index].answer = value
           
            }
        })
        const array  = MontagemProntuario
  
        array[PosProntuario].montagem.map((item , index)=>{
            if(item.tempId === id){
             
                item.answer = value
            }

        })

        setMontagemProntuario(array)
        


        
    };

    useEffect(()=>{
      setValue(edit)
    },[id])

    useEffect(()=>{
        validacaoOnchange()
    },[value])
    
    
    function validacaoOnchange() {
      if (!required) {
        return  
      }
  
      Answer.map((item) => {
        if (item.question_id === id) {  
          validaProntuario[id].error = !item.answer && validaProntuario[id].started
          validaProntuario[id].value = item.answer ? 'ok' : ''
          validaProntuario[id].started = true
  
          setvalidaProntuario({...validaProntuario})
        }
      })
    }


    // useEffect(() => {
    //     Answer.map((item, index) => {
    //         if (item.question_id === id) {
    //             setValue(item.answer)
    //         }
    //     })
    // }, [id])

    // useEffect(()=>{

    //     Answer.map((item,index)=>{
    //       if(item.question_id === id){  
    //         if(item.answer ===  ''){                 
    //        setValue(edit)
    //         }else{
    //           setValue(item.answer)

    //         }
    //       }
    //     })      

    //   },[edit])
  return (
        <div>
            <p className="titulo-form">{title}</p>
            <TextField
                id="data"
                value={value}
                required={required}
                variant="outlined"
                onChange={handleChange}
                error={validaProntuario[id].error}
                disabled={respView}
                type="date"
                InputLabelProps={{
                shrink: true,
                }}
            />

            <div style={{ color: 'red', width: '100%', fontSize: '15px' }}>
                {!respView && validaProntuario[id].error === true ? validaProntuario[id].message : ''}
            </div>
        </div>
  );
}
