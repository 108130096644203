import React, { useState, useEffect } from 'react'
import { Select, MenuItem, TextField, InputLabel, Button, IconButton, Badge } from '@material-ui/core'
import conexao from '../../../Services/api'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';




export default function SelectProcedimento({ value, index, json, excluir, insertQtd, arrayfinal }) {


    const useStyles = makeStyles((theme) => ({
        formControl: {
            marginTop: '1rem',
            marginBottom: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: '#F9F9F9',
            display:'flex',
            alignItems:'center',
            justifyContent:'flex-start'

        },

        SelectProcedimento: {
            width: '80%',
            backgroundColor: 'white',
        },
        QuantProce: {
            width: '50%',
            marginLeft: '1rem',
            backgroundColor: 'white',
       
        },


    }));





    const classes = useStyles();


    const [proc, setProc] = useState([])
    const [array, setArray] = useState()
    const [valueselect, setValueSelect] = useState('Mauro')
    const [quantity, setQtd] = useState()


    function listaProcedimentos2() {
        var userId = localStorage.getItem('userId');
        conexao.get(`/schedule/type/${userId}`).then((res) => {
            setProc(res.data)
        }).catch((error) => {
      
        })
    }

    useEffect(() => {
        listaProcedimentos2()
        setValueSelect(value.name)
        if (value.quantity) {
            setQtd(value.quantity)
        }

    }, [value])


    const valor = arrayfinal[index].name

    return (

        <div className={classes.formControl} >


            {/* {arrayfinal && arrayfinal[index].name} */}


            <Select
                required
                name='procedimento'
                id="demo-simple-select-outlined"
                //value={values.procedimento}
                // onClick={() => {(e)=>{setValueSelect(e.target.value)} }}
                //label="Procedimentos"
                value={valueselect}
                

                className={classes.SelectProcedimento}

            >
                <MenuItem >
                    <em>Selecione um procedimento</em>
                </MenuItem>


                {proc.map((item) => {
                    return (

                        <MenuItem value={item.name} onClick={() => {
                            json({ name: item.name, value: item.value, index: index, id: item.id  ,quantity : 1})
                            // setArray({ name: item.name, value: item.value, index: index, id: item.id })
                        }}>{item.name}</MenuItem>
                    )
                })}
            </Select>


            <TextField className={classes.QuantProce} type="number" id=""
                label="Quant." name='quant'
                onChange={(e) => {
                    insertQtd({ quantity: parseInt(e.target.value) }, index)
                    setQtd(e.target.value)
                }}
                variant="outlined" required value={quantity ? quantity : 1} />


            {index === 0 ? ""
                :
                <IconButton onClick={() => { excluir(value ,index) }} aria-label="show 10 new notifications" color="inherit">
                    <CloseIcon />

                </IconButton>
            }

        </div>
    )

}

