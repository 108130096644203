import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Login from './Pages/NewLogin';
import TesteRedux from './Components/ReduxTeste/index'
import Drag from './Components/Dragdrop';
import ProntuarioListagemModelosSalvos from './Pages/Prontuarios/ListagemModelosSalvos';
import Topo from './Components/MenuTopo';
import Dashboard from './Pages/Dashboard';
import Agenda from './Pages/Agenda';
import NovoModeloProntuario from './Pages/Prontuarios/NovoModeloProntuario';
import Atendimento from './Pages/Atendimento';
import CadastroPaciente from './Pages/CadastroPaciente'
import CadastrarProfissional from './Pages/CadastroProfissional';
import ListarPacientes from './Pages/Pacientes'
import Suporte from './Pages/Suporte'
import MenuInferior from './Components/MenuInferior';
import Atendentes from './Pages/Atendentes'
import Planos from './Pages/Planos';
import ContratarPlanos from './Pages/Planos/ContatarPlanos';
import CadastroProfissional from './Pages/CadastroProfissional/EditarCadastro'
import Financeiro from './Pages/FinanceiroNovo/Resumo'
import Notificacoes from './Pages/Notificacoes';
import ApplePayNotification from './Components/ApplePayNotification';

import { isIOS } from 'react-device-detect'

export default function Routes() {

    const Home = () => {

        return (
            <div>
                <Topo />
                <Agenda/>
                <MenuInferior />
            </div>
        )
    }


    return (

        <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/home" component={() =>
                localStorage.getItem('/painel') === 'true' ?
                <div>
                    <Topo />
                    <Dashboard />
                    <MenuInferior />

                </div>
                : Home()
            }>
            </Route>
            {/* <Route path="/cadastro" component={() =>
                <div>
                    <CadastrarProfissional />
                </div>
            }>
            </Route> */}
            <Route path="/atendentes" component={() =>
            localStorage.getItem('/atendente') === 'true' ?
                <div>
                    <Topo />
                    <Atendentes />
                    <MenuInferior />
                </div>
                  : Home()
                } />
            <Route path="/financeiro" component={() =>
                localStorage.getItem('/financeiro') === 'true' ?
                    <div>
                        <Topo />
                        <Financeiro />
                        <MenuInferior />
                    </div>
                    : Home()
            } />
            <Route path="/agenda" component={() =>
                    <div>
                        <Topo />
                        <Agenda />
                        <MenuInferior />
                    </div>
            }>
            </Route>
            <Route path="/suporte" component={() =>
                <div>
                    <Topo />
                    <Suporte />
                    <MenuInferior />
                </div>
            }>
            </Route>
            <Route path="/meu-perfil" component={() =>
              localStorage.getItem('/perfil') === 'true' ?
                <div>
                    <Topo />
                    <CadastroProfissional />
                    <MenuInferior />
                </div>
                  : Home()
            }>
            </Route>
            <Route path="/listagem-modelos" component={() =>
               localStorage.getItem('/novo-modelo') === 'true' ?
                <div>
                    <Topo />
                    <ProntuarioListagemModelosSalvos />
                </div>
                      : Home()
            }>
            </Route>
            <Route path="/novo-modelo" component={() =>
                localStorage.getItem('/novo-modelo') === 'true' ?
                    <div>
                        <Topo />
                        <MenuInferior />
                    </div>
                    : Home()
            }>
            </Route>
            <Route path="/notificacoes" component={() =>
                <div>
                    <Topo />
                    <Notificacoes />
                    <MenuInferior />
                </div>
            }>
            </Route>
            <Route path="/listagem-modelos" component={() =>
                            localStorage.getItem('/novo-modelo') === 'true' ?
                <div>
                    <Topo />
                    <NovoModeloProntuario />
                </div>
                    : Home()
            }>
            </Route>
            <Route path="/atendimento" component={() =>
                <div>
                    <Topo />
                    <Atendimento />
                    <MenuInferior />
                </div>
            }>
            </Route>
            <Route path="/cadastro-paciente" component={() =>
                    <div>
                        <Topo />
                        <CadastroPaciente />
                        <MenuInferior />
                    </div>
            }>
            </Route>
            <Route path="/listagem-pacientes" component={() =>
                    <div>
                        <Topo />
                        <ListarPacientes />
                        <MenuInferior />
                    </div>
            }>
            </Route>
            <Route path="/planos" component={() =>
                   localStorage.getItem('/plano') || localStorage.getItem('/planos') ?
                    <div>
                        <Topo />
                        { isIOS ? (
                            <ApplePayNotification />
                        ) : (
                            <Planos />
                        )}
                        <MenuInferior />
                    </div>
                    :
                    Home()
            }>
            </Route>
            {/* <Route path="/contratar-plano" component={() =>
                localStorage.getItem('/plano') || localStorage.getItem('/planos') ?
                    <div>
                        <Topo />
                        <ContratarPlanos />
                        <MenuInferior />
                    </div>
                     :
                     Home()
            }>
            </Route> */}
        </Switch>
    )

}


//
