import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import { TextField, MenuItem, Select, InputLabel, FormControl } from '@material-ui/core/';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RightIcon from '@material-ui/icons/ArrowRight';
import LeftIcon from '@material-ui/icons/ArrowLeft';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { StateGlobal } from '../../../ContextAPI/context';
// import DadosPessoais from '../Components/DadosPessoais';
import conexao from '../../../Services/api2';
import './style.css'
import Modal from './Modal'
import ModalCategoria from './ModalCategoria'
import ModalLancamento from './ModalLancamento'
import AccordionFinanceiro from './AccordionFinanceiro'
import { currency, currencyNegative } from '../../../Components/ReduxTeste/Validate'
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import watermark from '../../../Assets/Images/plus_watermark.png';

import '../../../App.css'
import { getPosition } from '@syncfusion/ej2-react-grids';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  colorPaperWhite: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 0px 0px gray",
    padding: '2rem'
  },
  envolveResumo: {
    marginTop: '6rem',
    height: '87vh',
    padding: '2rem',
  },
  envolveDadosPaciente: {
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  divEnvolveFotoDados: {
    display: 'flex'
  },
  divEnvolveTextos: {
    '& p': {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: '1rem',
      color: '#8a8a8a'
    }
  },
  avatar: {
    width: '60px',
    height: '60px',
    fontSize: '2rem'
  },
  tituloName: {
    fontSize: '1.3em',
    color: '#27b8d2 !important',
    marginBottom: '.3rem !important',
    fontWeight: '600'
  },
  tituloEtapa: {
    fontSize: '1.3em',
    color: '#757575 !important',
    marginBottom: '1rem !important',
    fontWeight: '600'
  },
  filtroData: {
    display: 'flex',
    marginTop: '20px'
  },
  filtroResulo: {
    backgroundColor: 'white',
    marginTop: '2rem',
    padding: '2rem',
    height: '100%',
  },
  marginTop: {
    marginTop: '2rem'
  },
  pSelecionarData: {
    marginRight: '1rem',
    color: '#757575',
    fontWeight: '500'
  },
  pate: {
    marginRight: '1rem',
    color: '#757575',
    fontWeight: '500',
    marginLeft: '10px'
  },
  textField: {
    marginRight: '2rem'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  table: {
    boxShadow: '0px 0px 0px 0px grey',
    marginTop: '0rem',
  }
  ,
  topo: {
    display: 'flex',
    padding: '2rem',
    justifyContent: 'space-between',


  },
  buttonstopdireito: {
    marginRight: '10px'

  },
  select: {
    //  marginLeft:'10px'

  },
  formControl: {
    minWidth: 50,
    minHeight: 46,
    width: '100%',
    display: 'grid',
    marginTop: '15px'
  },
  wrap: {
    whiteSpace: 'nowrap'
  },
  value: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: '2rem',
    margin: 0
  },
  valueTitle: {
    fontWeight: 600,
    color: '#27b8d2'
  },
  alignRight: {
    textAlign: 'right'
  },
  paginator: {
    fontSize: 13,
    borderRadius: '8px',
    margin: '0.5rem'
  },
  loading: {
    width: '100%',
    padding: '100px',
    textAlign: 'center',
    display: 'block',
    color: '#666666',
    // position: 'absolute',
    // top: '40%'
  },
  buttonArray: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  jumbotron: {
    backgroundColor: '#E83368',
    padding: '1rem',
    margin: '1rem 0',
    borderRadius: '10px',
    textAlign: 'right',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    backgroundImage: 'url(' + watermark + ')',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '-20px',
    backgroundPositionY: '-20px'
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#27b8d2',
      contrastText: '#fff',
    }
  },
}, ptBR);

function createData(data, hora, prontuario) {
  return { data, hora, prontuario };
}


export default function ResumoFinanceiro() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [dataInit, setDataInit] = useState();
  const [dataFim, setDataFim] = useState();
  const [iddelete, setIddelete] = useState();
  const [open, setOpen] = useState(false);
  const [dataarr, setDataarr] = useState();
  const [categoryId, setCategoryId] = useState('');

  const [status, setStatus] = useState('');
  const [procedureType, setProcedureType] = useState('');
  const [paymentTypeList, setPaymentTypeList] = useState();
  const [categoryList, setCategoryList] = useState();
  const [paymentType, setPaymentType] = useState('');
  const [paymentTypeId, setPaymentTypeId] = useState('');
  const [creator, setCreator] = useState('');

  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [openFinancialRecordModal, setOpenFinancialRecordModal] = useState(false);
  const [edit, setEdit] = useState({ open: false, data: '' });
  const [typeAlert, setTypeAlert] = useState('');
  const [message, setMessage] = useState('');
  const [load, setLoad] = useState(false)
  const [valorTotal, setValorTotal] = useState(0)
  const [valorTipo, setValorTipo] = useState('Total Geral')
  const [selectedItem, setSelectedItem] = useState('')
  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const [actualPage, setActualPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [showFilter, setShowFilter] = useState(false)

  useEffect(() => {
    console.log('API NOVA')
    console.log(process.env.REACT_APP_NEW_API_URL)
    setSelectedDoctor(parseInt(localStorage.getItem('selectedDoctor')));
    // getHistorico()
    filter()
    getPaymentTypes()
    getCategories()
  }, [])

  useEffect(() => {
    console.log('LOAD: ', load)
  }, [load])

  function getPaymentTypes()
  {
    conexao.get('finance/paymenttype').then((res) => {
      console.log('paymentTypeList')
      console.log(res.data.data)

      setPaymentTypeList(res.data.data)

    }).catch((error) => {
    })
  }

  function getCategories() {
    const userId = (parseInt(localStorage.getItem('selectedUser')) ? localStorage.getItem('selectedUser') : localStorage.getItem('userId'))

    conexao.get('/finance/category/get/' + userId).then((res) => {
      setCategoryList(res.data.data)
    }).catch((error) => {
      Alertshow('Não há dados para listar !', 'error')
    })
  }

  function Alertshow(msg, type) {
    setTypeAlert(type)
    setMessage(msg)

    setTimeout(() => {
      setMessage('')
    }, 5000);
  }

  function cleanEdit() {
    setEdit({ open: false, data: '' })
  }

  function getHistorico() {
    var userId = (parseInt(localStorage.getItem('selectedUser'))) ? localStorage.getItem('selectedUser') : 0;

    conexao.post('finance/filter/' + userId).then((res) => {
      if (res.data.data) {
        console.log('ROW')
        console.log(res.data.data.data.total)
        setLastPage(res.data.data.data.last_page)
        setRows(res.data.data.data.data)
        setDataarr(res.data.data.data.data)
        // calculaTotal(res.data.data.data.data)
        setValorTotal(res.data.data.data.total)
      }
    })
  }

  function editFinancialRecord(id) {
    console.log('editFinancialRecord')
    console.log(id)
    setOpenFinancialRecordModal(true)
    setSelectedItem(id)
  }

  function deletePrompt(id) {
    console.log('deletePrompt')
    console.log(id)
    setOpen(true)
    setSelectedItem(id)
  }

  function calculaTotal(data) {
    setValorTotal(0)
    var tot = 0;
    data.forEach((item) => {
      console.log(item.type)
      if (item.type === 'Pagamento')
        tot -= item.value
      else
        tot += item.value
    })
    setValorTotal(tot)
  }

  function closemodal() {
    setOpen(false)
    filter()
  }

  function nextPage() {
    filter(actualPage + 1)
    setActualPage(actualPage + 1)
  }

  function previousPage() {
    if (actualPage > 1) {
      filter(actualPage - 1)
      setActualPage(actualPage - 1)
    }
  }

  function closeCategoryModal() {
    setOpenCategoryModal(false)
  }

  function closeFinancialRecordModal() {
    console.log('closeFinancialRecordModal')
    // getHistorico()
    setSelectedItem('')
    filter()
    setOpenFinancialRecordModal(false)
  }

  function deleteFinancialRecord() {
    setLoad(true)
    conexao.delete('/finance/' + selectedItem).then((res) => {

      setLoad(false)
      setTypeAlert('success')
      setMessage('Lançamento excluído com sucesso!')
      setTimeout(() => {
        //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        setMessage('')
        closemodal()
        setRows([])
        // getHistorico()
        filter()
      }, 3000);

    }).catch((error) => {
      setLoad(false)
      setTypeAlert('error')
      setMessage('Erro ao deletar!')
      setTimeout(() => {
        //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        setMessage('')
      }, 3000);
    })

  }

  function changeDate(data) {
    const arr = data.split(" ")
    const date = arr[0].split('-')
    const dateRev = date.reverse()
    const formatDate = `${dateRev[0]}/${dateRev[1]}/${dateRev[2]}`
    const hora = arr[1].split(":")
    const formatHora = `${hora[0]}:${hora[1]}`

    return [formatDate, formatHora]
  }

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  function filter(page) {
    const userId = localStorage.getItem('selectedUser')
    const payload = {
      "started_at": dataInit,
      "finished_at": dataFim,
      "finance_category_id": categoryId,
      "status": status,
      "creator": creator,
      "payment_type": paymentType,
      "payment_type_id": paymentTypeId,
      "page": page
    }

    setLoad(true)
    conexao.post(`/finance/filter/${userId}`, payload)
    .then((res) => {
      setValorTipo(
        paymentType === '' 
          ? 'Total Geral' 
          : `Total ${capitalize(paymentType == 'C' ? 'Recebimentos' : 'Pagamentos')}`
      )

      console.log(res.data.data)
      setLastPage(res.data.data.data.last_page)
      setRows(res.data.data.data.data)
      setDataarr(res.data.data.data.data)
      setValorTotal(res.data.data.total)
      
      setLoad(false)
    })
    .catch(() => {
      setValorTotal(0)
      setRows('')
      setLoad(false)
    })
  }

  function cleanFilter() {
    const userId = localStorage.getItem('selectedUser')
    const payload = {
      "started_at": '',
      "finished_at": '',
      "finance_category_id": '',
      "status": '',
      "creator": '',
      "payment_type": '',
      "payment_type_id": '',
      "page": 1
    }

    conexao.post(`/finance/filter/${userId}`, payload)
    .then((res) => {
      setValorTipo('Total Geral')

      console.log(res.data.data)
      setLastPage(res.data.data.data.last_page)
      setRows(res.data.data.data.data)
      setDataarr(res.data.data.data.data)
      // calculaTotal(res.data.data.data)
      console.log('TOTAL', res.data.data.total)
      setValorTotal(res.data.data.total)
    })
    .catch(() => {
      setValorTotal(0)
      setRows('')
    })
  }

  return (
    <div className={localStorage.getItem('containerClass')}>
      <div className="card">
        <h1>Financeiro</h1>
        <div className={classes.jumbotron}>
          <span className={classes.valueTitle}>{valorTipo}</span>
          <h3 className={classes.value}>R$ {valorTotal.toLocaleString('pt-BR')}</h3>
        </div>
        
        <div className={classes.buttonArray}>
          <Button disabled={localStorage.getItem('loginType') == 'EMPLOYEE' && (isNaN(selectedDoctor) || parseInt(selectedDoctor) == 0)} className={clsx("color-primary")} variant="contained" color="primary" onClick={() => {
            setOpenCategoryModal(true)
          }}>Categorias</Button>
          <Button disabled={localStorage.getItem('loginType') == 'EMPLOYEE' && (isNaN(selectedDoctor) || parseInt(selectedDoctor) == 0)} onClick={() => {
            setOpenFinancialRecordModal(true)
          }} variant="contained" className="color-primary" color="primary">Lançamentos</Button>
          <Button disabled={localStorage.getItem('loginType') == 'EMPLOYEE' && (isNaN(selectedDoctor) || parseInt(selectedDoctor) == 0)} onClick={() => {
            setShowFilter(!showFilter)
          }} variant="contained" className="color-primary" color="primary">Filtros</Button>
        </div>

        {showFilter ?
          <div class="d-block">
            <h4>Filtrar por:</h4>
            <div>
              <form noValidate>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="date"
                    label="Data inicial"
                    type="date"
                    onChange={(e) => { setDataInit(e.target.value) }}
                    value={dataInit}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.formControl}
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <TextField
                    id="date"
                    label="Data final"
                    type="date"
                    value={dataFim}
                    onChange={(e) => { setDataFim(e.target.value) }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.formControl}
                  />
                </FormControl>
              
                <FormControl className={classes.formControl}>
                  <InputLabel className={classes.select} id="demo-simple-select-helper-label">TIPO</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    className={classes.formControl}
                    value={paymentType}
                    onChange={(e) => { setPaymentType(e.target.value) }}
                  >
                    <MenuItem key={'vazio'} value={''}>...</MenuItem>
                    <MenuItem key={'1'} value={'D'}>PAGAMENTO</MenuItem>
                    <MenuItem key={'2'} value={'C'}>RECEBIMENTO</MenuItem>
                  </Select>
                </FormControl>

                {localStorage.getItem('loginType') == 'CLINIC' ? 
                  <FormControl className={classes.formControl}>
                    <InputLabel className={classes.select} id="demo-simple-select-helper-label">TP. PROCEDIMENTO</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.formControl}
                      value={creator}
                      onChange={(e) => { setCreator(e.target.value) }}
                    >
                      <MenuItem key={'vazio'} value={''}>...</MenuItem>
                      <MenuItem key={'1'} value={'DOCTOR'}>CRIADO PELO PROFISSIONAL</MenuItem>
                      <MenuItem key={'2'} value={'CLINIC'}>CRIADO PELA CLÍNICA</MenuItem>
                    </Select>
                  </FormControl>
                : ''}

                <FormControl className={classes.formControl}>
                  <InputLabel className={classes.select} id="demo-simple-select-helper-label">FRM. PAGAMENTO</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    className={classes.formControl}
                    value={paymentTypeId}
                    onChange={(e) => { setPaymentTypeId(e.target.value) }}
                  >
                    <MenuItem key={'vazio'} value={''}>...</MenuItem>
                    {paymentTypeList ? paymentTypeList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id} onClick={() => { setPaymentTypeId(item.id) }}>{item.name} </MenuItem>
                      )
                    }) : ''}
                  </Select>
                </FormControl>

                <FormControl className={classes.formControl}>
                  <InputLabel className={classes.select} id="demo-simple-select-helper-label">STATUS</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    className={classes.formControl}
                    value={status}
                    onChange={(e) => { setStatus(e.target.value) }}
                  >
                    <MenuItem key={'vazio'} value={''}>...</MenuItem>
                    <MenuItem key={'1'} value={'PAGO'}>PAGO</MenuItem>
                    <MenuItem key={'2'} value={'PENDENTE'}>PENDENTE</MenuItem>
                  </Select>
                </FormControl>

                <div class="d-flex">

                  <Button
                    style={{ margin: '2rem 0.5rem', width: '45%' }}
                    className="color-primary"
                    variant="contained"
                    color="primary"
                    onClick={() => { filter() }}
                  >
                    Filtrar
                  </Button>

                  <Button
                    style={{ margin: '0.5rem', width: '45%' }}
                    className="color-secondary"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setDataInit('')
                      setDataFim('')
                      // getHistorico()

                      setPaymentType('')
                      setPaymentTypeId('')
                      setCreator('')
                      setStatus('')
                      setValorTipo('Total Geral')
                      
                      cleanFilter()
                    }}
                  >
                    Limpar
                  </Button>
                </div>
              </form>
            </div>
          </div>
        : ''}

        {!load ?
          <>
            {rows.length ?
              <>
                <div className={[classes.alignRight, classes.marginTop].join(' ')}>
                  <IconButton className={classes.paginator} onClick={previousPage} disabled={actualPage == 1}>
                    <LeftIcon /> <span>Anterior</span>
                  </IconButton>
                  <IconButton className={classes.paginator} onClick={nextPage} disabled={actualPage >= lastPage}>
                  <span>Próxima</span> <RightIcon />
                  </IconButton>
                </div>

                {rows.map((row , index) => {
                    return (
                      <AccordionFinanceiro key={index} data={row} edit={editFinancialRecord} delete={deletePrompt} />
                    )
                  })
                }

                {/* <TableContainer className={classes.table} component={Paper}>
                  <Table size="small" aria-label="tabela resumo">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Vencimento</TableCell>
                        {localStorage.getItem('loginType') == 'CLINIC' ? 
                          <TableCell>Usuário</TableCell>
                        : ''}
                        <TableCell align="center">Tipo</TableCell>
                        <TableCell align="center">Descrição</TableCell>
                        <TableCell align="center">Valor</TableCell>
                        <TableCell align="center">Categoria</TableCell>
                        <TableCell align="center">Forma de Pagamento</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>
                    </TableHead>


                    <TableBody>

                          {rows.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell align="center">{row.id}</TableCell>
                              <TableCell component="th" scope="row">{changeDate(row.due_date)[0]}</TableCell>
                              {localStorage.getItem('loginType') == 'CLINIC' ? 
                                <TableCell>{row.user_name}</TableCell>
                              : ''}
                              <TableCell align="center">{row.type}</TableCell>
                              <TableCell align="center">{row.description}</TableCell>
                              <TableCell align="center" className={classes.wrap}>R$ {currency(row.value)} </TableCell>
                              <TableCell align="center">{row.category_name}</TableCell>
                              <TableCell align="center">{row.payment_type_name}</TableCell>
                              <TableCell align="center" >{row.status}</TableCell>
                              <TableCell className={classes.wrap} align="center">

                                <IconButton onClick={() => {
                                  setOpenFinancialRecordModal(true)
                                  setSelectedItem(row.id)
                                }}>
                                  <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => {

                                  setOpen(true)
                                  setIddelete(row.id)

                                }}>
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        }
                    </TableBody>

                  </Table>
                </TableContainer> */}

                <div className={classes.alignRight}>
                  <IconButton className={classes.paginator} onClick={previousPage} disabled={actualPage == 1}>
                    <LeftIcon /> <span>Anterior</span>
                  </IconButton>
                  <IconButton className={classes.paginator} onClick={nextPage} disabled={actualPage >= lastPage}>
                  <span>Próxima</span> <RightIcon />
                  </IconButton>
                </div>
              </>
            :
              <Alert variant="filled" severity='warning' className={classes.marginTop}>Nenhum registro encontrado</Alert>
            }
          </>
          :
              <div className={classes.loading}>
                <CircularProgress />
                <div className={classes.marginTop}>Carregando...</div>
              </div>
          }

        <Modal desc={'Tem certeza que deseja excluir este lançamento?'} typeAlert={typeAlert} message={message} load={load} titulo={'Financeiro'} delete={deleteFinancialRecord} open={open} close={closemodal} />

        <ModalCategoria opencategoria={openCategoryModal} close={closeCategoryModal} categoryList={categoryList} update={getCategories}/>
        <ModalLancamento openFinancialRecordModal={openFinancialRecordModal} close={closeFinancialRecordModal} item={selectedItem} edit={edit} cleanedit={cleanEdit} paymenttypes={paymentTypeList} categoryList={categoryList}/>
      </div>
    </div>

  );
}