import React, { useEffect, useState } from "react"
import Alert from "@material-ui/lab/Alert"
import Button from "@material-ui/core/Button"
import { ptBR } from "@material-ui/core/locale"
import { createMuiTheme } from "@material-ui/core/styles"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom"
import conexao from "../../Services/api"
import { data } from "../../Pages/Agenda/Modais/Validate"

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: " #f50057 !important",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c51162 !important;",
    },
  },
}))

const theme = createMuiTheme(
  {
    palette: {
      secondaryAlert: {
        light: "#E7366A",
        main: "#E7366A",
        dark: "#E7366A",
        contrastText: "#E7366A",
      },
    },
  },
  ptBR
)

export default function AlertDashboard() {
  const classes = useStyles()
  const history = useHistory()

  const [dataAlert, setDataAlert] = useState("")
  const [message, setMessage] = useState("")
  const [plan, setPlan] = useState("")

  useEffect(() => {
    call()
  }, [])

  useEffect(() => {
    if (dataAlert) {
      let dataExpired = new Date((dataAlert.expired_at).replace(/\s/, 'T')+'Z')
      let dataAtual = new Date()

      let expired = dataExpired - dataAtual
      expired = expired / 1000 / 60 / 60 / 24
      expired = expired.toFixed(0)

      expired = expired.toString()

      if (expired <= 0) {
        setMessage(`Bem vindo ao Clinic Plus! Seu período de avaliação expirou`)
      } else {
        setMessage(
          `Bem vindo ao Clinic Plus! Seu período de avaliação termina em ${Number(
            expired
          )} dias`
        )
      }
    }
  }, [dataAlert])

  async function call() {
    try {
      const id = localStorage.getItem("doctorId")
      const response = await conexao.get(`/doctor/plan/get/${id}`)
      console.log("ver", response)
      setDataAlert(response.data.data[0])
      setPlan(response.data.data[0].plan.name)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      {plan === "Free" ? (
        <Alert
          className="Dashboard-Graficos-alert"
          variant="filled"
          color="info"
        >
          {message}

          <Button
            variant="contained"
            className={classes.btn}
            color="secondaryAlert"
            onClick={() => {
              history.push("/planos")
            }}
          >
            ASSINE AGORA
          </Button>
        </Alert>
      ) : (
        ""
      )}
    </div>
  )
}
