import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Cronometro from './Cronometro'
import { StateGlobal } from '../../../ContextAPI/context'
import SaveIcon from '@material-ui/icons/Save';
import StopIcon from '@material-ui/icons/Stop';
import SecaoLateral from './secoes'
import conexao from '../../../Services/api'
import Modal from '../Components/Modal'


const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto"
  },
  envolveProntuario: {
    backgroundColor: '#f9f9f9',
    padding: '1rem',
    marginTop: '4rem',
    borderRadius: '6px',
  },
  titleProntuario: {
    color: '#777777',
    fontSize: '1.5em'
  },
  btnInicarAtendimento: {
    width: '100%',
    marginBottom: '.6rem'
  },
  btnInicarAtendimentoSec: {
    width: '100%',
    marginBottom: '.6rem'
  },
  btnDuracao: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  divSecao: {
    backgroundColor: 'white',
    margin: 0,
    borderRadius: '4px',
    cursor: 'pointer',
  },
  titleSecao: {
    color: '#27b8d2',
    fontSize: '1.1em',
    padding: '.7rem'
  },
  btnfinalizar: {
    width: '100%',
    marginBottom: '1rem'
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#27b8d2',
      contrastText: '#fff',
    }
  },
}, ptBR);

export default function ProntuarioLateralEsquerda() {
  const classes = useStyles();

  const [open, setOpen] = useState(false)
  const { pos, setPos, MontagemProntuario, PosProntuario, setPosProntuario, Answer, respEdit, setrespEdit, setAnswer, respView, setRespView, validaProntuario, setvalidaProntuario, NameProntuario, setNameProntuario, setObservationStep, observationStep, validation   } = StateGlobal();


  const [openModal, setOpenModal] = useState(false)
  const [ocultarDuracao, setOcultarDuracao] = useState(false)
  const [NewAnswerTemp, setNewAnswerTemp] = useState()
  const [load, setLoad] = useState(false);
  const [typeAlert, setTypeAlert] = useState('');
  const [message, setMessage] = useState('');
  const [modalType, setModalType] = useState()
  const [modalInfos, setModalInfos] = useState({
    title: '',
    description: ''
  })

  const localStorageLocation = localStorage.getItem('Location');

  useEffect(() => {
    return () => {
      setrespEdit(false)
      setRespView(false)

      setPos(0)
      setPosProntuario(0)
      setOpenModal(false)
      setObservationStep(false)
    }
  }, [])

  useEffect(() => {
    if (pos === 2) {
      setOpen(!open)
    }
  }, [pos])


  function closemodal() {
    setOpenModal(false)
  }



  function encerra() {//ENVIA CRIACAO DE REPOSTAS

    var error = false

    Object.values(validaProntuario).map((item) => {
      if (item.error === true) {
        
        item.error = true
        error = true
      }
    })
    
    if (error === false) {
      setLoad(true);
      conexao.post('/form/patient/answer/' + localStorage.getItem('idSend'), { answers: NewAnswerTemp }).then((res) => {
        console.log(res)
        setTypeAlert('success');
        setMessage('Informações salvas com sucesso');
        setTimeout(() => {
          setTypeAlert('');
          setMessage('');
          closemodal();
          window.location.reload()
          setLoad(false);
        }, 2500);
      }).catch((error) => {
        setTypeAlert('erro');
        setMessage('Erro ao salvar informações!');
        setTimeout(() => {
          setTypeAlert('');
          setMessage('');
          closemodal();
          // window.location.reload()
          setLoad(false);
        }, 2500);
      })
    } else {

      setvalidaProntuario({ ...validaProntuario })
      closemodal()

    }
  }

  function enviar() {//EDICAO DE RESPOSTAS
    if (respEdit === true && pos === 2) {
      var NewAnswer = []

      Answer.map((item) => {
        if (item.question_type === 'check') {
             
          if(item.answer){
            NewAnswer.push(item)
          }
        }

        if (item.question_type === 'selecao') {
              
          if(item.answer){
            NewAnswer.push(item)
          }
        }

        if (item.question_type === 'osteopatiapediatrica') {
          item.answer.map((a) => {

            if (a.form_step_question_option_id) {//importante colocado para quando o cliente nao alterar nada e mesmo assim editar o prontuario

              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.form_step_question_option_id, answer: a.text })

            } else {
              console.log('DENTRO OSTEOPATIA PEDIATRIACA', item.answer)
              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.id, answer: a.value })
            }

          })

        }



        if (item.question_type === 'desenvolvimentomotor') {
          item.answer.map((a) => {

            if (a.form_step_question_option_id) {//importante colocado para quando o cliente nao alterar nada e mesmo assim editar o prontuario

              console.log('DENTRO DESENVOLVIMENTO MOTOR', item.answer)

              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.form_step_question_option_id, answer: a.text })

            } else {
              console.log('DENTRO DESENVOLVIMENTO MOTOR', item.answer)
              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.id, answer: a.value })
            }

          })

        }

        if (item.question_type === 'curvadecrescimento') {
          item.answer.map((a) => {

            if (a.form_step_question_option_id) {//importante colocado para quando o cliente nao alterar nada e mesmo assim editar o prontuario

              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.form_step_question_option_id, answer: a.text })

            } else {

              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.id, answer: a.value })
            }

          })

        }


        if (item.question_type === 'odontologico') {
          item.answer.map((a) => {

            if (a.form_step_question_option_id) {//importante colocado para quando o cliente nao alterar nada e mesmo assim editar o prontuario

              console.log('DENTRO ODONTOLOGICO', item.answer)

              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.form_step_question_option_id, answer: a.text })

            } else {
              console.log('DENTRO ODONTOLOGICO', item.answer)
              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.id, answer: a.answer })
            }

          })

        }


        if (item.question_type === 'musculoesqueletico' || item.question_type === 'visceral' || item.question_type === 'cranianovistaanterior' || item.question_type === 'cranianovistaposterior' || item.question_type === 'cranianovistalateraldireita') {
          item.answer.map((a) => {

            if (a.form_step_question_option_id) {//importante colocado para quando o cliente nao alterar nada e mesmo assim editar o prontuario

              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.form_step_question_option_id, answer: a.text })

            } else {

              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.id, answer: a.escala })
            }
          })

        }
        if (item.question_type === 'textocurto' || item.question_type === 'textolongo' || item.question_type === 'data' || item.question_type === 'numerico' || item.question_type === 'telefone' || item.question_type === 'email' || item.question_type === 'label' || item.question_type === 'image') {
          NewAnswer.push(item)
        }

      })

      let error = false

      Object.values(validaProntuario).map((item) => {
        if (item.error === true) {
          error = true
        }
      })


      console.log( 'NEW ANSWER👀:', NewAnswer)
      if (error === false) {
        conexao.put('/form/patient/answer/update/' + localStorage.getItem('idSend'), { answers: NewAnswer })
          .then((res) => {
            setPos(0)
            PosProntuario(0)
          })
      } else {
        setvalidaProntuario({ ...validaProntuario })
      }
    }


    if (pos === 2 && respEdit === false) {//CRIACAO DE RESPOSTAS
      var NewAnswer = []
      Answer.map((item) => {
        
        if (item.question_type === 'check') {
             
            if(item.answer){
              NewAnswer.push(item)
            }
        }

        if (item.question_type === 'selecao') {
             
          if(item.answer){
            NewAnswer.push(item)
          }
      }

        if (item.question_type === 'osteopatiapediatrica') {
          item.answer.map((a) => {
            NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.id, answer: a.value })
          })
        }

        if (item.question_type === 'desenvolvimentomotor') {
          item.answer.map((a) => {
            NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.id, answer: a.value })
          })
        }

        if (item.question_type === 'curvadecrescimento') {
          item.answer.map((a) => {
            NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.id, answer: a.value })
          })
        }

        if (item.question_type === 'odontologico') {
          item.answer.map((a) => {
            NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.id, answer: a.answer })
          })
        }

        if (item.question_type === 'musculoesqueletico' || item.question_type === 'visceral' || item.question_type === 'cranianovistainferior' || item.question_type === 'cranianovistaanterior' || item.question_type === 'cranianovistaposterior' || item.question_type === 'cranianovistalateraldireita') {
          item.answer.map((a) => {
            NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.id, answer: a.escala })
          })
        }

        if (item.question_type === 'textocurto' || item.question_type === 'textolongo' || item.question_type === 'data' || item.question_type === 'numerico' || item.question_type === 'telefone' || item.question_type === 'email' || item.question_type === 'label' || item.question_type === 'image' ) {
          NewAnswer.push(item)
        }
      })

      setTimeout(() => {
        let hasValidationError = Object.values(validaProntuario).some((item) => item.error === true)

        if (!hasValidationError) {
          setAnswer(NewAnswer)
        }
      }, 3000)

      

      setNewAnswerTemp(NewAnswer)
      

      setOpenModal(true)
      setModalType('finish')
      setModalInfos({
        title: 'Clinic Plus - Atendimentos',
        description: 'Deseja salvar os dados do atendimento?'
      })

      if (localStorage.getItem('Location') !== 'agenda') {
        localStorage.removeItem('agendamentoId');
      }
    }

    if (pos !== 2) {
      setPos(1)

    } else {
      setOpen(!open)
    }

  }

  function ocultarDuracaof() {
    setOcultarDuracao(!ocultarDuracao)
  }

  function back() {
    if (!respEdit) {
      var count = 0

      Answer.map((item) => {
        if (item.answer == '' || item.answer == null || item.answer.length < 1 ) count = count + 1
      })
      
      if (count > 0) conexao.delete('/form/patient/' + localStorage.getItem('idSend'))
    } 

    setrespEdit(false)
    setRespView(false)

    setPos(0)
    setPosProntuario(0)
    setOpenModal(false)
    setObservationStep(false)
  }
  
  return (
    <div className="">
      <ThemeProvider theme={theme}>
        <div className={classes.envolveProntuario}>
          <div>
            <h1 className={classes.titleProntuario}>Prontuários</h1>
            {
              respView === false &&
              localStorageLocation === 'agenda' &&

              <Button 
                className={clsx(classes.btnInicarAtendimento,'color-primary')} 
                disabled={pos === 1 ? true : false} 
                variant="contained" 
                color={pos === 2 && respEdit === false ? 'primary' : 'primary'} 
                onClick={() => { enviar() }}
              >
                {pos === 0 && <> <PlayArrowIcon />Iniciar atendimento</>}

                {pos === 1 && 'Seleção de prontuario'}

                {pos === 2 && respEdit === false && <><StopIcon />Finalizar Atendimento</>}

                {pos === 2 && respEdit === true && <><SaveIcon /> Salvar</>}
              </Button>
            }

            {pos === 2 &&
              <Button 
                className={clsx(classes.btnInicarAtendimento,"color-secondary")} 
                variant="contained" 
                color='secondary' 
                onClick={() => {
                  setOpenModal(true)
                  setModalType('back')
                  setModalInfos({
                    title: 'Clinic Plus - Atendimentos',
                    description: 'Tem certeza que deseja sair desse atendimento?'
                  })
                }}
              >
                Voltar
              </Button>
            }
          </div>

          <div className={classes.btnDuracao}>
            {pos === 2 && respEdit === false ?
              <>
                <Button color="default" onClick={ocultarDuracaof}>
                  <QueryBuilderIcon /> {!ocultarDuracao? 'Ocultar duração':'Exibir duração'}
                </Button>
                {!ocultarDuracao &&
                  <Cronometro op={open} />
                }
              </>
              : ''}
          </div>
          <div>
            {pos === 1 && 
              <div className={classes.divSecao} onClick={() => { setPos(0); setPosProntuario(0) }}>
                <h2 className={classes.titleSecao}>Resumo</h2>
              </div>
            }

            {pos === 2 &&
              MontagemProntuario.map((item, index) => {
                return (
                  <div onClick={() => {
                    setPosProntuario(index)
                    setObservationStep(false)
                  }}>
                    <SecaoLateral data={item} />
                  </div>
                )
              })
            }

            {respEdit && 
              <div style={{ boxShadow: '0px 6px 10px 0px #0000000F' }} className={classes.divSecao}>
                <h2 className={classes.titleSecao} onClick={() => setObservationStep(true)}>Observações</h2>
              </div>
            }
          </div>
        </div>
      </ThemeProvider>

      <Modal 
        desc={modalInfos.description} 
        titulo={modalInfos.title} 
        typeAlert={typeAlert} 
        message={message} 
        load={load} 
        encerra={modalType === 'finish' ? encerra :  back} 
        open={openModal} 
        close={closemodal} 
      />
    </div>
  );
}