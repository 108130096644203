import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import conexao from '../../../../../Services/api';
import Button from '@material-ui/core/Button';
import { StateGlobal } from '../../../../../ContextAPI/context'
import LaunchIcon from '@material-ui/icons/Launch';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import '../css/inputs.css';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  elipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '& label': {
      width: '100px'
    }
  }
}));
 

export default function InputImagem({id ,title ,required, answer, imageID}) {
  const classe = useStyles();

  const [value, setValue] = React.useState('1');
  const [checked, setcheck] = useState(false)
  const [titulocampo , setTituloCampo] =useState();
  const [label, setLabel] = useState("Selecione uma imagem");
  const [imgUrl, setImgUrl] = useState('');
  const [imgName, setImgName] = useState('')
  const [req, setReq] = useState(required);
  const [error, setError] = useState(false)

  const { Answer, setAnswer, respEdit, setMontagemProntuario, MontagemProntuario, PosProntuario, setpos, respView, validaProntuario, setvalidaProntuario, validation, setValidation,attValidation, setAttValidation, attEncerraError } = StateGlobal();

 
  useEffect(()=>{

    Answer.map((item, index)=>{
      if(item.question_id === id){
        item.answer = imgUrl

    
            if(req){
              if( !Answer[index].answer ){
                setError(true)
              }
              else{
                setError(false)
            }
            }
    
        
      }
    })
    
  }, [imgUrl])

  useEffect(()=>{
    if(answer){
      if(answer[0]){
        setImgUrl(answer[0].image)
        setLabel(answer[0].image)
      }else{
        setError(false)
      }
      Answer.map((item)=>{
        if(item.question_id === id){
          item.answer = imgUrl
        }
      })
    }
  
  }, [id])

  useEffect(()=>{
    validacaoOnchange()
  },[imgUrl])


  function validacaoOnchange() {
    if (!required) {
      return  
    }
  
    Answer.map((item) => {
      if (item.question_id === id) {  
        validaProntuario[id].error = !item.answer && validaProntuario[id].started
        validaProntuario[id].value = item.answer ? 'ok' : ''
        validaProntuario[id].started = true
  
        setvalidaProntuario({...validaProntuario})
      }
    })
  }



  function onClickImg(e){
    if(e.target.files[0].size > 10000000){
      alert("Documento maior que 10MB, por favor verifique o tamanho do arquivo.");
      return;
    };

    let fileName = e.target.files[0].name
    let fileType = e.target.files[0].type

    if(document.getElementById(imageID).files[0]){
      const t = document.getElementById(imageID).files[0].name
      setImgName(t)
      setLabel("")


      var file = document.getElementById(imageID).files[0];

      var reader = new FileReader();

      reader.onloadend = function(e) {
        enviaImagem(e.target.result, fileName, fileType)
      }

      reader.readAsDataURL(file);
    }
  }

  function enviaImagem(img, ...file){
    conexao.post('upload/hot', {
      name: file[0],
      type: file[1],
      blob: img
    })
    .then(res => {
      setImgUrl(`${process.env.REACT_APP_STORAGE_URL}/storage/${res.data.data}`)
    })
    .catch(error => {
      console.log(error)
    })
  }

  function deletar(){
    setImgUrl(''); 
    setLabel('Selecione uma imagem'); 
    setImgName('')
    document.getElementById(imageID).value = ''
  }

     
   return (
       <div className="envolve-inputImagem">
        <p className="titulo-form">{title}</p>
        <div className="img-input img-input-pront">


          <div className="image-content-pront">
            <TextField 
              disabled 
              id="imagem" 
              name='image' 
              value={imgName} 
              label={label} 
              type="end-ellipsis"
              variant="outlined" 
              className={"img-input-pront", error ? 'errorBorder' : ''}
              classes={{
                root: classe.elipsis
              }}
              
            />
            {
              !respEdit &&
              <label 
                className={"upload upload-pront"} 
                for={imgUrl ? '': imageID} 
              >
                Selecionar Imagem
              </label>
            }
            <input disabled={respEdit} id={imageID} type='file' onChange={onClickImg} ></input>
          </div>
          { imgUrl && respEdit ? 
          <div className="visualizar-imagem-pront">
              <LaunchIcon/>
              <small><a href={imgUrl} rel="noreferrer">Visualizar documento enviado</a></small>
              {!respEdit ? 
              <Button variant="contained"  className="color-primary btn-delete-img" onClick={()=>{deletar()}}>
                  <DeleteOutlineOutlinedIcon/>
              </Button> 
              : ''}
          </div>
          : ''
          }

         </div>
         <div style={{ color: 'red', width: '100%', marginTop: '0px', fontSize: '15px' }}>
          {!respView && error === true ? '* Preencha o campo' : ''}  
        </div>
     </div>  
  )
  }