import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { StateGlobal } from '../../ContextAPI/context'
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularLoad from '../../Components/Load';
import conexao from '../../Services/api';
import ModalExcluir from '../../Components/Modais/ModalExclusao';
import PersonIcon from '@material-ui/icons/Person';
import './Listagem.css';
import { cpf, data } from '../../Components/ReduxTeste/Validate'
import Modal from './Modal'
import AccordionPaciente from './AccordionPaciente'
import Paginacao from './Paginacao';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    border: 'none',
    marginBottom: '2rem'
  },
  search: {
    background: '#f1f1f1',
    boxShadow: '0px 1px 10px rgba(25, 32, 56, 0.08)',
    borderRadius: '3px',
    height: '45px',
    fontSize: '15px',
    border: 'none',
    padding: '15px',


  },
  iconSearch: {
    color: '#8F8F8F'
  },
  acordeon:{
    paddingBottom: '5rem'  
  },
  pag:{
    display: 'flex',
    justifyContent:'center',
   
  }
 
}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    },
    
  },
}, ptBR);


export default function CardModelosProntos() {
  const classes = useStyles();
  const History = useHistory();
  const [pacientes, setPacientes] = useState([]);
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [load, setLoad] = useState(true);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [buscacpf, setBuscaCpf] = useState('');
  const [titulo, setTitulo] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [modeloId, setModeloId] = React.useState('');
  const [optionDelete, setOptionDelete] = React.useState('');
  const [dataarr, setDataarr] = useState([]);
  const [open, setOpen] = useState(false);
  const [iddelete, setIddelete] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [atualPage, setAtualPage] = useState(1);
  const [search, setSearch] = useState('')

  const { setLocation } = StateGlobal()

  const [control, setControl] = useState()

  const {

    isEditPatient,
    setIsEditPatient,
    ViewPatient,
    setViewPatient,
    setPacienteName 
  } = StateGlobal();


  useEffect(() => {
    getallPacients();
    setViewPatient(false)

  }, [])

  useEffect(()=>{
    if(!search){
    getallPacients()
    }else{
      buscaApi()
    }
  }, [atualPage])
  
  useEffect(() => {
    if(search===''){
      buscaApi()
    }

  },[search]);

  function close() {
    setOpen(false)
  }

  function getallPacients() {
    setLoad(true)
    const doctorId = localStorage.getItem('doctorId')
    conexao.post(`patient/filter/${doctorId}?page=${atualPage}`)
    .then((res) => {

      setPacientes(res.data.data.data)
      setDataarr(res.data.data.data)
      setTotalPages(res.data.data.last_page)
      setLoad(false)
      if(res.data.data.data.length<1){
        setMessage('Nenhum resultado encontrado')
        setTypeAlert('error')
      }
    }).catch((error) => {
      setMessage('Nenhum resultado encontrado')
      setTypeAlert('error')
      setLoad(false)
    })
  }

  function busca(event) {
    if (event.key === 'Enter') {
      buscaApi()
    } if (event._reactName === 'onClick') {
      buscaApi()
    }
    return true
  }

  function buscaApi() {
    setLoad(true)
    const doctorId = localStorage.getItem('doctorId')
    conexao.post(`/patient/filter/${doctorId}?page=${atualPage}`, {
      "name": search,
     })
    .then((res)=>{
      
      setPacientes(res.data.data.data)
      setDataarr(res.data.data.data)
      setTotalPages(res.data.data.last_page)
        
    }).catch((error)=>{
      console.log(error)
      
    })
    setLoad(false)
  }



  function deleteuser() {
    conexao.delete('/patient/' + iddelete).then((res) => {

      getallPacients()

    }).catch((error) => {

    })

  }
  function editar(id, name){
    setPacienteName(name)
    localStorage.setItem('patientID', id)
    setIsEditPatient(true)
    setLocation('pacientes')
    localStorage.setItem('Location', 'pacientes')
    History.push('/cadastro-paciente')

  }
  function visualizar(id, name){
    setPacienteName(name)
    localStorage.setItem('patientID', id)
    setIsEditPatient(true)
    setViewPatient(true)
    setLocation('pacientes')
    localStorage.setItem('Location', 'pacientes')
    History.push('/cadastro-paciente')
  }
  function prontuario(id, name){
    setPacienteName(name)
    localStorage.setItem('patientID', id)
    setLocation('prontuario')
    localStorage.setItem('Location', 'pacientes')
    History.push('/atendimento')
  }
  function deletar(id){
    setOpen(true)
    setIddelete(id)
  }

  function handlePage(value){
    setAtualPage(value)
   
  }
   

  return (
    <div  className={classes.acordeon}>
        <FormControl className={clsx(classes.form)}>
          <InputBase
            id="outlined-adornment-weight"
            endAdornment={<InputAdornment position="end">{<IconButton><SearchIcon className={classes.iconSearch} onClick={(event) => busca(event)} /></IconButton>}</InputAdornment>}
            labelWidth={0}
            disabled={load ? true : false}
            onKeyDown={busca}
            onChange={(e) => {setSearch(e.target.value)}}
            placeholder="Busque por um paciente"
            className={clsx(classes.search)}
          />
        </FormControl>
        <div>
            {message ?
              <div className="Modal-agendamento-alert">
                <Alert variant="filled" severity={typeAlert}>
                  {message}
                </Alert>
              </div>
              : ''}
          </div>
      <div >
      {load ? <div className="div-load-lista-modelos"><CircularLoad /></div>
      :
        dataarr ? 
        dataarr.map((row) => {

          return <AccordionPaciente arr={row} editar={editar} prontuario={prontuario} deletar={deletar}/>
        
        }):'Nenhum registro encontrado'
      
      }
        
      </div>
      <div className={classes.pag}>
      {!load ? 
        <Paginacao handlePage={handlePage} totalPages={totalPages} atualPage={atualPage} /> 
        : ''
      }  
      </div>
      <Modal open={open} titulo={'Excluir Paciente'} desc={'Tem certeza que deseja excluir este paciente ?'} close={close} delete={deleteuser} />
    </div>
  );
}