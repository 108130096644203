import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import ModalDetalheAgendamento from './ModalDetalhesAgendamento';
import CloseIcon from '@material-ui/icons/Close';
import '../css/Agenda.css'
import '../Tabela/header/css/header.css'
import { TextareaAutosize } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { StateGlobal } from '../../../ContextAPI/context';
import { currency } from './Validate';
import conexao from '../../../Services/api'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'white',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  SelectProcedimento: {
    width: '100%'
  },
  QuantProce: {
    width: '50%',
    marginLeft: '1rem',
    backgroundColor: 'white',
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
  },
  selectTipoAtendimento: {
    width: '100%'
  },
  input: {
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    backgroundColor: 'white',
  },
  inputTel: {
    width: '50%',
    marginRight: '1rem',
    backgroundColor: 'white',
  },
  paddingButton: {
    paddingRight: '1.5rem'
  },
  inputData: {
    width: '30%',
  },
  label: {
    background: 'white',
    paddingRight: '.4rem'
  },
  modalColor: {
    backgroundColor: '#f9f9f9',
  },

  DadosPaciente: {
    color: '#8F8F8F',
    fontSize: '14px',
    marginTop: "15px",
    display: 'flex',
    flexDirection: 'row'
  },
  ResultGet: {
    color: '#8F8F8F',
    fontSize: '14px',
    fontWeight: 'bold'
  },

  inputquant: {

    width: '60px',
    '& input': {
      padding: '5px',

    }
  }, total: {

    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px',
    marginLeft: '30px',
    color: '#8F8F8F',
    marginRight: '30px',
    '& span':{
      marginRight:'10px'
    }
  },

  select: {
    width: '200px',
    margin: '10px',
    backgroundColor: '#FFFF',
    borderColor: '#8080807a',
    borderRadius: '4px',
    padding: '15px'
  },

  tabelaAgendamento: {
    boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%)',
    color: '#8F8F8F',
  },
  divselect: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '1em',
    margin: '10px',
    fontWeight: 'bold',
    color: '#8F8F8F',
    width:'100%'
  },
  val:{
    whiteSpace:'nowrap'
  },
  divContainerSelect:{
    display:'flex',
    flexDirection: 'column',
    alignItems:'flex-end'
  },
  containerTabelaAgendamento:{
    minHeight: '7rem'
  } 


}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },


  },
}, ptBR);



export default function ModalAddAgendamento(props) {



  const initialvalues = {
  
    payment_type: '',
    payment_status: '',
  }

  const classes = useStyles();
  const [procedimento, setProcedimento] = React.useState('');
  const [tipoAtendimento, setTipoAtendimento] = React.useState('');
  const [frequenciaAtendimento, setFrequenciaAtendimento] = React.useState('');
  const [open, setOpen] = useState(false);
  const [modalDetalhesAgendamento, setModalDetalhesAgendamento] = useState(false);
  const [values, setValues] = useState(initialvalues);
  const [valor, setValor] = useState(1);
  const [qtd, setQtd] = useState();
  const [valorTotal, setValorTotal] = useState();
  const { agendadoData , setAgendadoData} = StateGlobal();
  const { statusTabela, setStatusTabela } = StateGlobal();
  const [error, setError] = useState();
  const [openAlert, setOpenAlert] = useState(false)
 var dados = false
const [arr , setArr] = useState(dados)


  
 
  const openModalDetalhesAgendamento = () => {
    setModalDetalhesAgendamento(true)
  }

  const alteraEstadoModal = (modalDetalhesAgendamento) => {
    setModalDetalhesAgendamento(modalDetalhesAgendamento);
  };



  useEffect(() => {
    setOpen(props.open);
     
  }, [props])

  useEffect(() => {
   
    let total = 0 

    // var arr = agendadoData[0]
    // setDados(arr)
    
 

    
    if(agendadoData[0] !== undefined){
      dados =agendadoData[0]
      setArr(dados)
    }else{
      dados  =agendadoData
      setArr(dados)
    }




   

   dados.items.map((item)=>{
   
     total = (total + (item.value * item.quantity))
    
   })
   total = currency(total.toFixed(2))
   setValorTotal(total)
    // for(var i = 0; i < arr.items.length; i++ ){
    //   let valormutiplicado = arr.items[i].value * arr.items[i].quantity
      
    //   if(i == 0){
    //     total = valormutiplicado
    //   }else{
    //     total = total + valormutiplicado
    //   }
    // }

 

    // 
    
    // setValorTotal(total)

  })

  const closeModal = () => {
    setStatusTabela(!statusTabela)
    setOpen(false);
    props.onChange(false)

  }


  function enviaDadosAgendamento(event) {
    event.preventDefault();
    closeModal();
    openModalDetalhesAgendamento();
  }



  function onchange(e) {
    console.log(e.target.value)
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });

  }


  function lancarPagamento(){
    
  
      conexao.put(`/schedule/payment/${arr.id}`,
        values
      ).then((res) => {
 
        setOpenAlert(true)
        setError('success')

      }).catch((error) => {

      
      })
      setTimeout(() => {
        closeModal();
      }, 1500);
      
  }



  return (
    
    <div className="Modal-agendamento">
       <ThemeProvider theme={theme} style={{ border: '6px solid red' }}>
        <Dialog open={open} aria-labelledby="Modal-title-agendamento" className={'dialogteste'} >
          <form id="" className={classes.modalColor} onSubmit={enviaDadosAgendamento} autoComplete="off" >
            <DialogTitle id="Modal-title-agendamento">
              <div className="title-modal-close">
                Lançar Recebimento
            <CloseIcon onClick={closeModal} />
              </div>
              <label style={{ fontSize: '14px' }}>
                {dados.date}
              </label>
              <div>
              </div>
              <div style={{ fontSize: '12px', color: '#8F8F8F' }}>
                 Convênio : {arr.type} 
              </div>
            </DialogTitle>
            <DialogContent>
              {/* <TableContainer className={classes.tabelaAgendamento} component={Paper} style={{ overflow: 'scroll', height: '200px', backgroundColor: '#f9f9f9' }}>
                <Table className={classes.table} size="small" aria-label="a dense table" >
                  <TableHead className={classes.tabelaAgendamento}>
                    <TableRow className={classes.tabelaAgendamento}>
                      <TableCell className={classes.tabelaAgendamento}>procedimento</TableCell>
                      <TableCell className={classes.tabelaAgendamento} align="center">Quantidade</TableCell>
                      <TableCell className={classes.tabelaAgendamento} align="center">Valor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {stateRows.map((row, index) => (
                      <TableRow key={row.procedimento}>
                        <TableCell className={classes.tabelaAgendamento} component="th" scope="row">
                          {row.procedimento}
                        </TableCell>
                        <TableCell className={classes.tabelaAgendamento} align="center">
                          <TextField
                            className={classes.inputquant}
                            id="outlined-number"
                            type="number"
                            value={stateRows[index].quant}
                            variant="outlined"
                            onChange={(e) => {
                              if (e.target.value >= 1) {
                        
                                stateRows[index].quant = e.target.value
                     
                                const arr = [...stateRows]
                           
                                setStateRows(arr)
                              }

                            }}
                          />
                        </TableCell>
                        <TableCell className={classes.tabelaAgendamento} align="center">{row.valor}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> */}

              <TableContainer className={classes.tabelaAgendamento, classes.containerTabelaAgendamento} component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead className={classes.tabelaAgendamento}>
                      <TableRow className={classes.tabelaAgendamento}>
                        <TableCell className={classes.tabelaAgendamento}>Procedimento</TableCell>
                        <TableCell className={classes.tabelaAgendamento} align="center">Quantidade</TableCell>
                        <TableCell className={classes.tabelaAgendamento} align="center">Valor</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {arr ?

                        arr.items.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell className={classes.tabelaAgendamento} component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell className={classes.tabelaAgendamento} align="center">{row.quantity}</TableCell>
                            <TableCell className={classes.tabelaAgendamento, classes.val} align="center">R$ { currency(row.value * row.quantity)}</TableCell>
                          </TableRow>
                        ))
                        : ''} 
                    </TableBody>
                  </Table>
                </TableContainer>

              <div>
                <div className={classes.total}>
                  <span>Total</span>  <span>R${valorTotal}</span>
                </div>
              </div>


              <div className={classes.divContainerSelect}>

                <div className={classes.divselect}>
                  <div> Tipo de <br /> Pagamento: </div>
                  <select
                    style={{
                      width: '200px',
                      margin: '10px',
                      backgroundColor: '#FFFF',
                      borderColor: '#8080807a',
                      borderRadius: '4px',
                      padding: '15px',
                    }}
                    onChange={(e) => onchange(e)}
                    name="payment_type"
                    inputProps={{
                      id: 'age-native-required',
                    }}
                  >
                    <option value="" disabled selected>...</option>
                    <option value={'Dinheiro'}>Dinheiro</option>
                    <option value={'Cartao de Debito'}>Cartão de Débito</option>
                    <option value={'Cartao de Credito'}>Cartão de Crédito</option>
                    <option value={'Cheque'}>Cheque</option>
                    <option value={'PIX'}>PIX</option>
                    <option value={'Boleto Bancario'}>Boleto Bancário</option>
                    <option value={'Convenio'}>Convênio</option>
                  </select>
                </div>

                <div className={classes.divselect}>
                  Status: 
                  <select
                    style={{
                      width: '200px',
                      margin: '10px',
                      backgroundColor: '#FFFF',
                      borderColor: '#8080807a',
                      borderRadius: '4px',
                      padding: '15px',
                    }}
                    onChange={onchange}
                    name="payment_status"
                    inputProps={{
                      id: 'age-native-required',
                    }}
                  >
                    <option value="" disabled selected>...</option>
                    <option value={'Pago'}>Pago</option>
                    <option value={'Pendente'}>Pendente</option>
                  </select>
                </div>
              </div>

              { openAlert ? 
              <div> 
                {error == 'error' ?  
                <Alert variant="filled" severity="error">Não foi possível processar o pagamento!</Alert> 
                :
                <Alert variant="filled" severity="success">Recebimento feito com sucesso!</Alert>  
                }

              </div>    : " "       
              }
                    
                    
            </DialogContent>
            <DialogActions className={classes.paddingButton}>
              <Button onClick={closeModal} type="button">
                VOLTAR
          </Button>
              <Button variant="contained" color="primary" className="color-primary" onClick={lancarPagamento}>
                LANÇAR PAGAMENTO
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <ModalDetalheAgendamento open={modalDetalhesAgendamento} onChange={alteraEstadoModal} />
      </ThemeProvider>
     
    </div>
  );
}
