import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import Button from '@material-ui/core/Button';
import { TextField, MenuItem, Select } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import CircularLoad from '../../../../Components/Load';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import conexao from '../../../../Services/api2'
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import './categoria.css'
import { currency, currencyinput } from '../../../../Components/ReduxTeste/Validate'
// import ModalExcluir from '../../../Components/Modais/ModalExclusao';
import Modal from '../Modal'
import { campovazio, teste2 } from '../../../../Components/ReduxTeste/ValidateForm'

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    backgroundColor: 'white',
  },
  pConfig: {
    color: '#8F8F8F',
    minWidth: '30%',
    maxWidth: '30%',
    textAlign: 'left',
    wordWrap: 'break-word'
  },
  envolveConfig: {
    borderBottom: '1px solid #DCDCDC',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '1rem',
  },
  envolveConfigs: {
    height: '35vh',
    overflow: 'auto',
    // backgroundColor: 'white',
    marginTop: '1rem',
    marginBottom: '0rem'
  },
  actionsDiv: {
    marginTop: '20px'
  },
  modalColor: {
    backgroundColor: '#f9f9f9',
  },
  clearButton: {
    backgroundColor: '#FFFFFF',
    float: 'right',
    padding: '8px 20px',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
  },
  primaryButton: {
    backgroundColor: '#27b8d2',
    color: '#FFFFFF',
    float: 'right',
    padding: '8px 20px',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
  },
  addButton: {
    backgroundColor: '#27b8d2',
    color: '#FFFFFF',
    float: 'right',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
  },
  borderBottom: {
    borderBottom: '1px solid #dcdcdc'
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
}, ptBR);


export default function ModalCategoria(props) {
  const classes = useStyles();
  const History = useHistory();

  const initialvalues = {
    name: '',
    type: '',
    user_id: (localStorage.getItem('selectedUser') !== 'null' && localStorage.getItem('selectedUser') !== '') ? localStorage.getItem('selectedUser') : localStorage.getItem('userId')
  }


  const [open, setOpen] = useState(props.opencategoria)
  const [values, setValues] = useState(initialvalues)
  const [procedimento, setProcedimento] = useState();
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [itens, setItens] = useState();
  const [procId, setProcId] = useState();
  const [editar, setEditar] = useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [load, setLoad] = useState(false)
  const [idEdit, setIdedit] = React.useState();
  const [idExcluir, setIdexcluir] = React.useState();
  const [openmodalexcluir, setOpenmodalexcluir] = useState(false);
  const [openalert, setOpenalert] = useState({ open: false })
  const [showForm, setShowForm] = useState(false);
  const [validatesx, setvalidatesx] = useState([
    { name: 'name', funcao: campovazio, error: false, value: '' }
    , { name: 'type', funcao: campovazio, error: false, value: '' }]);

  const initialvalidate = [
    { name: 'name', funcao: campovazio, error: false, value: '' }
    , { name: 'type', funcao: campovazio, error: false, value: '' }]

  function onchange(e) {
    const { name, value } = e.target
    validatesx.map((item) => {
      if (item.name === name) {
        item.error = item.funcao(value)
        item.value = value

      }
    })
    const arr = validatesx.slice()
    setvalidatesx(arr)
    setValues({ ...values, [name]: value });
  }

  useEffect(() => {
    setOpen(props.opencategoria)
    getCategories();
  }, [props])

  useEffect(()=>{
    setvalidatesx(initialvalidate)
  },[props.opencategoria])

  const closeModal = () => {
      setOpen(false)
      setShowForm(false)
      props.close()
  }

  function close() {
    setOpenmodalexcluir(false)
  }

  async function getCategories() {
    setItens(props.categoryList)
    // const userId = (parseInt(localStorage.getItem('selectedUser')) ? localStorage.getItem('selectedUser') : localStorage.getItem('userId'))

    // conexao.get('/finance/category/get/' + userId).then((res) => {
    //   setItens(res.data.data)
    // }).catch((error) => {
    //   Alertshow('Não há dados para listar !', 'error')
    // })
  }

  async function save() {
    validatesx.map((item) => {
      if (item.value === '') {
        item.error = true       
      }
    })
    const arr = validatesx.slice()
    setvalidatesx(arr)
    setLoad(true)

    conexao.post('finance/category/create', values).then((res) => {
      setLoad(false)
      Alertshow('Salvo com sucesso!', 'success')
      props.update()
      setShowForm(false)
      setTimeout(() => {
        setValues(initialvalues)
        // closeModal()
        // getCategories()
      }, 3000);

    }).catch((error) => {
      setLoad(false)
      Alertshow('Erro ao salvar!', 'error')
      setTimeout(() => {
        Alertshow('', '')
      }, 3000);
    })
  }

  function update() {
    setLoad(true)
    conexao.put('finance/category/update/' + idEdit, values).then((res) => {

      setLoad(false)
      Alertshow('Editado com sucesso!', 'success')
      props.update()
      setValues(initialvalues)
      setShowForm(false)
    }).catch((error) => {
        setLoad(false)
        Alertshow('Erro ao editar!', 'error')
        setTimeout(() => {
          Alertshow('', '')
        }, 3000);
      })
  }

  function Alertshow(msg, type) {
    setOpenalert({ open: true, msg: msg, type: type })
    setTimeout(() => {
      setOpenalert({ open: false })
    }, 3000);
  }

  function modalDelete(id) {
    setOpenmodalexcluir(true)
    setIdexcluir(id)
  }

  function deletaProcedimento() {
    setLoad(true)
    conexao.delete('/finance/category/' + idExcluir).then((res) => {
      setLoad(false)
      setTypeAlert('success')
      setMessage('Categoria excluída com sucesso!')
      props.update()
      setTimeout(() => {
        //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        setOpenalert({ open: false })
        setOpenmodalexcluir(false)
        // getCategories()
      }, 3000);
      

    }).catch((error) => {
      setLoad(false)
      setTypeAlert('error')
      setMessage('Essa categoria não pode ser excluída, pois existe vinculos financeiros!')
      setTimeout(() => {
        //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        setOpenalert({ open: false })
        // getCategories()
      }, 3000);

    })
  }

  function selectItem(id) {
    setEditar(true);
    setOpenalert({ open: false })
    setShowForm(true)
    itens.map((item) => {
      if (item.id === id) {
        setValues({
            name: item.name,
            type: item.type,
            user_id: item.user_id
          }
        )
      }
      setIdedit(id)
    })
  }

  function addCategory() {
    setValues({
        name: '',
        type: '',
        user_id: (localStorage.getItem('selectedUser') !== 'null' && localStorage.getItem('selectedUser') !== '') ? localStorage.getItem('selectedUser') : localStorage.getItem('userId')
      }
    )
    setShowForm(true)
  }

  useEffect(() => {
  }, [])



  return (
    <div className="Modal-agendamento">
      <ThemeProvider theme={theme} >
        <Dialog open={open}  aria-labelledby="Modal-title-agendamento">
          <DialogTitle id="Modal-title-agendamento" className={classes.modalColor}>
            <div className="title-modal-close">
              Categorias
              <CloseIcon onClick={closeModal} />
            </div>
          </DialogTitle>
          {openalert.open ?
          <DialogContent className={classes.modalColor}>
              <Alert variant="filled" severity={openalert.type}>
                {openalert.msg}
              </Alert>
          </DialogContent>
          : ''}
          {showForm ?
            <form id="" autoComplete="off" className={[classes.modalColor, classes.borderBottom].join(' ')}>

              <DialogContent>
                <div> 
                  <TextField
                    InputLabelProps={procedimento ? { shrink: true } : ''}
                    className={classes.input}
                    name='name'
                    value={values.name}
                    onChange={(e) => {
                      onchange(e)
                    }}
                    type="text"
                    error={validatesx[0].error}
                    helperText={validatesx[0].error ? 'esse campo precisa ser preenchido' : ''}
                    required
                    id=""
                    label="Nome da categoria"
                    variant="outlined"
                  />

                  <Select
                    className={classes.input}
                    value={values.type}
                    onChange={(e) => {
                      onchange(e)
                    }}
                    label="Tipo da categoria"
                    variant="outlined"
                    name='type'
                    error={validatesx[1].error}
                  >
                    <MenuItem key={1} value={'D'}>Pagamento</MenuItem>
                    <MenuItem key={2} value={'C'}>Recebimento</MenuItem>
                  </Select>
                </div>
                <DialogActions className={classes.actionsDiv}>
                  <Button onClick={() => setShowForm(false)} type="button" className={classes.clearButton}>Cancelar</Button>
                  {editar ?
                    <Button onClick={() => { update() }} variant="contained" color="primary" className={classes.primaryButton}>
                      {!load ? 'Salvar' : <CircularLoad/>}
                    </Button>
                    :
                    <Button onClick={() => { save() }} variant="contained" color="primary" className={classes.primaryButton}>
                      {!load ? 'Incluir' : <CircularLoad/>}
                    </Button>
                  }
                </DialogActions>
              </DialogContent>
            </form>
            : 
              <div>
                <DialogActions className={classes.modalColor}>
                  <Button onClick={() => addCategory()} className={classes.addButton}>
                    <AddIcon />
                  </Button>
                </DialogActions>
                <DialogContent className={classes.modalColor}>
                  <div className={classes.envolveConfigs}>
                    {itens ?
                      itens.map((item) => (
                        <div key={item.id} className={classes.envolveConfig}>
                          <p className={classes.pConfig}>{item.name}</p>
                          <p className={classes.pConfig}>{item.type === 'D' ? 'Pagamento' : 'Recebimento'}</p>
                          <div>
                            <IconButton onClick={() => selectItem(item.id)} aria-label="edit" className={classes.margin}>
                              <EditIcon />
                            </IconButton>
                            <IconButton disabled={item.default == 1} onClick={() => modalDelete(item.id)} aria-label="delete" className={classes.margin}>
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </div>
                      ))
                      : ''}
                  </div>
                </DialogContent>
              </div>
            }

            <DialogActions className={classes.modalColor}>
              <Button onClick={closeModal} type="button" className={classes.clearButton}>Fechar</Button>
            </DialogActions>
        </Dialog>
        <Modal open={openmodalexcluir} close={close} titulo={'Excluir categoria'} typeAlert={typeAlert} message={message} load={load} desc={'Tem certeza que deseja excluir essa categoria ?'} delete={deletaProcedimento} />

      </ThemeProvider>
    </div>
  );
}