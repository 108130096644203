import React, {useState, useEffect} from 'react';
import AddIcon from '@material-ui/icons/Add';
import ShortTextIcon from '@material-ui/icons/ShortText';
import IconButton from '@material-ui/core/IconButton';
import '../../../css/NovoModelo.css';
import { StateGlobal } from '../../../../../../ContextAPI/context'
import CardTextoCurto from '../../../SecaoInput/CardTextoCurto'
import TodayIcon from '@material-ui/icons/Today';
import CardData from '../../../SecaoInput/Components/CardData'

export default function ButtonsFerramentas({index ,id}) {

    const {    
        montagemstate,
        setMontagem,
        att  ,
        setAtt,
    idComponentAtual} = StateGlobal();

      
        var array = []


        function getdata(e) {
     
        }
        
        const teste = 'CardTextoCurto'

        //array.push({ component: <CardTextoCurto getData={getdata} testid={testid} tempId={id}/> })

  return (
            <div className="NovoModelo-Ferramentas">
               <div className="circle-icon">
                   <TodayIcon/>
               </div>
                <p className="titulo-ferramenta-btn">Data</p>
               <div onClick={()=>{

                array  = montagemstate
                array.push({ type: "data", component: <CardData getData={getdata}  tempId={id}/> , tempId : id , title : ''  , required : false, idsessao:idComponentAtual })               
               
                setMontagem(array)

                setAtt(!att)
                 


               }} className="envolve-btn-add-ferramentas">
                   <IconButton className="btn-add-ferramenta">
                       <AddIcon/>
                   </IconButton>
               </div>
            </div>
  );
}