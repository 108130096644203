import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {celular, telefonefixo} from '../../../Agenda/Modais/Validate'

export default function Aniversariantes(props) {
  const [aniversario, setAniversario] = useState([]);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setAniversario(props.dados)
    console.log('opa', aniversario)

  }, [props])

  function handleModal(value){
    setOpen(value)
}

  return (
    <div className="aniversariantes-dashboard">
      {aniversario ?
          <div className="aniversariantes-dashboard">
            <div>
            <AvatarGroup max={4}>
              {aniversario.map((item) => (
                <Avatar alt={item.name} src={item.avatar} />
              ))}
            </AvatarGroup>
            </div>
            <div>
              {aniversario[0] ?
                <p>
                  {aniversario[0].name} {aniversario[1] ? 'e outros':''}
                </p>
              : ''}
            </div>
            <div>
                <Button
                  type="submit"
                  variant="contained"
                  className="color-secondary"
                  onClick={()=>handleModal(true)}
                  color="secondary">
                  Ver a lista completa
                </Button>
            </div>
              <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Aniversáriantes do mês</DialogTitle>
                    <DialogContent>
                    <div>
                    {aniversario.map((item) => (
                      <div className="modal-aniversariante">
                        <div className="modal-aniversariante-img-p">
                          <Avatar className="margin-rigth-p" alt={item.name} src={item.avatar} />
                          <p className="margin-rigth-p"><strong>{item.name}</strong></p>
                        </div>
                          <p className="margin-rigth-p"><strong>Celular:</strong> {item.cellphone?celular(item.cellphone):'Não possui'}</p>
                          <p><strong>Telefone:</strong> {item.phone?telefonefixo(item.phone):'Não possui'}</p>
                      </div>
                    ))}
                    </div>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>handleModal(false)} color="default">
                        Fechar
                    </Button>
                    </DialogActions>
                </Dialog>
          </div>
      : 
        <p>Não possui aniversriantes</p>
      }
    </div>
  );
}