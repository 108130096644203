import React, {useState, useEffect} from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import CadatroLateralEsquerdo from './CadastroLateralEsquerdo'
import Cadastrar from './Cadastrar';
import Voltar from '../../Components/Voltar'

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  colorPaperWhite:{
    backgroundColor: "#fff",
    boxShadow: "0px 0px 0px 0px gray",
    padding: '2rem'
  },
  container:{
    marginBottom: '7rem',
  }

}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    }
  },
}, ptBR);

export default function ResumoPaciente() {
  const classes = useStyles();
 

  return (
    <div className="">
      <Voltar Titulo={'Voltar'}/>
     <Grid container>
     {/* <Grid item xs={3}>
        <Paper className={clsx(classes.paper, classes.colorPaperWhite)}>
            <CadatroLateralEsquerdo/>
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        
        <Paper className={clsx(classes.paper, classes.colorPaper)}>
          
           <Cadastrar/>
         
        </Paper>
     
      </Grid>
    </Grid>
  </div>
  );
}