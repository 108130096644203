import React, {useState, useEffect} from 'react';
import { ptBR } from '@material-ui/core/locale';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularLoad from '../../../Components/Load';

import conexao from "../../../Services/api"

import '../css/Agenda.css'

import { StateGlobal} from '../../../ContextAPI/context'


const useStyles = makeStyles((theme) => ({

  divDados:{
    display: 'flex',
    alignItems: 'center',

  },
  description:{
      color:'#8F8F8F',
  },
  btnsFooter:{
    color: '#8F8F8F',
    backgroundColor: 'white',
  },
  btnFooter:{
    color: '#8F8F8F',
    cursor: 'pointer'
  },
  iconBtn:{
    marginRight: '.25rem'
  },
  dadosConsulta:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  modalColor:{
    backgroundColor: '#f9f9f9',
  },
  iconClock: {
      fontSize: '10em',
      color:'#bdbdbd',
      marginRight: '20px'
  },
  dadosConsultaH2:{
    color: '#27b8d2',
    fontSize: '1.1em',
  },

  

}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
}, ptBR);



export default function ModalDetalhesAgendamento(props) {
  const classes = useStyles();
  const [openExcluir, setOpenExcluir] = useState(false);
  const [dados, setDados] = useState(false);
  const [load, setLoad] = useState(false);

  const { listaBloqueado} = StateGlobal();
  const { agendadoData } = StateGlobal();
  const { statusTabela, setStatusTabela } = StateGlobal();


  

  useEffect(() => {
    setOpenExcluir(props.openExcluir);
    setStatusTabela(true)

  }, [props])

  useEffect(() => {
    if(listaBloqueado){
      setDados(listaBloqueado[0])
    }

  
  }, [dados])
 

  const closeModalExcluir= () => {
    setOpenExcluir(false);
    props.onChange(false)
   
  }
  

  async function deletar(){
    setLoad(true)
    if(props.tipoAgendamento === 'bloqueado'){

      const id = listaBloqueado[0].id
  
      await requisicaoExcluir(id)
  await setTimeout(() => {
       closeModalExcluir()
      setLoad(false)
    }, 900);
    }else{
      setLoad(true)
      const id = agendadoData[0].id
    
      await requisicaoExcluir(id)
      await setTimeout(() => {
        closeModalExcluir()
       setLoad(false)
     }, 900);
    
    }

    

  }

  function requisicaoExcluir(id){

    conexao.delete(`/schedule/${id}`).then((resp) =>{

      setStatusTabela(!statusTabela)

     
      }).catch((error)=>{
 
      })


     

  }

  return (
    
    
    <div className="Modal-agendamento">
    <ThemeProvider theme={theme}>
      <Dialog maxWidth='xl' minWidth='xl'  open={openExcluir}  aria-labelledby="Modal-title-agendamento">
        <DialogTitle >
          <div >
            Exclusão de agendamento
          </div>
        </DialogTitle>

      
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja excluir esse agendamento?
          </DialogContentText>
        </DialogContent>
        
        <DialogActions className={classes.paddingButton}>
        <Button color="default" onClick={closeModalExcluir}>
            Cancelar
        </Button>  

        <Button variant="contained" className="color-primary" color="primary" autoFocus onClick={deletar}>
            {!load?'Sim, excluir':<CircularLoad/>}
         </Button>
          
        </DialogActions>
      </Dialog>
      
      </ThemeProvider>
      
      
    </div>
  );
}