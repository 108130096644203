import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'

export default function InputTextoLongo({ title, id, required, edit }) {
  const [textoLongo, setTextoLongo] = useState();
  //const [IDcomp, setIDcomp] = useState(id);
  const { Answer, setAnswer, respEdit, setMontagemProntuario, MontagemProntuario, PosProntuario, setpos, respView, validaProntuario, setvalidaProntuario } = StateGlobal();

  const handleChangeInput = event => {
    const { value } = event.target;
    Answer.map((item, index) => {
      if (item.question_id === id) {

        setTextoLongo(value)
        Answer[index].answer = value
      }

    })
    const array = MontagemProntuario

    array[PosProntuario].montagem.map((item, index) => {
      if (item.tempId === id) {
        item.answer = value
      }

    })

    setMontagemProntuario(array)



  };

  useEffect(() => {
    setTextoLongo(edit)
  }, [id])



  //codigo para validação***************************************
  useEffect(() => {
    validacaoOnchange()
  }, [textoLongo])


  function validacaoOnchange() {
    if (!required) {
      return  
    }
  
    Answer.map((item) => {
      if (item.question_id === id) {  
        validaProntuario[id].error = !item.answer && validaProntuario[id].started
        validaProntuario[id].value = item.answer ? 'ok' : ''
        validaProntuario[id].started = true
  
        setvalidaProntuario({...validaProntuario})
      }
    })
  }
  //codigo para validação***************************************



  return (



    <div>
      <p className="titulo-form">{title}</p>
      {/* {id}{edit} */}
      <TextField
        className="mb-4"
        value={textoLongo}
        onChange={handleChangeInput}
        type="text"
        required={required}
        variant="outlined"
        disabled={respView}
        error={validaProntuario[id].error}
        multiline
        rows={5}
        inputProps={{ maxLength: 800 }}
      />
      <div style={{ color: 'red', width: '100%', marginTop: '10px', fontSize: '15px' }}>
        {!respView && validaProntuario[id].error === true ? validaProntuario[id].message : ''}
      </div>
    </div>




  );
}
