import React, { useState, useEffect } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';
import {TextField , Button }from '@material-ui/core/';
import '../css/NovoModelo.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { StateGlobal } from '../../../../ContextAPI/context'
import CardTextoCurto from '../SecaoInput/CardTextoCurto'
import Alert from '@material-ui/lab/Alert';



const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh",
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '97%',
    },
  },
}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
});

export default function SecaoInput() {

  const {
    montagemstate,
    setMontagem,
    idComponentAtual,
    sessaostate,
    setTituloSessaoAtual,
    setSessaostate,
    att,
    setAtt,
    idEditProntuario


  } = StateGlobal();


  var arrayteste = [
    { id: '2', type: 'mauro44', name: 'mauro' },
    { id: '3', type: 'mauro333', name: 'tes' },
    { id: '4', type: 'mauro123', name: '213' },
  ]



  const classes = useStyles();
  const history = useHistory();
  const [indexatual, setIndexatual] = useState();
  const [array, setArray] = useState([]);
  const [titulo, setTitulo] = useState();
  const [open ,setOpen] = useState(false);


  const [lala, setlala] = useState(arrayteste)




  useEffect(() => {

    setArray(montagemstate)
   

  }, [])


  


  function getdata(e) {

  }

  


  function montagem(result) {//controle array 3
    if (!result.destination) return;

    const items = Array.from(montagemstate); // importante
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setMontagem(items);
  }


  function insertIdatual(index, id) {
  

  }



  const id = ''

  useEffect(()=>{

    sessaostate.map((item)=>{
      if(item.id === idComponentAtual){
        if(item.titulosessao!= ''){
          setTitulo(item.titulosessao)
        }else{
          setTitulo('')
        }
        
      }
    })

  },[idComponentAtual])

 
const alertsuccess =  <Alert variant="filled" severity="success">Informações atualizadas com sucesso</Alert>


function  tituloSimultaneo(e){
  setTituloSessaoAtual(e)
  setTitulo(e)

  sessaostate.map((item,index)=>{
    if(item.id === idComponentAtual){
      sessaostate[index].titulosessao = e            
    }
    const newarray = [...sessaostate]
    setSessaostate(newarray)
    setAtt(!att)
    
  
  })
}


  return (
    <div className="SecaoInput-container">
      <ThemeProvider theme={theme}>
      <form className={classes.root} noValidate autoComplete="off">
      {idComponentAtual}
        <TextField id="tituloSecao" color="primary" InputLabelProps={{ shrink: true }} value={titulo === 'Título da seção prontuário' ?  '' : titulo } onChange={(e)=>{tituloSimultaneo(e.target.value)}}  label="Título da seção" />  <Button onClick={()=>{
          setOpen(true)
           sessaostate.map((item,index)=>{
            if(item.id === idComponentAtual){
    
              sessaostate[index].montagem = montagemstate
              sessaostate[index].titulosessao = titulo
              
            }
            const newarray = [...sessaostate]
            setSessaostate(newarray)
         
        
         //   setTituloSessaoAtual('Título da seção prontuário')
            // var arrteste = sessaostate
            // setSessaostate(arrteste)
            // setAtt(!att)
            setTimeout(() => {
              setOpen(false)
              
            }, 1500);

          })
        }} variant="contained" className="Color-btn-salvarModelo color-primary" color="primary">
                        Salvar e continuar   id de ref: {idComponentAtual}  
                    </Button>
                    {open  ? alertsuccess : ''}

                    {/* {idComponentAtual} */}
      </form>



      <DragDropContext onDragEnd={montagem}>
        <Droppable droppableId="montagemstate">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {montagemstate.map(({ tempId, type, component , idcerto }, index) => {
                var indexatual = index
      
                return (
                  <Draggable key={tempId.toString()} draggableId={tempId.toString()} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}  >

                        <div >
        
                          {component}
          
                     

                        </div>

                      </div>

                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      </ThemeProvider>
    </div>
  );
}