import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import CircularLoad from '../../../Components/Load'
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Avatar from '@material-ui/core/Avatar';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { StateGlobal } from '../../../ContextAPI/context';
import { validaCpfCnpj } from '../../Agenda/Modais/Validate';
import Alert from '@material-ui/lab/Alert';
import conexao from '../../../Services/api';
import '../css/cadastroProfissional.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    marginBottom: '5rem',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    width: '100%',
    marginTop: '.5rem',
    marginBottom: '.5rem'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  btnEditLogo:{
    maxWidth: '2rem',
    height: '2rem',
    minWidth: '2rem',
    borderRadius: '50%',
    position: 'relative',
    marginLeft: '-1.1rem',
    marginTop: '1.3rem'
  },
  iconEditLogo:{
    width: '.8em',
    height: '.8em',
  },
  avatarLogo:{
    width: '3rem',
    height: '3rem'
  },
  envolveLogo:{
    display: 'flex'
  },
  form:{
    '& .MuiTextField-root': {
        marginBottom: '1rem',
        width: '100%',
      },
      width: '100%'
  },
  titleModal:{
    fontSize: '1.4em',
    margin: '0',
    marginTop: '.5rem',
    marginBottom: '.5rem',
    color: '#4a4a4a',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '.5rem',
    },
  },

  divTitleModal: {
    display: 'flex',
    marginTop: '5rem',
    alignItems: 'center',
    marginLeft: '.5rem',
    marginBottom: '1rem',
  },
}));

function FormataCEP(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        format='#####-###'
      />
    );
  }
  FormataCEP.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  function FormataCPF(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        format='###.###.###-##'
      />
    );
  }
  FormataCPF.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  FormataCEP.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  function FormataRG(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        format='##.###.###-##'
      />
    );
  }
  FormataRG.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }


  function FormataCelular(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        format='(##) # ####-####'
      />
    );
  }
  
  FormataCelular.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }
  
  function FormataTelefone(props) {
    const { inputRef, onChange, ...other } = props;
    
    
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        format='(##) ####-####'
      />
    );
  }
  FormataTelefone.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

const theme = createMuiTheme({
    palette: {
        secondary: {
          light: '#E7366A',
          main: '#E7366A',
          dark: '#D93364',
          contrastText: '#fff',
        },
        primary: {
          light: '#27b8d2',
          main: '#27b8d2',
          dark: '#229fb5',
          contrastText: '#fff',
        }
      },
}, ptBR);

export default function EditarCadastroProfissional() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const History = useHistory();
  const [validaCPF, setValidaCPF ] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadFoto, setLoadFoto] = useState(false);
  const [profissao, setProfissao] = useState([]);
  const [especialidade, setEspecialidade] = useState([]);
  const [idProfissao, setIdProfissao] = useState('');
  const [idEspecialidade, setIdEspecialidade] = useState('');
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState('');
  const [profissaoSelecionada, setProfissaoSelecionada] = useState('');
  const [especialidadeSelecionadaIndex, setEspecialidadeSelecionadaIndex] = useState('');
  const [profissaoSelecionadaIndex, setProfissaoSelecionadaIndex] = useState('');
  const [name, setName] = useState('');
  const [fotoPerfil, setFotoPerfil] = useState('');
  const [typeAlert, setTypeAlert] = useState('');
  const [message, setMessage] = useState('');
  const [validaSenha, setValidaSenha] = useState('');
  const {rota} = StateGlobal()
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeSenha = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };


  const [dados, setDados] = useState({
    "name": "",
    "email": "",
    "cpf": "",
    "rg": "",
    "phone": "",
    "cellphone":"",
    "is_whatsapp": true,
    "birthday": "",
    "street": "",
    "neighborhood": "",
    "number": '',
    "city": "",
    "state": "",
    "complement": "",
    "zip_code": "",
    "avatar": "",
    "speciality_id": 1
})

const [validaDados, setValidaDados] = useState({
  "name": false,
  "email": false,
  "cpf": false,
  "rg": false,
  "phone": false,
  "cellphone":false,
  "birthday": false,
  "street": false,
  "neighborhood": false,
  "number": '',
  "city": false,
  "state": false,
  "complement": false,
  "zip_code": false,
  "avatar": false,
  "speciality": false,
  "profissao": false,
})

useEffect(() => {
  getDadosCadastro();
}, [])

async function envia(e){
    e.preventDefault();
    setLoad(true)
    var userId = '';
    if(localStorage.getItem('userId')){
      userId = localStorage.getItem('userId');
      if(!dados.name || !dados.email || !dados.cpf || !dados.rg || !dados.phone || !dados.cellphone || !dados.birthday){
          setValidaDados({...validaDados, 
            'rg': !dados.rg ? true : false,
            'cpf': !dados.cpf ? true : false,
            'name': !dados.name ? true : false,
            'email': !dados.email ? true : false,
            'phone': !dados.phone ? true : false,
            'cellphone': !dados.cellphone ? true : false,
            'birthday': !dados.birthday || dados.birthday =='' ? true : false,
          })
        setLoad(false)
        setMessage('Preencha todos os campos para prosseguir!')
        setTypeAlert('error')
        setExpanded('panel1')
        setTimeout(() => {
          setMessage('')
          setTypeAlert('')
        }, 1500);
      } else if(!idEspecialidade || !idProfissao){
        setValidaDados({...validaDados, 
          'speciality': !idEspecialidade ? true : false,
          'profissao': !idProfissao ? true : false,
        })
        setLoad(false)
        setMessage('Preencha todos os campos para prosseguir!')
        setTypeAlert('error')
        setExpanded('panel2')
        setTimeout(() => {
          setMessage('')
          setTypeAlert('')
        }, 1500);
      }else if(!dados.zip_code || !dados.state || !dados.city || !dados.neighborhood || !dados.number || !dados.street){
        setLoad(false)
        setValidaDados({...validaDados, 
          'zip_code': !dados.zip_code ? true : false,
          'state': !dados.state ? true : false,
          'city': !dados.city ? true : false,
          'neighborhood': !dados.neighborhood ? true : false,
          'number': !dados.number ? true : false,
          'street': !dados.street ? true : false,
        })
        setMessage('Preencha todos os campos para prosseguir!')
        setTypeAlert('error')
        setExpanded('panel3')
        setTimeout(() => {
          setMessage('')
          setTypeAlert('')
        }, 1500);
      }
      else{
        if(validaCPF){
          setValidaDados({...validaDados, 
            'cpf': false 
          })
            await conexao.put(`/doctor/update/${userId}`, dados)
            .then((response) => {
                setLoad(false)
                setValidaDados({...validaDados, 
                  'zip_code':  false,
                  'state':  false,
                  'city':  false,
                  'neighborhood':  false,
                  'number': false,
                  'street': false,
                  'complement': false,
                  'rg': false,
                  'cpf': false,
                  'name': false,
                  'email': false,
                  'phone': false,
                  'cellphone': false,
                  'birthday': false,
                  'speciality': false,
                  'profissao': false,
                })
                setMessage('Dados atualizados com sucesso!')
                setTypeAlert('success')
                setTimeout(() => {
                  setMessage('')
                  setTypeAlert('')
                  window.location.reload();
                }, 1500);
            }).catch((error)=>{
              setLoad(false)
              setMessage('Ocorreu um erro ao atualizar os dados. Por favor verifique os dados preenchidos!')
              setTypeAlert('error')
              setTimeout(() => {
                setMessage('')
                setTypeAlert('')
              }, 1500);
            })
        }else{
          setValidaDados({...validaDados, 
            'cpf': true 
          })
          setLoad(false)
          setMessage('CPF inválido!')
          setTypeAlert('error')
          setExpanded('panel1')
          setTimeout(() => {
            setMessage('')
            setTypeAlert('')
          }, 1500);
        }
      }
    }
}


async function getCep(cep){
    await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
    .then((resp) => {
      setDados({...dados, 
                'street': resp.data.logradouro,
                'state': resp.data.uf,
                'city': resp.data.localidade,
                'neighborhood': resp.data.bairro,
                'complement': resp.data.complemento
              })
              setValidaDados({...validaDados, 
                'zip_code': !dados.zip_code ? true : false,
                'state': !resp.data.uf ? true : false,
                'city': !resp.data.localidade ? true : false,
              })
    }).catch((error)=>{

    })

  }

  async function getDadosCadastro(){
    await conexao.get(`/doctor`)
    .then((resp) => {
    
      var userId = '';
      if(localStorage.getItem('userId')){
        userId = localStorage.getItem('userId');
        var cadastro = resp.data.data;
        for(let i = 0; i < cadastro.length; i++){
          if(userId == cadastro[i].user_id){
            var data = '2000-01-01';
            if(cadastro[i].birthday){
              data = cadastro[i].birthday.split(' ')[0];
            }
            console.log('aaaa', cadastro[i])

            setDados({...dados, 
              'street': cadastro[i].street,
              'state': cadastro[i].state,
              'city': cadastro[i].city,
              'neighborhood': cadastro[i].neighborhood,
              'complement': cadastro[i].complement,
              'name': cadastro[i].user.name,
              'email': cadastro[i].user.email,
              'cpf': cadastro[i].cpf,
              'rg': cadastro[i].rg,
              'phone': cadastro[i].phone,
              'cellphone': cadastro[i].cellphone,
              'birthday': data,
              'zip_code': cadastro[i].zip_code,
              'number': cadastro[i].number,
              'avatar': cadastro[i].avatar
            });
            if(cadastro[i].speciality.speciality_category.name!==''){
            setIdEspecialidade(cadastro[i].speciality.id);
            setName(cadastro[i].user.name);
            setIdProfissao(cadastro[i].speciality.speciality_category.id);
            setEspecialidadeSelecionada(cadastro[i].speciality.name);
            setProfissaoSelecionada(cadastro[i].speciality.speciality_category.name);
            if(cadastro[i].cpf != null){
              setValidaCPF(validaCpfCnpj(cadastro[i].cpf))
            }
            }else{
              getProfissaoCadastro();
            }
          }
        }
      }

    }).catch((error)=>{

    })

  }
  useEffect(() => {
    getProfissaoCadastro();
  }, [profissaoSelecionada,especialidadeSelecionada])

  async function getProfissaoCadastro(){
    await conexao.get(`/speciality/category`)
    .then((resp) => {
      setProfissao(resp.data.data)
      resp.data.data.map((i, index)=>{
        if(i.name===profissaoSelecionada){
          setProfissaoSelecionadaIndex(index);
        }
      })
      })

    conexao.get(`/speciality/${idProfissao}`)
    .then((resp) => {
      setEspecialidade(resp.data.data)
      resp.data.data.map((i, index)=>{
        if(i.name==especialidadeSelecionada){
          setEspecialidadeSelecionadaIndex(index);
        }
      })
    }).catch((error)=>{
    })

  }

  function getBase64(file) {
    
    if(file.size > 10000000){
      alert("Documento maior que 10MB, por favor verifique o tamanho do arquivo.");
      return;
    };

    var reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function () {
      setFotoPerfil(reader.result);
      putFotoPerfil(reader.result)
    };
  }


 
 async function putFotoPerfil(image){
   setLoadFoto(true)
  await conexao.post('image/upload',
    {
      "path": 'doctor',
      "photo": image,
    })
  .then((resp) => {
    if(!resp.data.error){
      setDados({...dados, 
        'avatar': resp.data.data
      });
      setLoadFoto(false)
    }
  }).catch((error)=>{
  })

}

  async function getEspecializacaoCadastro(idProfissao){
    await conexao.get(`/speciality/${idProfissao}`)
    .then((resp) => {
      setEspecialidade(resp.data.data)
    }).catch((error)=>{

    })

  }

  function handleInputChange(event,value){
    setIdProfissao('');
    for(let i = 0; i < profissao.length; i++){
      if(profissao[i].name==value){
        setIdProfissao(profissao[i].id);
        getEspecializacaoCadastro(profissao[i].id);
      }
    }
  }

  
  function handleInputChangeEspecialidade(event,value){
    setIdEspecialidade('');
    setDados({...dados, 
      'speciality_id': ''
    })
    for(let i = 0; i < especialidade.length; i++){
      if(especialidade[i].name==value){
        setIdEspecialidade(especialidade[i].id);
        setDados({...dados, 
          'speciality_id': especialidade[i].id
        })
      }
    }
  }

  function voltar(){
    History.push(rota)
}

async function resetSenha(e){
  e.preventDefault();
  var userId = '';
  if(localStorage.getItem('userId')){
    userId = localStorage.getItem('userId');
  setLoad(true)
  if(!values.password || values.password.length<6){
    setValidaSenha(true)
    setLoad(false)
    setMessage('Ocorreu um erro ao editar a senha. Por favor verifique os dados preenchidos e tente novamente!')
    setTypeAlert('error')
    setTimeout(() => {
      setMessage('')
      setTypeAlert('')
    }, 3500);
  }else{
    setValidaSenha(false)
    await conexao.put(`/doctor/update/password/${userId}`, {"password":values.password})
    .then((response) => {
        setLoad(false)
        setMessage('Senha atualizada com sucesso!')
        setTypeAlert('success')
        setTimeout(() => {
          setMessage('')
          setTypeAlert('')
        }, 3500);
    }).catch((error)=>{
      setLoad(false)
      setMessage('Ocorreu um erro ao editar a senha. Por favor verifique os dados preenchidos e tente novamente!')
      setTypeAlert('error')
      setTimeout(() => {
        setMessage('')
        setTypeAlert('')
      }, 3500);
    })
  }
}
}


  return (
    <div className={classes.root}>
        <div className={classes.divTitleModal}>
            <h1 className={classes.titleModal}><ArrowBackIcon onClick={voltar}/> Meu perfil </h1>
        </div>
    <ThemeProvider theme={theme}>
    {message ?
                      <div className="Modal-agendamento-alert">
                        <Alert variant="filled" severity={typeAlert}>
                          {message}
                        </Alert>
                      </div>
                      : ''}
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Dados Pessoais</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={(e)=>envia(e)} noValidate="false" className={classes.form} autoComplete="off">
            <div className="divCadastrarEditar">
              {dados.birthday?
                    <TextField
                        id="date"
                        label="Data de nascimento"
                        type="date"
                        variant="outlined"
                        color="primary"
                        error={validaDados.birthday ? true:false}
                        helperText={validaDados.birthday ? 'Campo obrigatório' : ''}
                        defaultValue={dados.birthday}
                        onChange={(event)=>setDados({...dados, 'birthday': event.target.value})}
                            InputLabelProps={{
                            shrink: true,
                        }}
                    />
                :''}
                    <TextField value={dados.name} 
                      onChange={(event)=>setDados({...dados, 'name': event.target.value.replace(/[0-9]/g, "")})} 
                      type="text" 
                      id="nome" 
                      label="Nome completo" 
                      variant="outlined" 
                      color="primary" 
                      error={validaDados.name ? true:false}
                      helperText={validaDados.name ? 'Campo obrigatório' : ''}
                      required/>
                    <TextField 
                        type="text" 
                        id="rg" 
                        label="RG" 
                        value={dados.rg}
                        onChange={(event)=>setDados({...dados, 'rg': event.target.value})}
                        variant="outlined" 
                        color="primary" 
                        required
                        InputProps={{
                            inputComponent: FormataRG,
                        }}
                        error={validaDados.rg ? true:false}
                        helperText={validaDados.rg ? 'Campo obrigatório' : ''}
                    />
                    <TextField 
                        type="text" 
                        id="cpf" 
                        label="CPF" 
                        variant="outlined" 
                        value={dados.cpf}
                        onChange={(event)=>setDados({...dados, 'cpf': event.target.value})}
                        onKeyUp={(event) => { setValidaCPF(validaCpfCnpj(event.target.value)) }}
                        color="primary" 
                        required
                        InputProps={{
                            shrink: true,
                            inputComponent: FormataCPF,
                        }}
                        error={validaDados.cpf ? true:false}
                        helperText={validaDados.cpf ? 'Documento inválido' : ''}
                                />
                        <TextField 
                            type="tel" 
                            id="phone" 
                            onChange={(event)=>setDados({...dados, 'phone': event.target.value})} 
                            value={dados.phone} 
                            label="Telefone" 
                            variant="outlined" 
                            color="primary" 
                            // error={validaDados.phone ? true:false}
                            // helperText={validaDados.phone ? 'Campo obrigatório' : ''}
                            InputProps={{
                                shrink: true,
                                inputComponent: FormataTelefone,
                            }}
                            required
                        />
                        <TextField 
                            type="tel" 
                            id="cellphone" 
                            onChange={(event)=>setDados({...dados, 'cellphone': event.target.value})} 
                            value={dados.cellphone} 
                            label="Celular" 
                            variant="outlined" 
                            error={validaDados.cellphone ? true:false}
                            helperText={validaDados.cellphone ? 'Campo obrigatório' : ''}
                            color="primary" 
                            InputProps={{
                                shrink: true,
                                inputComponent: FormataCelular,
                            }}
                            required
                        />
                        <TextField 
                            type="email" 
                            id="email" 
                            onChange={(event)=>setDados({...dados, 'email': event.target.value})} 
                            value={dados.email} 
                            label="E-mail" 
                            variant="outlined" 
                            color="primary" 
                            error={validaDados.email ? true:false}
                            helperText={validaDados.email ? 'Campo obrigatório' : ''}
                            InputProps={{
                                shrink: true,
                            }}
                            required
                        />
                    </div>
                    <div className="div-btn-cadastrar">
                        <Button onClick={voltar}>
                            Cancelar
                        </Button>
                        <Button variant="contained" type="submit" className="btn-salvar-editar-cadastro color-primary" color="primary">
                            {load?<CircularLoad/>:'Salvar'}
                        </Button>
                    </div>
                </form>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Dados Profissionais</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <form onSubmit={(e)=>envia(e)} noValidate="false" className={classes.form} autoComplete="off">
                <div className="divCadastrarEditar">
                    {profissaoSelecionadaIndex !=='' ?
                        <Autocomplete
                            id="profissao"
                            options={profissao}
                            getOptionLabel={(option) => option.name}
                            defaultValue={profissao[profissaoSelecionadaIndex]}
                            onInputChange={ handleInputChange }
            
                            renderInput={(params) => <TextField {...params} error={validaDados.profissao ? true:false}
                            helperText={validaDados.profissao ? 'Campo obrigatório' : ''} label="Profissão" variant="outlined" />}
                        />
                    :''}
                    {especialidadeSelecionadaIndex !=='' ?
                        <Autocomplete
                            id="especialidade"
                            options={especialidade}
                            getOptionLabel={(option) => option.name}
                            defaultValue={especialidade[especialidadeSelecionadaIndex]}
                            onInputChange={ handleInputChangeEspecialidade }
       
                            renderInput={(params) => <TextField {...params} label="Especialidade" error={validaDados.speciality ? true:false}
                            helperText={validaDados.speciality ? 'Campo obrigatório' : ''} variant="outlined" />}
                        />
                    :''}
                </div>
                <div className="div-btn-cadastrar">
                    <Button onClick={voltar}>
                        Cancelar
                    </Button>
                    <Button variant="contained" type="submit" className="btn-salvar-editar-cadastro color-primary" color="primary">
                        {load?<CircularLoad/>:'Salvar'}
                    </Button>
                </div>
            </form>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Dados da Empresa</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={(e)=>envia(e)} noValidate="false" className={classes.form} autoComplete="off">
            <div className="divCadastrarEditar">
              <div className="div-logo-empresa">
                  <p className="p-logo-empresa">Logo da empresa:</p>
                  <div className={classes.envolveLogo}>
                      <Avatar 
                        id="avatar" 
                        className={classes.avatarLogo} 
                        alt={name} 
                        src={dados.avatar} 
                      />

                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        className={clsx(classes.btnEditLogo,"color-primary")}
                      >
                        {loadFoto?<CircularLoad/>:<EditIcon className={classes.iconEditLogo}/>}
                        <input
                          id="inputFileToLoad"
                          type="file"
                          hidden
                          onChange={(event)=>getBase64(event.target.files[0])}
                        />
                      </Button>
                  </div>
              </div>
              <TextField 
                type="tel" 
                id="cep" 
                onChange={(event)=>setDados({...dados, 'zip_code': event.target.value})}
                label="CEP" 
                onKeyUp={(event)=>getCep(event.target.value)}
                variant="outlined" 
                color="primary" 
                error={validaDados.zip_code ? true:false}
                helperText={validaDados.zip_code ? 'Campo obrigatório' : ''}
                required
                value={dados.zip_code}
                InputProps={{
                  inputComponent: FormataCEP,
                  shrink: true
                }}
              />

              <TextField 
                disabled={dados.state?true:false} 
                InputLabelProps={{ shrink: true }} 
                value={dados.state} 
                onChange={(event)=>setDados({...dados, 'state': event.target.value})} 
                type="text" 
                id="uf" 
                label="Estado" 
                variant="outlined" 
                error={validaDados.state ? true:false}
                helperText={validaDados.state ? 'Campo obrigatório' : ''}
                color="primary" 
                required
              />

              <TextField 
                type="text" 
                id="cidade" 
                disabled={dados.city?true:false} 
                InputLabelProps={{ shrink: true }} 
                onChange={(event)=>setDados({...dados, 'city': event.target.value})} 
                value={dados.city} 
                label="Cidade" 
                variant="outlined" 
                error={validaDados.city ? true:false}
                helperText={validaDados.city ? 'Campo obrigatório' : ''}
                color="primary" 
                required
              />

              <TextField 
                type="text" 
                id="rua" 
                InputLabelProps={{ shrink: true }}  
                value={dados.street} 
                onChange={(event)=>setDados({...dados, 'street': event.target.value.replace(/[0-9]/g, "")})} 
                label="Rua" 
                variant="outlined" 
                color="primary" 
                error={validaDados.street ? true:false}
                helperText={validaDados.street ? 'Campo obrigatório' : ''}
                required
              />

              <TextField 
                InputLabelProps={{ shrink: true }} 
                value={dados.neighborhood}  
                onChange={(event)=>setDados({...dados, 'neighborhood': event.target.value.replace(/[0-9]/g, "")})} 
                type="text" 
                id="bairro" 
                label="Bairro" 
                variant="outlined" 
                color="primary" 
                error={validaDados.neighborhood ? true:false}
                helperText={validaDados.neighborhood ? 'Campo obrigatório' : ''}
                required
              />

              <TextField 
                type="number" 
                id="numero" 
                label="Número" 
                onChange={(event)=>setDados({...dados, 'number': event.target.value})}
                InputLabelProps={{ shrink: true }}
                variant="outlined" 
                color="primary" 
                error={validaDados.number ? true:false}
                helperText={validaDados.number ? 'Campo obrigatório' : ''}
                value={dados.number}
                required
              />

              <TextField 
                type="text" 
                id="complemento" 
                value={dados.complement}  
                onChange={(event)=>setDados({...dados, 'complement': event.target.value})} 
                InputLabelProps={{ shrink: true }} 
                label="Complemento" 
                variant="outlined" 
                color="primary"
              />
            </div>

            <div className="div-btn-cadastrar">
                <Button onClick={voltar}>
                  Cancelar
                </Button>

                <Button 
                  variant="contained" 
                  type="submit" 
                  className="btn-salvar-editar-cadastro color-primary" 
                  color="primary"
                >
                  {load?
                    <CircularLoad />
                    :
                    'Salvar'
                  }
                </Button>
            </div>
          </form>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Edição de senha</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={(e)=>resetSenha(e)} noValidate="false" className={classes.form} autoComplete="off">
            <div className="divCadastrarEditar">
            <FormControl
              error={!validaSenha?false:true}
              className="Login-senha-input" variant="outlined">
              <InputLabel htmlFor="senha">Nova senha</InputLabel>
              <OutlinedInput
                className="Login-input-color"
                id="senha"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChangeSenha('password')}
                aria-describedby="component-error-text"
                endAdornment={
                <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={85}
                />
                <FormHelperText id="component-error-text">{validaSenha ? 'Senha inválida' : ''}</FormHelperText>
            </FormControl>
            </div>
            <div className="div-btn-cadastrar">
              <Button onClick={voltar}>
                Cancelar
              </Button>
              <Button variant="contained" type="submit" className="btn-salvar-editar-cadastro color-primary" color="primary">
                {load?<CircularLoad/>:'Salvar'}
              </Button>
            </div>
          </form>
        </AccordionDetails>
      </Accordion>
      </ThemeProvider>
    </div>
  );
}