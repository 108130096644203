

import React, { useState, useEffect } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import ListIcon from '@material-ui/icons/List';
import { BsPlusCircleFill } from 'react-icons/bs'

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import './style.css'


import { StateGlobal } from '../../../../../ContextAPI/context'
import { keys } from '@material-ui/core/styles/createBreakpoints';

import osteopatiapediatrica from '../../../../../Assets/Images/ImagensProntuario/osteopatia_pediatrica.png';

import { TrainRounded } from '@material-ui/icons';




const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '97%',
    },
  },
  formControl: {
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',

    background: '#F9F9F9'
  },
  inputEscala: {
    width: '70%',
  },
  inputs:{
    display:'flex',
    flexDirection:'column'
  }

}));



export default function CardTextoCurto({ title, id, required, edit, options, answer }) {
  const classes = useStyles();
  const history = useHistory();
  const [newname, setNewname] = useState();

  const [checked, setcheck] = useState(false)
  const [titulocampo, setTituloCampo] = useState();
  const [tipo, setTipo] = useState({ id: 595, text: "Molares", value: "18" });
  const [img, setImg] = useState();
  const [escala, setEscala] = useState('')
  const [listagem, setListagem] = useState([])
  const [OptionValues, setOptionValues] = useState(options)




  const { Answer, setAnswer, respEdit, AnswerEdit, setMontagemProntuario, MontagemProntuario, PosProntuario, respView, validaProntuario, setvalidaProntuario } = StateGlobal();




  useEffect(() => {
    var arr = []

    if (Answer.length) {
      if (respEdit) {
        const dados = Answer.find(item => item.question_id === id)
  
        dados?.answer.map((item) => {
          if (item.form_step_question_option_id) {
            arr.push({
              id: item.form_step_question_option_id, 
              value: item.text, 
              text: ChangeOption(item.form_step_question_option_id) 
            })
          }
          
          UpdateAnswer(arr)
        })

        var opa = ReturnAnswer()

        const eae = opa && opa.slice()

        setOptionValues(eae)
      } else {
        setOptionValues(options)
      }
    }
   
  }, [id, Answer])




  function handlerChange(e) {
    const value = e.target.value
    const obj = ChangeOption(value)

    setTipo(obj)

  }
  function handlerChange2(e) {
    const { name, value } = e.target



    OptionValues.map((item) => {
      if (item.text === name) {
        item.value = value
      }


    })

    const arr = OptionValues.slice()
    setOptionValues(arr)

    UpdateAnswer(arr)



  }


  function ChangeOption(tipo) {
    var data = ''
    options.map((item) => {
      console.log(item.id)
      if (item.id === Number(tipo)) {
        data = item
      }
    })
    return data.text
  }


  function UpdateAnswer(arr) {
    Answer.map((item) => {
      if (item.question_id === id) {
        item.answer = arr
      }
    })

    setAnswer(Answer)
  }


  function ReturnAnswer() {
    var teste
    Answer.map((item) => {
      if (item.question_id === id) {
        teste = item.answer
      }
    })

    return teste
  }

  useEffect(() => {
    validacaoOnchange()
  }, [OptionValues])


  function validacaoOnchange() {
    if (!required) {
      return  
    }
  
    Answer.map((item) => {
      if (item.question_id === id) {  
        validaProntuario[id].error = !item.answer && validaProntuario[id].started
        validaProntuario[id].value = item.answer ? 'ok' : ''
        validaProntuario[id].started = true
  
        setvalidaProntuario({...validaProntuario})
      }
    })
  }

  //   <div style={{ color: 'red', width: '100%', marginTop: '10px', fontSize: '15px' }}>
  //   {validaProntuario[id].error === true ? validaProntuario[id].message : ''}
  // </div>
  return (
    <div className="CardTextoCurto-container" >
      <div className="container-card-input">

        <div className="titleEscale">
          <h3 className="h3-title-card-input">Craniometria</h3>
        </div>

        <div>
          <div className={classes.inputs}>
            {options.map((item, index) => {
              return (
                <TextField
                  disabled={respView}
                  label={item.text}
                  style={{  marginTop: '25px' }}
                  name={item.text}
                  className='inputdm'
                  onChange={handlerChange2}
                  value={OptionValues && OptionValues[index]?.value}
                  variant="outlined"
                ></TextField>
              )
            })}
          </div>
        </div>

        <div style={{ color: 'red', width: '100%', marginTop: '10px', fontSize: '15px' }}>
          { !respView && validaProntuario[id].error === true ? validaProntuario[id].message : ''}
        </div>
      </div>
    </div>
  );
}