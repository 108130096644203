import React, {useState, useEffect} from 'react';
import AddIcon from '@material-ui/icons/Add';
import ShortTextIcon from '@material-ui/icons/ShortText';
import IconButton from '@material-ui/core/IconButton';
import '../../../css/NovoModelo.css';
import { StateGlobal } from '../../../../../../ContextAPI/context';
import CardTextoLongo from '../../../SecaoInput/Components/CardTextoLongo';
import SubjectIcon from '@material-ui/icons/Subject';

export default function ButtonsFerramentas({index ,id}) {

    const {    
        montagemstate,
        setMontagem,
        att  ,
        setAtt,
    idComponentAtual} = StateGlobal();


      
        var array = []


        function getdata(e) {

        }
        


        //array.push({ component: <CardTextoCurto getData={getdata} testid={testid} tempId={id}/> })

  return (
            <div className="NovoModelo-Ferramentas">
               <div className="circle-icon">
                   <SubjectIcon/>
               </div>
                <p className="titulo-ferramenta-btn">Texto Longo</p>
               <div onClick={()=>{

                array  = montagemstate
     
                array.push({ type: "textolongo", component: <CardTextoLongo getData={getdata}  tempId={id}/> , tempId : id , title : ''  , required : false, idsessao:idComponentAtual })   
               
                setMontagem(array)
                setAtt(!att)
              
               


               }} className="envolve-btn-add-ferramentas">
                   <IconButton className="btn-add-ferramenta">
                       <AddIcon/>
                   </IconButton>
               </div>
            </div>
  );
}