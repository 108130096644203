import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


export default class GraficoFaixaEtaria extends PureComponent {
  
  constructor(props) {
    super(props);
    this.state = { 
      dados:this.props.dados,
      activeIndex: 0,
    };
}

componentDidMount() {
  console.log(this.props.dados)
    this.interval = setInterval(() => this.setState({ 
    dados:this.props.dados,
  }), 1000);
}

componentWillUnmount() {
  clearInterval(this.interval);
}

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={100}
          height={100}
          data={this.state.dados}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="quantity" stroke="#27b8d2" fill="#27b8d2" />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
