import React, {useState, useEffect} from 'react';
import { ptBR } from '@material-ui/core/locale';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CircularLoad from '../../../Components/Load';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import conexao from '../../../Services/api'
import {useHistory} from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import '../css/Agenda.css'
import {currency, currencyinput} from './Validate'
import ModalExcluir from '../../../Components/Modais/ModalExclusao';



const useStyles = makeStyles((theme) => ({
  input:{
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    backgroundColor: 'white',
  },
  pConfig: {
    color: '#8F8F8F',
    minWidth: '100%',
    maxWidth: '100%',
    textAlign: 'left',
    wordWrap: 'break-word',
    margin: '0rem',
    marginTop: '1rem'
},
envolveConfig: {
    borderBottom: '1px solid #8F8F8F',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: ' column',
    justifyContent: 'space-between',
    paddingLeft: '1rem',
},
envolveConfigs: {
    height: '35vh',
    overflow: 'auto',
    backgroundColor: 'white',
    marginTop: '1rem',
    marginBottom: '1.5rem'
},
  modalColor:{
    backgroundColor: '#f9f9f9',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  divBtn:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%'
  }
}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
}, ptBR);


export default function ModalConfiguracaoAgenda(props) {
  const classes = useStyles();
  const History = useHistory();

  const initialvalues={
    procedimento:'',
    valor:'',
  }

  const [open, setOpen] = useState(false);
  const [values , setValues] = useState(initialvalues)
  const [valor, setValor] = useState()
  const [procedimento , setProcedimento] = useState();
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [itens, setItens] = useState();
  const [procId, setProcId] = useState();
  const [editar, setEditar] = useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [load, setLoad] = useState(false)
  const [titulo, setTitulo] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [optionDelete, setOptionDelete] = React.useState('');

  useEffect(() => {
    setOpen(props.open);
  }, [props])

  const closeModal = () => {
    setOpen(false);
    props.onChange(false);
    setProcId('');
    setEditar(false);
    setValor('')
    setProcedimento('')
  }

   // usar essa função para o modal delete
  const handleClose = () => {
    setOpenModalDelete(false);
    setProcId('');
    setEditar(false);
  }

  async function listaProcedimentos() {
    var userId = '';
    if(localStorage.getItem('userId')){
      userId = localStorage.getItem('userId');
    await conexao.get(`/schedule/type/${userId}`)
      .then((response) => {
        setItens(response.data)
        //
      })
    }
  }

  async function salvaProcedimentos(e) {
    setLoad(true)
    e.preventDefault();
    if(procedimento && valor){
    var userId = '';
    if(isNaN(valor)){
    var value = valor.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1.$2");
    }else{
      value = valor;
    }
    if(!editar){
      if(localStorage.getItem('userId')){
        userId = localStorage.getItem('userId');
        await conexao.post('/schedule/type/create',
        {
          "name": procedimento,
          "value": parseFloat(value),
          "user_id": userId
        })
          .then((response) => {
            setLoad(false)
            setTypeAlert('success');
            setMessage('Procedimento salvo com sucesso');
            listaProcedimentos();
            setTimeout(() => {
              setTypeAlert('');
              setMessage('');
            }, 1500);
          }).catch((error)=>{
            setLoad(false)
            setTypeAlert('error');
            setMessage('Erro ao criar procedimento, tente novamente');
            listaProcedimentos();
            setTimeout(() => {
              setTypeAlert('');
              setMessage('');
            }, 1500);
          })

      }else{
        History.push('/');
      }
    }else{
      setLoad(true)
      await conexao.put(`/schedule/type/update/${procId}`,
      {
        "name": procedimento,
        "value": parseFloat(value),
      })
        .then((response) => {
          setLoad(false)
          setTypeAlert('success');
          setMessage('Procedimento alterado com sucesso');
          listaProcedimentos();
          setTimeout(() => {
            setTypeAlert('');
            setMessage('');
          }, 1500);
        }).catch((error)=>{
          setLoad(false)
          setTypeAlert('error');
          setMessage('Erro ao editar procedimento, tente novamente');
          listaProcedimentos();
          setTimeout(() => {
            setTypeAlert('');
            setMessage('');
          }, 1500);
        })
    }
    }else{
      setLoad(false)
      setTypeAlert('error');
      setMessage('Preencha os campos corretamente');
    }
  }

 // usar essa função para o modal delete
  function deletaProcedimentos(id) {
    setTitulo('Exclusão de procedimento');
    setDescricao('Deseja excluir esse procedimento?');
    setProcId(id);
    setOpenModalDelete(true);
    
  }

async function deletaProcedimento() {
  setLoad(true)
  await conexao.delete(`/schedule/type/${procId}`)
    .then((response) => {
      setLoad(false)
      setOpenModalDelete(false);
      setTypeAlert('success');
      setMessage('Procedimento deletado com sucesso');
      setTimeout(() => {
        setTypeAlert('');
        setMessage('');
      }, 1500);
      listaProcedimentos();
    }).catch((error)=>{
      setLoad(false)
      setOpenModalDelete(false);
      setTypeAlert('error');
      setMessage('Erro ao deletar procedimento, tente novamente');
      listaProcedimentos();
      setTimeout(() => {
        setTypeAlert('');
        setMessage('');
      }, 1500);
    })
}

function editarProcedimento(id) {
  setEditar(true);
  setProcId(id);
    var userId = '';
    if(localStorage.getItem('userId')){
      userId = localStorage.getItem('userId');
    conexao.get(`/schedule/type/${userId}`)
      .then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          if(response.data[i].id===id){
            setValor(response.data[i].value)
            setProcedimento(response.data[i].name)
          }
        }
      })
    }
}

  useEffect(() => {
    listaProcedimentos();
  }, [])

  // usar essa função para o modal delete
  const handleModal = (value) => {
    setOptionDelete(value);
    if(value){
      deletaProcedimento()
    }else{
      handleClose()
    } 
  };

  return (
    <div className="Modal-agendamento">
    <ThemeProvider theme={theme}>
      <Dialog open={open}   aria-labelledby="Modal-title-agendamento">
      <form id="" onSubmit={salvaProcedimentos} className={classes.modalColor} autoComplete="off">
        <DialogTitle id="Modal-title-agendamento">
          <div className="title-modal-close">
            Configurações da agenda
            <CloseIcon onClick={closeModal}/>
          </div>
        </DialogTitle>
        <DialogContent>
          {message ?
            <div className="Modal-agendamento-alert">
              <Alert variant="filled" severity={typeAlert}>
                {message}
              </Alert>
            </div>
          :''}
          <div>
            <TextField
              InputLabelProps={procedimento?{ shrink: true }:''}
              className={classes.input} 
              name='procedimento' 
              value={procedimento} 
              onChange={(e)=>{setProcedimento(e.target.value)}} 
              type="text" 
              required
              id="" 
              label="Nome do Procedimento" 
              variant="outlined"
             />
            <TextField 
              className={classes.input} 
              name='valor' 
              value={valor} 
              onChange={(e)=>{setValor(currencyinput(e.target.value))}} 
              type="text"
              required
              id="" 
              label="Valor do Procedimento" 
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
            />
          </div>
          <DialogActions>
          <Button onClick={closeModal} type="button">
            Cancelar
          </Button>
          {editar?
          <Button type="submit" className="color-primary" variant="contained" color="primary" className="">
            {!load ? 'Salvar alteração' : <CircularLoad/>}
          </Button>
          :
          <Button type="submit" className="color-primary" variant="contained" color="primary" className="">
            {!load ? 'Incluir' : <CircularLoad/>}
          </Button>
          }
        </DialogActions>
          <div className={classes.envolveConfigs}>
          {itens ?
            itens.map((item) => (
              <div className={classes.envolveConfig}>
                <p className={classes.pConfig}>{item.name}</p>
                <p className={classes.pConfig}>R$ {currency(item.value)}</p>
                <div className={classes.divBtn}>
                <IconButton onClick={()=>editarProcedimento(item.id)} aria-label="edit" className={classes.margin}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={()=>deletaProcedimentos(item.id)} aria-label="delete" className={classes.margin}>
                  <DeleteIcon />
                </IconButton>
                </div>
              </div>
              ))
          :''}
          </div>
        </DialogContent>
        </form>
      </Dialog>
          <ModalExcluir
            handleModal={handleModal}
            open={openModalDelete} 
            load={load} 
            titulo={titulo} 
            descricao={descricao}
           />
      </ThemeProvider>
    </div>
  );
}