import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import Logo from '../../Assets/Images/logoBranco.svg';
import ModalSair from '../Modais/ModalSair';
import Avatar from '@material-ui/core/Avatar';
import NotificationsIcon from '@material-ui/icons/Notifications';
import './css/menuTopo.css';
import conexao from '../../Services/api'
import { StateGlobal } from '../../ContextAPI/context';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      color: "#fff",
    }),
    backgroundColor: "#27b8d2",
    color: "#fff",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  subIcon: {
    marginLeft: "1rem",
  },
  iconVoltar: {
    color: "#fff",
  },
  text: {
    color: "#fff",
    textDecoration: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#27b8d2",
    color: "#fff",
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

export default function PersistentDrawerRight() {

  const classes = useStyles();
  const History = useHistory();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [badge, setbadge] = useState();
  const [controle, setControle] = useState()
  const [load, setLoad] = useState(false)
  const [fotoPerfil, setFotoPerfil] = React.useState('');
  const [nome, setNome] = React.useState('');
  const [voltar, setVoltar] = useState('')
  const [openModalSair, setOpenModalSair] = React.useState(false);
  const { setIsEditPatient, notification, setNotification, notfyatt, setNotatt, setRota ,rota} = StateGlobal()



  useEffect(() => {
    AttdadosSininho()
    setInterval(() => {
      AttdadosSininho()
    }, 180000)
  }, [controle])


  useEffect(() => {
    if (History.location.pathname != '/suporte' && History.location.pathname != '/notificacoes' && History.location.pathname != '/meu-perfil'  ) {    
      setRota(History.location.pathname)
    } 
  }, [])

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  function suporte() {
 
    // History.push('/suporte');

    let a= document.createElement('a');
    a.target= '_blank';
    a.href= 'https://api.whatsapp.com/send?phone=5515981120505&text=Estou%20utilizando%20o%20Clinicplus%20e%20preciso%20de%20suporte%20t%C3%A9cnico%20!';
    a.click();
  }

  
  function notificacoes() {
    History.push('/notificacoes', { params: voltar });
  }

  function AttdadosSininho() {
    let qtd = 0

    var userId = '';
    if (localStorage.getItem('userId')) {
      userId = localStorage.getItem('userId');
    conexao.get(`/notification/${userId}`).then((res) => {

      res.data.data.map((item) => {
        if (item.read === 0) {
          qtd = qtd + 1
        }
        setbadge(qtd)
      })

      setNotification(res.data.data)

    }).catch(function (error) {
      if (error.response.status==401) {
        History.push('/')
      }
    });
  }
}

  function modalSair() {
    setOpenModalSair(true);
  }

  const handleModalSair = (value) => {
    if (value) {
      sair()
    } else {
      handleCloseSair()
    }
  };

  const handleCloseSair = () => {
    setOpenModalSair(false);
  }
  const sair = () => {
    setLoad(true);
    conexao.post('/logout').then((res) => {
      setLoad(false)
      localStorage.clear();
      History.push('/');
    }).catch((error) => {
      setLoad(false)
      localStorage.clear();
      History.push('/');
    })
  }

  useEffect(() => {
    // É inaceitavel fazer essa requisição neste lugar
    // Mais inaceitável ainda é trazer todos os profissionais e "filtrar" no front os dados do usuário logado
    // getDadosCadastro()

    setFotoPerfil(localStorage.getItem('avatar'));
    setNome(localStorage.getItem('doctorName'));

  }, [])

  async function getDadosCadastro(){
    await conexao.get(`/doctor`)
    .then((resp) => {
      var userId = '';
      if(localStorage.getItem('userId')){
        userId = localStorage.getItem('userId');
        var cadastro = resp.data.data;
        for(let i = 0; i < cadastro.length; i++){
          if(userId == cadastro[i].user_id){
            setFotoPerfil(cadastro[i].avatar);
            setNome(cadastro[i].user.name);
        }
      }
    }else{
      History.push('/');
    }
    }).catch(function (error) {
      if (error.response.status==401) {
        History.push('/')
      }
    });

  }

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {localStorage.getItem('/perfil') === 'true'?
        <MenuItem onClick={()=>History.push('/meu-perfil')}>Meu perfil</MenuItem>
      :''}
      {/* {localStorage.getItem('/plano') || localStorage.getItem('/planos') === 'true'?
        <MenuItem onClick={()=>History.push('/planos')}>Planos</MenuItem>
      :''} */}
      <MenuItem onClick={suporte}>Suporte</MenuItem>
      <MenuItem onClick={modalSair}>Sair</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <div className="menuTopo-envolve-logo-botoes">
            <div className="MenuTopo-logo-login">
              <Link to="/home">
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            <div className={classes.sectionDesktop}>
              {/* <IconButton aria-label="show 4 new mails" color="inherit">
                  <Badge badgeContent={4} color="secondary">
                    <MailIcon />
                  </Badge>
                </IconButton> */}
              <IconButton onClick={notificacoes} aria-label="show 10 new notifications" color="inherit">
                <Badge badgeContent={badge} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                className="menuTopo-img-perfil"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                       <Avatar alt={nome} src={fotoPerfil} />
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <ModalSair
        handleModal={handleModalSair}
        open={openModalSair}
        load={load}
      />
    </div>
  );
}