import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import { StateGlobal } from '../../../ContextAPI/context';
import DadosPessoais from '../Components/DadosPessoais';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import conexao from '../../../Services/api';
import './style.css'
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Modal from '../Components/Modal/index';
import CircularLoad from '../../../Components/Load';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto",
    marginBottom: '3rem'
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  colorPaperWhite: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 0px 0px gray",
    padding: '2rem'
  },
  envolveResumo: {
    marginTop: '1rem',
    height: '87vh',
    padding: '1rem'
  },
  envolveDadosPaciente: {
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  divEnvolveFotoDados: {
    display: 'flex'
  },
  divEnvolveTextos: {
    '& p': {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: '1rem',
      color: '#8a8a8a'
    }
  },
  avatar: {
    width: '60px',
    height: '60px',
    fontSize: '2rem'
  },
  tituloName: {
    fontSize: '1.3em',
    color: '#27b8d2 !important',
    marginBottom: '.3rem !important',
    fontWeight: '600'
  },
  tituloEtapa: {
    fontSize: '1.3em',
    color: '#757575 !important',
    marginBottom: '1rem !important',
    fontWeight: '600'
  },
  filtroData: {
    display: 'flex',
  },
  filtroResulo: {
    backgroundColor: 'white',
    marginTop: '2rem',
    padding: '1rem',
    overflow: 'auto'
  },
  pSelecionarData: {
    marginRight: '1rem',
    color: '#757575',
    fontWeight: '500'
  },
  textField: {
    marginLeft: '2rem',
    marginRight: '2rem'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
  },
  table: {
    boxShadow: '0px 0px 0px 0px grey',
    marginTop: '2rem',
  },
  dataSelect: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  mobileDateFim: {
    marginTop: '15px',
  },
  btnBuscar: {
    marginTop: '15px',
    width: '100%'
  },
  containerColapse: {
    marginBottom: '5rem',
    marginTop: '2rem',
    minHeight: '50vh'
  },
  AccordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    color: '#8f8f8f'
  },

  buttons: {
    margin: '5px'

  },
  hr: {
    border: '1px solid #d3d3d3',
    width: '100%'
  },
  divbuttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  AccordionSummary: {
    display: 'flex',
    flexDirection: 'column'

  },
  Accordion: {
    marginBottom: '15px'
  },
  titleDialog: {
    color: '#8F8F8F',
    padding: '29px',
    fontSize: '16px',
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#27b8d2',
      contrastText: '#fff',
    }
  },
}, ptBR);

function createData(data, hora, prontuario) {
  return { data, hora, prontuario };
}


export default function ResumoPaciente() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [erro, setErro] = useState();
  const [dataInit, setDataInit] = useState();
  const [dataFim, setDataFim] = useState();
  const [attState, setAttState] = useState(false);
  const [iddelete, setIddelete] = useState();
  const [typeAlert, setTypeAlert] = useState('');
  const [message, setMessage] = useState('');
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false)

  const { pos, setPos, setMontagemProntuario, respView, respEdit, setrespEdit, setAnswer, setRespView, PosProntuario, setPosProntuario, Answer, validaProntuario, setvalidaProntuario, NameProntuario, setNameProntuario, attResumo, setFormPatientID } = StateGlobal();

  const patientID = localStorage.getItem('patientID')

  useEffect(() => {
    getHistorico()
  }, [pos, attResumo, patientID])

  function deleteProntuario() {
    setLoad(true)
    conexao.delete('/form/patient/' + iddelete).then((res) => {
      setLoad(false)
      setTypeAlert('success')
      setMessage('Deletado com sucesso!')
      setTimeout(() => {
        setTypeAlert('')
        setMessage('')
        closemodal()
        window.location.reload()
      }, 2000);
    }).catch((error) => {
      setLoad(false)
      setTypeAlert('error')
      setMessage('Erro ao deletar!')
      setTimeout(() => {
        setTypeAlert('')
        setMessage('')
      }, 2000);

    })
  }

  async function getHistorico() {

    conexao.post('/form/patient/' + localStorage.getItem('patientID')).then((res) => {
      if (res.data.data) {
        setRows(res.data.data)
        setIsLoading(false)
      }

    }).catch((error) => {
      setIsLoading(false)
    })

  }

  function closemodal() {
    setOpen(false)
  }

  function ArvoreComponents(id, edit) {

    conexao.get('/form/patient/answered/' + id).then((res) => {

      const arredit = []

      res.data.data.steps.map((item, index) => {
        const idsessao = Math.floor(Math.random() * 256)



        const components = []
        item.questions.map((item) => {

          const typesImage = ['image']

          const typesOsteopatia = ['osteopatiapediatrica']
          const typesCurvadecrescimento = ['curvadecrescimento']
          //osteopatiapediatrica
          const typesMotor = ['desenvolvimentomotor']
          //desenvolvimento motor
          const typesOdonto = ['odontologico']
          //odontologico
          const typesEvas = ['escaladedor', 'musculoesqueletico', 'visceral', 'cranianovistainferior', 'cranianovistaanterior', 'cranianovistaposterior', 'cranianovistalateraldireita']
          //componentes mapas eva
          const types = ['textocurto', 'textolongo', 'data', 'numerico', 'telefone', 'email', 'label']
          //componentes basicos de texto


          typesOsteopatia.map((typeM) => {

            if (item.type === typeM) {

              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, answer: item.answers ? item.answers : [], id_answer: item.answers.id, options: item.options })
            }

          })

          //COMPONENTES ESPECIAIS ODONTOLOGICO  EDITAR 
          typesMotor.map((typeM) => {

            if (item.type === typeM) {

              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, answer: item.answers ? item.answers : [], id_answer: item.answers.id, options: item.options })
            }

          })


          typesOdonto.map((typeOdontologico) => {

            if (item.type === typeOdontologico) {

              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, answer: item.answers ? item.answers : [], id_answer: item.answers.id, options: item.options })
            }

          })

          typesCurvadecrescimento.map((typeM) => {

            if (item.type === typeM) {

              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, answer: item.answers ? item.answers : [], id_answer: item.answers.id, options: item.options })
            }

          })

          //COMPONENTES ESPECIAIS MAPA EVA  EDITAR 
          typesEvas.map((typeEva) => {

            if (item.type === typeEva) {

              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, answer: item.answers ? item.answers : [], id_answer: item.answers.id, options: item.options })
            }

          })

          //COMPONENTES SIMPLES EDITAR
          types.map((itemtypes) => {
            if (item.type === itemtypes) {

              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, answer: item.answers[0] ? item.answers[0].text : '', id_answer: item.answers[0] ? item.answers[0].id : '' })
            }
          })

          //componente selecao
          if (item.type === 'selecao') {
            const arrop = []
            if (item.options) {
              item.options.map((item) => {
                arrop.push({ text: item.text, value: item.id })
              })
            }
            components.push({ type: "selecao", component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, opcoes: arrop, answers: item.answers })

          }

          //componente check
          if (item.type === 'check') {

            const arrop = []
            if (item.options) {
              item.options.map((item) => {
                arrop.push({ text: item.text, value: item.id })
              })
            }
            components.push({ type: "check", component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, opcoes: arrop, answers: item.answers })

          }

          if (item.type === 'image') {
            components.push({ type: "image", component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, answer: item.answers })
          }

        })

        //gera sessoes
        arredit.push({ componentsessao: item.title, id: idsessao, montagem: components, titulosessao: item.description })

        criaAnswer(arredit, edit)
        createValidate(arredit)

      })


      setNameProntuario(res.data.data.title)
      setMontagemProntuario(arredit)

      setPosProntuario(0)
      setPos(2)
      // IMPORTATE AVANCA PARA PROXIMA TELA
    })


    function createValidate(e) {//CRIAR ARRAY PARAR VALIDACAO DOS COMPONENTES DINAMICOS
      if (!respView) {

        var valida = {}

        e.map((item) => {
          item.montagem.map((obj, index) => {
            valida = {
              ...valida,
              ...{
                [obj.tempId]: {
                  question_id: obj.tempId,
                  value: '',
                  required: obj.required === '1' ? true : false,
                  sessao: obj.idsessao,
                  error: false,
                  message: '* Reposta Obrigatória',
                  type: obj.type,
                  started: false
                }
              }
            }
          })
        })


        setvalidaProntuario(valida)
      }

    }
  }


  function criaAnswer(arredit, edit) { //MUITO IMPORTANTE PEGA VALORES DO BACK E CONVERTE PARA PRENCHER OS COMPONENETES DINAMICOS E ARMAZENAR SUAS UPDADTE NO ARRAY ANSWER DO CONTEXT API


    //OBS TALVEZ CRIAR ESSA FUNCAO NO RESUMO    PARA NAO PERDER O ESTADO
    const answers = []
    arredit.map((item) => {

      // if (item.montagem) {

      item.montagem.map((create) => {

        if (create.type === 'check') {
          create.opcoes.map((opcao) => {
            answers.push({
              question_id: create.tempId,
              question_type: create.type,
              id_check: opcao.value,
              answer: create.answer !== undefined ? create.answer : ''
            })
          })

        }

        if (create.type === 'osteopatiapediatrica') {

          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : [],
            form_step_question_option_id: ''

          })

        }

        if (create.type === 'curvadecrescimento') {

          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : [],
            form_step_question_option_id: ''

          })

        }


        if (create.type === 'desenvolvimentomotor') {

          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : [],
            form_step_question_option_id: ''

          })

        }


        if (create.type === 'odontologico') {

          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : [],
            form_step_question_option_id: ''

          })

        }

        if (create.type === 'image') {
          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : '',

          })

        }


        if (create.type === 'escaladedor' || create.type === 'cranianovistainferior' || create.type === 'musculoesqueletico' || create.type === 'visceral' || create.type === 'cranianovistaanterior' || create.type === 'cranianovistaposterior' || create.type === 'cranianovistalateraldireita') {

          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : '',
            form_step_question_option_id: ''
          })
        }

        if (create.type === 'textocurto' || create.type === 'textolongo' || create.type === 'data' || create.type === 'numerico' || create.type === 'telefone' || create.type === 'email' || create.type === 'label' || create.type === 'selecao') {

          answers.push({
            question_id: create.tempId,
            question_type: create.type,
            answer: create.answer !== undefined ? create.answer : '',

          })

        }

      })
    })

    setAnswer(answers.slice())
  }





  // async function getHistorico(){
  //   // const pacientID = localStorage.getItem('userId')
  //   console.log('USEEFECT RESUMO',)

  //   await conexao.post(`/form/patient/`+localStorage.getItem('patientID'))
  //   .then((resp) => {
  //     console.log('REPONSE FORM PATIENT' ,resp)

  //     const arr = resp.data.data

  //     const array = []

  //     arr.map((item)=>{

  //       const data = alterarData(item.created_at)

  //       let position =  createData(data[0], data[1], item.form.title)

  //       array.push(position)  

  //     })
  //     setRows(array)
  //     setIsLoading(false)

  //   }).catch((error) => {
  //    // console.log(error.response.data.message)
  //     //setErro(error.response.data.message)
  //    // setIsLoading(false)

  //   })
  // }

  function alterarData(data) {

    const arr = data.split(" ")

    const date = arr[0].split('-')
    const dateRev = date.reverse()

    const formatDate = `${dateRev[0]}/${dateRev[1]}/${dateRev[2]}`

    const hora = arr[1].split(":")
    const formatHora = `${hora[0]}:${hora[1]}`

    return [formatDate, formatHora]

  }


  async function filtro() {


    // const pacientID = localStorage.getItem('userId')
    if (dataInit && dataFim) {

      await conexao.post('/form/patient/' + patientID, {
        "started_at": dataInit,
        "finished_at": dataFim
      })
        .then((resp) => {

          // const arr = resp.data.data
          // const array = []
          // arr.map((item)=>{
          //   const data = alterarData(item.created_at)
          //   let position =  createData(data[0], data[1], item.form.title)
          //   array.push(position)
          // })
          // setRows(array)

          setIsLoading(false)
          setErro(false)

          setRows(resp.data.data)

        }).catch((error) => {

          setErro(true)
          setRows('')
          setIsLoading(false)


        })
    } else {
      getHistorico()
    }

  }

  return (
    <div className={classes.envolveResumo}>
      <ThemeProvider theme={theme}>
        {/* <h3 className={classes.tituloEtapa}>Resumo</h3> */}
        <div>
          <DadosPessoais />
          <div className={classes.filtroResulo}>
            <div className={classes.filtroData}>
              {/* <p className={classes.pSelecionarData}>Selecionar data:</p> */}
              <form className={classes.container} noValidate>
                <div className={classes.dataSelect}>
                  <TextField
                    id="date"
                    label="Data inicial"
                    type="date"
                    onChange={(e) => { setDataInit(e.target.value) }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="date"
                    label="Data final"
                    type="date"
                    className={classes.mobileDateFim}
                    onChange={(e) => { setDataFim(e.target.value) }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <Button variant="contained" className={clsx('color-primary', classes.btnBuscar)} color="primary" onClick={() => { filtro() }}>
                  Buscar
                </Button>

              </form>
            </div>
            <div className={classes.containerColapse}>

              {rows[0] ? rows.map((row) => {

                return (

                  <Accordion className={classes.Accordion} key={row.id}>

                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"

                    >
                      <div className={classes.AccordionSummary}>

                        <Typography className={classes.heading}>Data: {alterarData(row.created_at)[0]}</Typography>

                      </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetails}>
                      <Typography className={classes.heading}>
                        Prontuário : {
                          row.title === 'Fisioterapia Esportiva' ? 'Fisioterapia Esportiva' : row.form !== null ? row.form?.title : ''
                        }
                      </Typography>
                      <Typography className={classes.heading}>Hora: {alterarData(row.created_at)[1]}</Typography>
                      <hr className={classes.hr} />
                      <div className={classes.divbuttons} >
                        <IconButton
                          className={classes.buttons}
                          onClick={() => {
                            if (!row.form) {
                              setOpenDialog(true)
                              localStorage.setItem('idEdicaoRP', row.id)
                              return
                            }

                            setRespView(true)
                            setrespEdit(true)  //importante  grava array com respostas no prontuario 
                            setFormPatientID(row.form_id)
                            localStorage.setItem('idEdicaoRP', row.id)
                            ArvoreComponents(row.id)
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>

                        {/* <IconButton 
                                onClick={() => {
                                  setrespEdit(true)
                                  localStorage.setItem('idEdicaoRP', row.id)

                                  ArvoreComponents(row.id, true)

                                }}
                              >
                                <EditIcon />
                              </IconButton> */}

                        {/* <IconButton onClick={() => {
                                setIddelete(row.id)
                                setOpen(true)
                              }}>
                                <DeleteIcon />
                                </IconButton> */}



                      </div>
                    </AccordionDetails>
                  </Accordion>)
              }) :
                'Nenhum registro encontrado'
              }
              {/* <TableContainer className={classes.table} component={Paper}>
                        <Table size="small" aria-label="tabela resumo">
                            <TableHead>
                            <TableRow>
                                <TableCell>Data</TableCell>
                                <TableCell align="center">Hora</TableCell>
                                <TableCell align="center">Prontuário Selecionado</TableCell>
                                <TableCell align="center"></TableCell>

                            </TableRow>
                            </TableHead>

                              
                            <TableBody>
                             
                            { 
                            !erro ? 
                            rows ? 
                            rows.map((row) => (
                                <TableRow key={row.data}>
                                <TableCell component="th" scope="row">
                                    {row.data}
                                </TableCell>
                                <TableCell align="center">{row.hora}</TableCell>
                                <TableCell align="center">{row.prontuario}</TableCell>
                                <TableCell align="center">
                                    <IconButton>
                                        <VisibilityIcon/>
                                    </IconButton>
                                    <IconButton>
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton>
                                        <DeleteIcon/>
                                    </IconButton>
                                </TableCell>
                                </TableRow>
                            ))
                            
                            : ''
                            : 
                           
                              <TableCell colSpan={5} className="erroDados">Registros não encontrados</TableCell> 
                            
                            }                          
                            </TableBody>
           
                        </Table>
                        </TableContainer> */}

            </div>
          </div>
        </div>
      </ThemeProvider>
      <Modal desc={'Deseja deletar o atendimento?'} titulo={'Clinic Plus'} delete={deleteProntuario} typeAlert={typeAlert} message={message} load={load} open={open} close={closemodal} />
      <Dialog onClose={() => setOpenDialog(false)} aria-labelledby="customized-dialog-title" open={openDialog}>
        <p className={classes.titleDialog}>Erro: Este modelo de prontuário não pode ser visualizado na versão aplicativo</p>

        <DialogActions>
          <Button autoFocus onClick={() => setOpenDialog(false)} color="primary">
            FECHAR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}