import React, { useState, useEffect} from "react"
import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { StateGlobal } from '../../../../ContextAPI/context';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import conexao from '../../../../Services/api';
import CircularLoad from '../../../../Components/Load';
import {teste, teste2} from '../../../../Components/ReduxTeste/ValidateForm'

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  colorPaperWhite:{
    backgroundColor: "#fff",
    boxShadow: "0px 0px 0px 0px gray",
    padding: '2rem'
  },
  envolveResumo:{
    marginTop: '1rem',
    height: '87vh',
    padding: '1rem'
  },
  envolveDadosPaciente:{
    backgroundColor: 'white',
    padding: '1rem',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
    
  },
  divEnvolveFotoDados:{
      display: 'flex',
      flexDirection:'column',
      alignItems: 'center',
      marginBottom: '15px'
  },
  
  divEnvolveTextos:{
    '& p': {
      marginTop: '5px',
      marginBottom: 0,
      marginLeft: '0rem',
      color: '#8a8a8a',
      fontSize:'16px'
    }
  },
  
  avatar:{
      width: '100px',
      height: '100px',
      fontSize: '2rem'
  },
  tituloName:{
      fontSize: '1.3em',
      color: '#27b8d2 !important',
      marginBottom: '.3rem !important',
      fontWeight: '600',
      fontSize: '20px',
      textTransform: 'capitalize'

  },
  tituloEtapa:{
        fontSize: '1.3em',
        color: '#757575 !important',
        marginBottom: '1rem !important',
        fontWeight: '600'
  },
  filtroData:{
      display: 'flex',
  },
  filtroResulo:{
    backgroundColor: 'white',
    marginTop: '2rem',
    padding: '2rem',
    height: '48vh',
    overflow: 'auto'
  },
  pSelecionarData:{
    marginRight: '1rem',
    color: '#757575',
    fontWeight: '500'
  },
  textField:{
      marginLeft: '2rem',
      marginRight: '2rem'
  },
  container:{
    display: 'flex',
    alignItems: 'center'
  },
  table:{
    boxShadow: '0px 0px 0px 0px grey',
    marginTop: '2rem',
  },
  flex:{
    display: 'flex',
    flexDirection: 'column',
    alignItems:'center'
  },
  vizualizar:{
    display: 'flex',
    justifyContent:'center',
    width: '100%',
    marginTop: '15px'
  },
  divDados:{
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
  },
  btnVizualizar:{
    width: '100%',
  }

 
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#27b8d2',
      contrastText: '#fff',
    }
  },
}, ptBR);

export default function DadosPessoais(){
  const history = useHistory()
  const classes = useStyles();
  
  const[dadosPaciente, setDadosPaciente] = useState('');
  const[avatar, setAvatar] = useState('');
  const[isLoading, setIsLoading] = useState(true)

  const { agendadoData, pacienteName, Location } = StateGlobal()
  const { setIsEditPatient, setLocation } = StateGlobal()

  useEffect(() => {
    if(localStorage.getItem('Location') === 'pacientes'){
      getallPacients()
    }else{
      chamada()
    }
  },[
    localStorage.getItem('Location'),
    localStorage.getItem('agendamentoId'),
    localStorage.getItem('patientID')
  ])
  
  async function chamada(){ 
    const agendamentoId = localStorage.getItem('agendamentoId')
    
    await conexao.get(`/form/patient/schedule/${agendamentoId}`)
    .then((resp) => {
      setDadosPaciente(resp.data.data)
      localStorage.setItem('patientID' ,resp.data.data.patient_id)
      // setLocation('prontuario')
      getAvatar()
    })
  }

  function getAvatar(){
    const patientId = localStorage.getItem('patientID')
    const doctorId = localStorage.getItem('doctorId')

    conexao.post(`patient/filter/${doctorId}`, {name: pacienteName})
    .then((res) => {
      res.data.data.data.forEach(item => {
        if(patientId == item.id){
          setAvatar(item.avatar)
        }
      });
      setIsLoading(false) 
    })
  }

  async function chamada(){ 
    const agendamentoId = await localStorage.getItem('agendamentoId')
    
    await conexao.get(`/form/patient/schedule/${agendamentoId}`)
    .then((resp) => {
      localStorage.setItem('patientID' ,resp.data.data.patient_id)
      setDadosPaciente(resp.data.data)
      getAvatar()

    })
  }
    
  function getallPacients() {
    const patientId = localStorage.getItem('patientID')
    const doctorId = localStorage.getItem('doctorId')

    conexao.post(`patient/filter/${doctorId}`, {name: pacienteName})
    .then((res) => {
      
      res.data.data.data.forEach(item => {
        if(patientId == item.id){
          var ano_atual = new Date().getFullYear();
          let date = item.birthday
          let idade 
          
          if (date) {
            date = date.split(' ')
            
            date = date[0].split('-')

            date = date[0]
            
            idade = ano_atual - date
          } else {
            idade = ''
          }
          
          let data = {
            age: idade,
            attendance: "-",
            health_insurance: "-",
            name: item.name,                 
          }

          setDadosPaciente(data)
          getAvatar()
          setIsLoading(false)
        }
      });
    })
  }

  function vizualizar(){
    setIsEditPatient(true)
    history.push('/cadastro-paciente')

  }    
  
  return(

    <div className={classes.envolveDadosPaciente}>
      {isLoading ? 
        <div className="div-load-lista-modelos">
          <CircularLoad />
        </div> 
        
        : 
      
        dadosPaciente ? 
          <div className={classes.divEnvolveFotoDados}>
            <Avatar className={classes.avatar} alt={dadosPaciente.name} src={avatar ? avatar : '/static/images/avatar/1.jpg'} />
            <div className={classes.divEnvolveTextos}>
              <div className={classes.divDados}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <p className={classes.tituloName}>{dadosPaciente.name}</p>
                  {localStorage.getItem('Location') === 'pacientes' ? (
                    <div className={classes.flex}>
                      <div>
                        { dadosPaciente.age !== 0 && 
                          <p>
                            Idade: <strong>{dadosPaciente.age ? `${dadosPaciente.age} anos` : '-'}</strong>
                          </p>
                        }
                      </div>
                    </div>
                  ) : (
                    <div className={classes.flex}>
                      <div>
                        { dadosPaciente.age !== 0 && 
                        <p>Idade: <strong>{dadosPaciente.age ? `${dadosPaciente.age} anos` : '-'}</strong></p>
                        }
                        <p>Convênio: <strong> {dadosPaciente.health_insurance} </strong></p>
                      </div>
                      <div className={classes.atendimento}>
                        <p>Atendimentos: <strong> {dadosPaciente.attendance} </strong></p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div> 
        : 
        " " 
      }
        
      {isLoading ? '' :
        <div className={classes.vizualizar}>
          <Button 
            variant="contained" 
            className={clsx('color-primary', classes.btnVizualizar)}  
            color="primary" 
            onClick={vizualizar}
          >
            Visualizar cadastro
          </Button>
        </div>
      }
    </div>
  )
}