import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { StateGlobal } from '../../ContextAPI/context'
import CircularLoad from '../../Components/Load';
import conexao from '../../Services/api';
import ModalExcluir from '../../Components/Modais/ModalExclusao';
import PersonIcon from '@material-ui/icons/Person';
import './ListagemAt.css';
import { cpf, data } from '../../Components/ReduxTeste/Validate'
import Modal from './Modal/ModalExcluir'
import AccordionAtendente  from './AccordionAtendente'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    border: 'none',
    marginBottom: '2rem'
  },
  search: {
    background: '#f1f1f1',
    boxShadow: '0px 1px 10px rgba(25, 32, 56, 0.08)',
    borderRadius: '3px',
    height: '45px',
    fontSize: '15px',
    border: 'none',
    padding: '15px',
    minWidth: '300px',

  },
  iconSearch: {
    color: '#8F8F8F'
  },
  atendentesDiv:{
    padding: '1rem',
    paddingBottom:'4rem'
  }
}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    }
  },
}, ptBR);


export default function CardModelosProntos() {
  const classes = useStyles();
  const History = useHistory();
  const [atendentes, setAtendentes] = useState([]);
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [load, setLoad] = useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [buscacpf, setBuscaCpf] = useState('');
  const [titulo, setTitulo] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [modeloId, setModeloId] = React.useState('');
  const [optionDelete, setOptionDelete] = React.useState('');
  const [dataarr, setDataarr] = useState();
  const [open, setOpen] = useState(false)
  const [idDelete, setIddelete] = useState('')
  const [openModalgeral, setOpenModalgeral] = useState('')
  const [opengeral, setOpengeral] = useState(false)
  const [idEditar, setidEditar] = useState('')
  const [openalert, setOpenalert] = useState({ open: false })
  const [name, setName] = useState('')

  const [control, setControl] = useState()

  const {
    isEditPatient,
    setIsEditPatient,
    ViewPatient,
    setViewPatient,
    atendenteAtt,
    setAtendenteAtt
  } = StateGlobal();


  // useEffect(() => {
  //   getallAtendents();
  //   setViewPatient(false)

  // },[]);


  useEffect(() => {

    getallAtendents();



  }, []);

 


  function close() {
    setOpen(false)
    setOpengeral(false)
  }

  function closeModalGeral() {
    setOpengeral(false)
  }


  
  ///////////////aqui busca todos os atendentes:
  function getallAtendents() {
    conexao.post('/employee/list/' + localStorage.getItem('doctorId'),{"search":name}).then((res) => {
      setLoad(false)
      setMessage('');
      setTypeAlert('')
      setDataarr(res.data.data.data)
      setAtendentes(res.data.data.data)
      if(res.data.data.data.length<1){
        setMessage('Nenhum cadastro encontrado');
        setTypeAlert('error')
      }
    }).catch((error) => {
      setLoad(false)
    setMessage('Nenhum cadastro encontrado');
    setTypeAlert('error')

    })


  }

  const busca = (event) => {
    if (event.key === 'Enter') {
      setLoad(true)
      getallAtendents()
      setDataarr([])
      setAtendentes([])
    } if (event._reactName === 'onClick') {
      setLoad(true)
      getallAtendents()
      setDataarr([])
      setAtendentes([])
    }
    return true
  }

  useEffect(() => {
    if(name===''){
      getallAtendents()
    }

  },[name]);

  function Alertshow(msg, type) {
    setOpenalert({ open: true, msg: msg, type: type })
    setTimeout(() => {
      setOpenalert({ open: false })
    }, 2000);
  }

  ///////////////// função delete
  function deleteuser() {
    setLoad(true)
    conexao.delete('/employee/' + idDelete).then((res) => {
      setLoad(false)
      setTypeAlert('success')
      setMessage('Deletado com sucesso!')
      setTimeout(() => {
        setTypeAlert('')
        setMessage('')
        getallAtendents()
        close()
        window.location.reload();
      }, 2000);

    }).catch((error) => {
      setLoad(false)
      setTypeAlert('error')
      setMessage('Erro ao excluir o atendente!')
      setTimeout(() => {
        setTypeAlert('')
        setMessage('')
      }, 2000);
    })

  
  }

  function excluir(id){
    setOpen(true)
    setIddelete(id)
  }

  
  function editar(id, data){
    
    setAtendenteAtt({edit:true , change : true , id:id ,data:data})     
  }


  return (
    <div className={classes.atendentesDiv}>
        <FormControl className={clsx(classes.form)}>
          <InputBase
            id="outlined-adornment-weight"
            endAdornment={<InputAdornment position="end">{<IconButton onClick={(e)=>busca(e)}><SearchIcon className={classes.iconSearch}  /></IconButton>}</InputAdornment>}
            labelWidth={0}
            disabled={load ? true : false}
            onKeyDown={busca}
            onChange={(event)=>setName(event.target.value)}
            placeholder="Busque por um atendente"
            className={clsx(classes.search)}
          />
        </FormControl>
        <div>
            {message ?
              <div className="Modal-agendamento-alert">
                <Alert variant="filled" severity={typeAlert}>
                  {message}
                </Alert>
              </div>
              : ''}
          </div>
      <div>

      {load ?
          <div className="div-load-lista-modelos"><CircularLoad /></div>
          : ''}
        {dataarr ?
          dataarr.map((modeloCriado) => (
            
                  
                  <AccordionAtendente data={modeloCriado} excluir={excluir} editar={editar}></AccordionAtendente>
              
             
          )) :
          <div>
            {/* {message ?
              <div className="Modal-agendamento-alert">
                <Alert variant="filled" severity={typeAlert}>
                  {message}
                </Alert>
              </div>
              : ''} */}
          </div>
        }
  
      </div>
      <Modal open={open} titulo={'Excluir Atendente'} typeAlert={typeAlert} message={message} desc={'Tem certeza que deseja excluir este atendente ?'} load={load} close={close} delete={deleteuser} />

    </div>
  );
}