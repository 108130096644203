// import { ContactsOutlined } from "@material-ui/icons";
// import { Component } from "react";
// import Autosuggest from 'react-autosuggest';
// import './styles.css'
// import axios  from 'axios' 





// export default class Teste extends Component {
//   constructor(props) {
//     super();

//     this.state = {
//        languages : [
//         {
//           title: '390.680.198-56',
//           languages: [
//             {
//               name: 'Mauro Vieira da Cruz Junior',
//               cpf: '390.680.198-56'
//             }
//           ]
//         },
//         ,
//         {
//           title: '322.222.198-56',
//           languages: [
//             {
//               name: 'Mauro Junior',
//               cpf: '322.222.198-56'
//             }
//           ]
//         },
//         {
//           title: '312.123.198-56',
//           languages: [
//             {
//               name: 'Fernanda',
//               cpf: '321.123.198-56'
//             }
//           ]
//         },
//         ,
//         {
//           title: '432.234.234-23',
//           languages: [
//             {
//               name: 'Paulo',
//              cpf : '432.234.234-23'
//             }
//           ]
//         },
//         {
//           title: 'CPF DO PACIENTE',
//           languages: [
//             {
//               name: 'NOME DO PACIENTE'
//             }
//           ]
//         },
//       ],
//       value: '',
//       suggestions: []
//     };    
//   }







// // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
// escapeRegexCharacters(str) {
//   return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
// }



// getSuggestionValue(suggestion ) {



//   return suggestion.name;
// }

//  renderSuggestion(suggestion) {
//   return (
//     <span>{suggestion.name}</span>
//   );
// }

//  renderSectionTitle(section) {

//   return (
//     <strong>{section.title}</strong>
//   );
// }

// getSectionSuggestions(section) {

//   return section.languages;
// }



  




//   onChange = (event, { newValue, method }) => {

    
//     this.setState({
//       value: newValue
//     });
//   };


//   getvalor = (e)=>{

//     this.props.get(e)
//     return e.name;
//   }
  
//   onSuggestionsFetchRequested = ({ value }) => {
    
//     this.setState({
//       suggestions: this.getSuggestions(value)
//     });
//   };

//   onSuggestionsClearRequested = () => {
//     this.setState({
//       suggestions: []
//     });
//   };

//   render() {
//     const { value, suggestions } = this.state;
//     const inputProps = {
//       placeholder: "Paciente *",
//       value,
//       onChange: this.onChange,
//       //onClick: (e)=>{this.props.get(e.target)},
//       onblur: (e)=>{this.props.get(e.target)},
      
       
//     };

//     return (
//       <Autosuggest 
//         multiSection={true}
//         suggestions={suggestions}
//         onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
//         onSuggestionsClearRequested={this.onSuggestionsClearRequested}
//         getSuggestionValue={this.getvalor}
//         renderSuggestion={this.renderSuggestion}
//         renderSectionTitle={this.renderSectionTitle}
//         getSectionSuggestions={this.getSectionSuggestions}
//         inputProps={inputProps} 
//         />
//     );
//   }
// }


import { ContactsOutlined } from "@material-ui/icons";
import { Component } from "react";
import Autosuggest from 'react-autosuggest';
import './styles.css'
import {cpf} from '../Validate'





// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}



function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestion(suggestion) {
  return (
    <span>{suggestion.name}</span>
  );
}

function renderSectionTitle(section) {
  return (
    <strong>{section.title}</strong>
  );
}

function getSectionSuggestions(section) {
  return section.languages;
}

export default  class App extends Component {


  
  constructor(props) {
    super();

    



    this.state = {
      value: '',
      suggestions: [],
      valorAtual:''
    };    
  }

  onChange = (event, { newValue, method }) => {
    this.props.get(newValue)

    this.setState({
      value: newValue
    });
  };
  
  onSuggestionsFetchRequested = ({ value }) => {
    if(this.props.list){
    this.setState({
      suggestions: this.getSuggestions(value)
    });
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };



  getSuggestions(value) {
    const escapedValue = escapeRegexCharacters(value.trim());
    
    
    if (escapedValue === '') {
      return [];
    }
  
    const regex = new RegExp('^' + escapedValue, 'i');
  
    return this.props.list
      .map(section => {
        return {
          title: section.title,
          languages: section.languages.filter(language => regex.test(language.name))
        };
      })
      .filter(section => section.languages.length > 0);
  }


  getvalor = (e)=>{
       // this.setState({value :  e})
   
        this.props.get(e)
        return e.name;
      }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Paciente *",
      value,
      onChange: this.onChange,
      //onblur:(e)=>{this.props.get(e.target)}
    };

    return (
      <Autosuggest 
        multiSection={true}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        //getSuggestionValue={getSuggestionValue}
        getSuggestionValue={this.getvalor}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        inputProps={inputProps} />
    );
  }
}



