import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import CircularLoad from '../../../Components/Load';
import { validateEmail, validatePasswordLogin } from './validate';
import { useHistory } from 'react-router-dom';
import conexao from '../../../Services/api2'
import Alert from '@material-ui/lab/Alert';
import '../css/formLogin.css';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .MuiTextField-root': {
      marginBottom: '1rem',
      width: '100%',
    },
  },
}));

export default function FormLogin() {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState()
  const [load, setLoad] = useState(false)
  const [alertType, setAlertType] = useState(false)
  const [message, setMessage] = useState(false)
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const [logado, setLogado] = useState(false)
  const [clinicAlert, setClinicAlert] = useState(false)
 
  const alerts =[<Alert variant="filled"  severity="success">Logando</Alert> ,
  <Alert variant="filled" severity="error">E-mail ou senha inválidos</Alert>,
  <Alert variant="filled" severity="error">Usuário administrador acesse o sistema administrativo</Alert>,
  <Alert variant="filled" severity="error">Caro usuário Clinic Plus , como você faz parte do plano clínica , as funções do mobile podem estar reduzidas. Sugerimos que utilize o sistema na versão web (computador)</Alert>,
]

  function send(event) {
    event.preventDefault();
    if (validateEmail(email) && validatePasswordLogin(values.password)) {
      setLoad(true)
      console.log('LOGIN')
      conexao.post('/login', { "email": email, "password": values.password }).then((res) => {
        // Login OK!
        
        console.log(res.data.data)
        // if (res.data.data.type !== 'adm') {
          // Renova localstorage
          localStorage.removeItem('token')
          localStorage.removeItem('userId')
          localStorage.removeItem('selectedDoctor')
          localStorage.removeItem('selectedUser')
          localStorage.removeItem('clinicId')
          localStorage.removeItem('idEdicaoRP')
          localStorage.removeItem('answeredFormId')
          localStorage.removeItem('Location')
          localStorage.removeItem('idSend')
          localStorage.removeItem('expiredDate')
          localStorage.removeItem('expired')
          localStorage.removeItem('trial')
          localStorage.removeItem('adminAlert')
          localStorage.removeItem('doctorPlanId')
          localStorage.removeItem('/agenda')
          localStorage.removeItem('/pacientes')
          localStorage.removeItem('/financeiro')
          localStorage.removeItem('/novo-modelo')
          localStorage.removeItem('/atendente')
          localStorage.removeItem('/plano')
          localStorage.removeItem('/perfil')
          localStorage.removeItem('/painel')
          localStorage.removeItem('/relatorios')
          localStorage.removeItem('/profissionais')
          localStorage.removeItem('/atendentes')
  
          localStorage.setItem('loginType', res.data.data.type)
          localStorage.setItem('token', res.data.data.token)
          localStorage.setItem('userId', res.data.data.user_id)
          localStorage.setItem('profileId', res.data.data.profile_id)
          localStorage.setItem('avatar', res.data.data.avatar)
          
          if (res.data.data.expired_date)
            localStorage.setItem('expiredDate', res.data.data.expired_date)
          if (res.data.data.expired)
            localStorage.setItem('expired', res.data.data.expired)
          if (res.data.data.trial)
            localStorage.setItem('trial', res.data.data.trial)
          if (res.data.data.clinic_id)
            localStorage.setItem('clinicId', res.data.data.clinic_id)

          setLoad(false)


          if (res.data.data.profile_id == 2) // PROFILE_CLINIC
          {
            localStorage.setItem('selectedDoctor', res.data.data.doctor_id)
            localStorage.setItem('doctorId', res.data.data.doctor_id) //Compatibilidade com código antigo
            localStorage.setItem('selectedUser', res.data.data.user_id)
            localStorage.setItem('doctorName', res.data.data.name)
          }

          if (res.data.data.profile_id == 3) // PROFILE_DOCTOR
          {
            localStorage.setItem('selectedDoctor', res.data.data.doctor_id)
            localStorage.setItem('doctorId', res.data.data.doctor_id) //Compatibilidade com código antigo
            localStorage.setItem('selectedUser', res.data.data.user_id)
            localStorage.setItem('doctorName', res.data.data.name)
            localStorage.setItem('doctorPlanId', res.data.data.doctor_plan_id)
          }

          if (res.data.data.profile_id == 4) // PROFILE_EMPLOYEE
          {
            localStorage.setItem('employeeId', res.data.data.employee_id)
            localStorage.setItem('selectedDoctor', res.data.data.doctor_id) //Compatibilidade com código antigo
            localStorage.setItem('doctorId', res.data.data.doctor_id) //Compatibilidade com código antigo
            localStorage.setItem('selectedUser', res.data.data.doctor_user_id)
            localStorage.setItem('doctorName', res.data.data.doctor_name)
          }

          var obj = [];

          var count = 0
          console.log('PERMISSION')
          localStorage.setItem('permission', res.data.data.permission)
          if (res.data.data.permission) {
            const arr = res.data.data.permission.toString().split(',');
              arr.map((item) => {
              localStorage.setItem(item, 'true')
            })
          }

          localStorage.setItem('trial', res.data.data.trial)
          localStorage.setItem('expired', res.data.data.expired)

          var showAlert = false;
          if (localStorage.getItem('loginType') == 'CLINIC' || (localStorage.getItem('loginType') == 'DOCTOR' && !parseInt(localStorage.getItem('clinicId')))) 
            showAlert = true;

          // Vamos ver se o plano está expirado
          if (res.data.data.trial) {
            if (res.data.data.expired) {
              localStorage.setItem('trialExpired', '1')
              if (showAlert)
                localStorage.setItem('adminAlert', 'Bem vindo ao Clinic Plus! Seu período de avaliação expirou.')
              // Seta permissões
              obj =
              [
                { route: '/agenda', autorization: 'false' },
                { route: '/pacientes', autorization: 'false' },
                { route: '/financeiro', autorization: 'false' },
                { route: '/novo-modelo', autorization: 'false' },
                { route: '/atendente', autorization: 'false' },
                { route: '/plano', autorization: 'true' },
                { route: '/perfil', autorization: 'false' },
                { route: '/painel', autorization: 'false' },
                { route: '/relatorios', autorization: 'false' },
                { route: '/profissionais', autorization: 'false' },
                { route: '/atendentes', autorization: 'false' }
              ];

              obj.map((item) => {
                localStorage.setItem(item.route, item.autorization)
              })

            } else {
              if (showAlert) {
                switch (res.data.data.remain_days) {
                  case 0:
                    localStorage.setItem('adminAlert', 'Bem vindo ao Clinic Plus! Seu período de avaliação termina hoje.')
                  break;
  
                  case 1:
                    localStorage.setItem('adminAlert', 'Bem vindo ao Clinic Plus! Seu período de avaliação termina amanhã.')
                  break;
  
                  default:
                    localStorage.setItem('adminAlert', 'Bem vindo ao Clinic Plus! Seu período de avaliação termina em ' + res.data.data.remain_days + ' dias')
                }
              }
            }
          } else {
            if (res.data.data.expired) {
              if (showAlert)
                localStorage.setItem('adminAlert', 'Não identificamos o pagamento de sua assinatura. Entre em contato com o suporte evitando o bloqueio de acesso ao sistema Clinic Plus') 
            }
          }

          if (parseInt(localStorage.getItem('clinicId')) && (localStorage.getItem('loginType') == 'CLINIC' || localStorage.getItem('loginType') == 'EMPLOYEE')) {

            setClinicAlert (true)
            if (localStorage.getItem('adminAlert'))
              localStorage.setItem('containerClass','containerClinicAlert');
            else 
              localStorage.setItem('containerClass','containerClinic');
          } else {
            if (localStorage.getItem('adminAlert'))
              localStorage.setItem('containerClass', 'containerAlert');
            else
              localStorage.setItem('containerClass', 'container');
            
            alert('success', 'Logando')
          }

          setTimeout(() => {
            // getPlanData()
            if (localStorage.getItem('profileId') === 3) // DOCTOR
              window.location.href = '/agenda'
            else
              window.location.href='/home'
          }, clinicAlert ? 4000 : 500);
      }).catch((error) => {
        setLoad(false)
        alert('error', error.response.data.message)
      })
    }
  }

  function alert(type, msg) {
    setAlertType(type)
    setMessage(msg)
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };


  return (
    <>
      <div className='Login-alert'>
        {message ?
          <Alert variant="filled" severity={alertType}>{message}</Alert>
        :  ''
        }
      </div>
      <form onSubmit={(e) => send(e)} className={classes.root} validate autoComplete="off">


        <div>
          <TextField
            className="Login-input-color"
            id="email"
            label="E-mail"
            onChange={(e) => { setEmail(e.target.value) }}
            error={email && !validateEmail(email)}
            helperText={email && !validateEmail(email) ? 'Email inválido' : ''}
            type="email"
            placeholder="Digite o seu e-mail"
            variant="outlined"
            color="primary"
          />
          <FormControl
            error={values.password && !validatePasswordLogin(values.password)}
            className="Login-senha-input" variant="outlined">
            <InputLabel htmlFor="senha">Senha</InputLabel>
            <OutlinedInput
              className="Login-input-color"
              id="senha"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              aria-describedby="component-error-text"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={45}
            />
            <FormHelperText id="component-error-text">{values.password && !validatePasswordLogin(values.password) ? 'Senha inválida' : ''}</FormHelperText>
          </FormControl>
        </div>



        <div className="Login-div-btn-entrar">
          <Button
            className="Login-button color-primary"
            type="submit"
            variant="contained"
            color="primary">
            {!load ? 'Entrar' : <CircularLoad />}
          </Button>
        </div>
      </form>
    </>
  );
}