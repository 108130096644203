import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PeopleIcon from '@material-ui/icons/People';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import conexao from '../../Services/api'
import ModalSair from '../Modais/ModalSair';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import {useHistory} from 'react-router-dom';
import './css/menuInferior.css';
import { StateGlobal } from '../../ContextAPI/context';


const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    color: '#868686',
    overflowX: 'auto',
    // maxWidth: '370px',
    width: '100%',
    minWidth: '350px',
    backgroundColor: '#ffffff',
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
}));

export default function MenuInferior() {
  const classes = useStyles();
  const History = useHistory();
  const [ativo, setActive ]= useState('home');
  const { attAgenda, setAttAgenda } = StateGlobal()
  const { setIsEditPatient, notification, setNotification, notfyatt, setNotatt, setRota ,rota} = StateGlobal()
  const [load, setLoad] = useState(false)
  const [openModalSair, setOpenModalSair] = React.useState(false);
  const [hasExpired, setHasExpired] = useState(false)

  useEffect(() => {
    var url = History.location.pathname;
    if(url==='/home'){
      setActive('home');
    }else if(url==='/agenda'){
      setActive('agenda');
    } else if(url==='/listagem-pacientes'){
      setActive('pacientes');
    }
    else if(url==='/financeiro'){
      setActive('financeiro');
    }
    else if(url==='/atendentes'){
      setActive('atendentes');
    }
    else if(url==='/suporte'){
      setActive('suporte');
    }
    else{
      setActive('');
    }
  }, [ativo])

  useEffect(() => {
    getDoctorPlan()
    
    if (History.location.pathname != '/suporte' && History.location.pathname != '/notificacoes' && History.location.pathname != '/meu-perfil'  ) {    
      setRota(History.location.pathname)
    } 

  }, [])

  async function getDoctorPlan(){
    try{
      const id = localStorage.getItem('doctorId')
      const response = await conexao.get(`/doctor/plan/get/${id}`)
      
      const dataAlert = response.data.data[0]

      let dataExpired = new Date((dataAlert.expired_at).replace(/\s/, 'T')+'Z')
      let dataAtual = new Date()

      let expired = dataExpired - dataAtual

      expired = expired / 1000 / 60 / 60 /24

      expired = expired.toFixed(0)

      expired <= 0 && setHasExpired(true)
    }catch(error){
      console.log(error)
    }
  }

  function suporte() {
    mudaMenu('suporte')
    History.push('/suporte');
  }

  const handleModalSair = (value) => {
    if (value) {
      sair()
    } else {
      handleCloseSair()
    }
  };

  const handleCloseSair = () => {
    setOpenModalSair(false);
  }
  const sair = () => {
    setLoad(true);
    conexao.post('/logout').then((res) => {
      setLoad(false)
      localStorage.clear();
      History.push('/');
    }).catch((error) => {
      setLoad(false)
      localStorage.clear();
      History.push('/');
    })
  }

  function modalSair() {
    setOpenModalSair(true);
  }

  function mudaMenu(value){
    setActive(value);
    History.push(`/${value}`)
    if(value ==='agenda'){
      setAttAgenda(true)
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <div className="divappbar">
        {!hasExpired ? 

          <AppBar position="fixed"  className={classes.appBar}>
            <Toolbar className="envolveBtnsMenu">
            {localStorage.getItem('/painel') === 'true'?
              <IconButton onClick={()=>mudaMenu('home')} className={ativo=='home'?'active':''} color="inherit" aria-label="open drawer">
                <EqualizerIcon className="icon-menu" />
                <p className="p-menu">Painel</p>
              </IconButton>
            :''}
              <IconButton onClick={()=>mudaMenu('agenda')}  className={ativo=='agenda'?'active':''} color="inherit" aria-label="open drawer">
                <CalendarTodayIcon className="icon-menu" />
                <p className="p-menu">Agenda</p>
              </IconButton>
              <IconButton  color="inherit" aria-label="open drawer" className={ativo=='pacientes'?'active':''} onClick={()=>mudaMenu('listagem-pacientes')} >
                <PeopleIcon className="icon-menu" />
                <p className="p-menu">Pacientes</p>
              </IconButton>
              {localStorage.getItem('/financeiro') === 'true'?
                <IconButton  color="inherit" onClick={()=>mudaMenu('financeiro')} className={ativo=='financeiro'?'active':''} aria-label="open drawer">
                  <AttachMoneyIcon className="icon-menu" />
                  <p className="p-menu">Financeiro</p>
                </IconButton>
              :''}
              {localStorage.getItem('/atendente') === 'true'?
                <IconButton  color="inherit" onClick={()=>mudaMenu('atendentes')} className={ativo=='atendentes'?'active':''} aria-label="open drawer">
                  <AssignmentIndIcon className="icon-menu" />
                  <p className="p-menu">Atendentes</p>
                </IconButton>
              :''}
              {/* {localStorage.getItem('/perfil') !== 'true'?
                <IconButton  color="inherit" onClick={suporte} className={ativo=='suporte'?'active':''} aria-label="open drawer">
                  <HelpIcon className="icon-menu" />
                  <p className="p-menu">Suporte</p>
                </IconButton>
              :''}
              {localStorage.getItem('/perfil') !== 'true'?
                <IconButton  color="inherit" onClick={modalSair} className={ativo=='atendentes'?'active':''} aria-label="open drawer">
                  <ExitToAppIcon className="icon-menu" />
                  <p className="p-menu">Sair</p>
                </IconButton>
              :''} */}
            </Toolbar>
          </AppBar>

          :

          <AppBar position="fixed"  className={classes.appBar}>
            <Toolbar className="envolveBtnsMenu">
              <IconButton
                color="inherit"
                onClick={()=>mudaMenu('planos')}
                className={ativo=='planos'?'active':''}
                aria-label="open drawer"
              >
                <ViewCarouselIcon className="icon-menu" />
                <p className="p-menu">Planos</p>
              </IconButton>
            </Toolbar>
          </AppBar>
        }
        <ModalSair
        handleModal={handleModalSair}
        open={openModalSair}
        load={load}
      />
      </div>
    </React.Fragment>
  );
}